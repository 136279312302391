<template>
    <div class="phone-screen__wrapper ">
        <div class="phone-screen phone-screen-custom">
        <div class="phone-screen__inner">
            <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
            <div class="phone-screen__main">
            <h1 class="phone-screen__title">Set EMI parametrs</h1>
                <div class="block">
                    <div class="card">
                        <p class="card__title card__title_bold">Please select the preferred term</p>
                    </div>
                </div>
                <div class="block">
                    <div class="card">
                        <p class="card__title card__title_bold">Conditions:</p>
                    </div>
                    <div class="card">
                        <div v-for="item,i in conditions" :key="i" class="flex mb-0">
                            <div class="flex flex__column">
                                <p class="mb-0">{{ item.title }}</p>
                                <p class="condition_value">{{ item.value }}</p>
                            </div>
                            <!-- <img src="" alt="next"> -->
                            <span class="conditions_next">&#8594;</span>
                            <p class="condition_endValue">{{ item.endValue }}</p>
                        </div>
                    </div>
                </div>
                <div class="block full_hight">
                    <div class="card flex flex__column flex_between">
                        <nextDisplayBtns :is_btn_second="false" btn_first="Accept" />
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    
</template>

<script>
import nextDisplayBtns from '../widgets/homescreen/nextDisplayBtns.vue';
export default {
    components:{
        nextDisplayBtns,
    },
    props: {
        conditions: {
            default: () => [
                {'title': 'Term', 'value': '5 month', 'endValue': '10 month'},
                {'title': 'Total payable', 'value': '₹ 13 930,39', 'endValue': '₹ 15 930,39'},
                {'title': 'Repayment term', 'value': '31.01.2023', 'endValue': '30.06.2023'},
                {'title': 'Optional term fee', 'value': '₹ 0,00', 'endValue': '₹ 999'},
                {'title': 'Next EMI', 'value': '₹ 3 930,39', 'endValue': '₹3 109,15'},
                {'title': 'EMI after next', 'value': '₹ 3 930,39', 'endValue': '₹2 110,15'},
            ],
            type: Array
        }
    }
}
</script>

<style lang="scss">
.condition{
    &_value{
        color: #7A7A7A;
    }
    &_endValue{
        min-width: 30%;
    }
}
</style>
