<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <SearchComponent
          :searchData="['id', 'name', 'url']"
          prefix="externalSources"
          @searchInput="searchInput"
          ref="searchComponent"
      />

    <v-card class="mt-5 pb-6 rounded-xl pa-6">
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="showCreate"
        class="btn-add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-data-table
        :headers="tableHeaders"
        :items="externalSources"
        :loading="!loading"
        :loading-text="$t('multilang.load')"
        item-key="id"
        density="compact"
        :items-per-page="tableItemsPerPage"
        :footer-props="tableFooterProps"
        class="mt-4"
        :sort-by="sortTableBy"
        :sort-desc="sortTableDesc"
        :custom-sort="customSort"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ item.id }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.methodName`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ item.methodName }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.methodName)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.methodUrl`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ item.methodUrl }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.methodUrl)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.apiId`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ apis.find(type => type.id === item.apiId)?.apiName }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.apiId)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.typeId`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ types.find(type => type.id === item.typeId)?.method }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.typeId)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.isAsync`]="{ item }">
          <div class="table-item">
            <v-icon>{{ dataStatus(item?.isAsync) }}</v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
  <v-col cols="3" style="position: relative;" v-if="visibleEditor">
    <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
      <div style="position: fixed;">
        <v-btn
          class="btn-close"
          icon
          color="red"
          @click="closeEditor"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
        <p v-if="newData.id" class="mr-6"> Id:
          <b style="color:#9c9c9c;">{{ newData.id }}</b>
          <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
        </p>
        <v-text-field
          :label="$t('externalSource.methodName')"
          hide-details="auto"
          outlined
          v-model="newData.methodName"
          require
        ></v-text-field>
        <v-text-field
          :label="$t('externalSource.methodUrl')"
          hide-details="auto"
          outlined
          v-model="newData.methodUrl"
          require
        ></v-text-field>
        <v-autocomplete
          :items="apis"
          v-model="newData.apiId"
          item-value="id"
          item-text="apiName"
          outlined
          :placeholder="$t('externalSource.selectApiId')"
          hide-details
          class="mt-1"
        />
        <v-autocomplete
          :items="types"
          v-model="newData.typeId"
          item-value="id"
          item-text="method"
          outlined
          :placeholder="$t('externalSource.selectTypeId')"
          hide-details
          class="mt-1"
        />
        <div class="editor-col__content__checkbox">
          <v-checkbox
              v-model="newData.isAsync"
              color="green"
              class="editor-col__content__checkbox-item"
          >
            <template v-slot:label>
              <div @click.stop="">
                {{ $t('externalSource.isAsync') }}
              </div>
            </template>
          </v-checkbox>
        </div>
        <div>
          <div class="json-editor">
            <span>{{ $t('externalSource.request') }}</span>
            <JsonEditorVue
              v-model="newData.requestJson"
              v-bind="JsonEditorSetting"
              class="mb-3"
              style="position: relative;"
              ref="jsonEditor-request"
            />
            <v-icon class="copy_json" @click="copyData(newData.requestJson)">mdi-content-copy</v-icon>
          </div>
          <div class="json-editor">
            <span>{{ $t('externalSource.response') }}</span>
            <JsonEditorVue
              v-model="newData.responseJson"
              v-bind="JsonEditorSetting"
              class="mb-3"
              style="position: relative;"
              ref="jsonEditor-response"
            />
            <v-icon class="copy_json" @click="copyData(newData.responseJson)">mdi-content-copy</v-icon>
          </div>
      </div>
        <div class="editor-col__btns">
          <template v-if="newData.id">
            <v-btn :disabled="requireFields" @click="updateMethod">{{ $t('update') }}</v-btn>
            <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
            <v-btn @click="deleteMethod(newData.id)">{{ $t('delete') }}</v-btn>
          </template>
          <template v-else>
            <v-btn :disabled="requireFields" @click="createMethod">{{ $t('create') }}</v-btn>
          </template>
        </div>
      </div>
      <SpinnerLoader 
        v-else
        color="blue" 
      />
    </div>
  </v-col>
</v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";

export default{
  data(){
    return {
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('externalSource.methodName'), value: 'methodName'},
        {text: this.$t('externalSource.methodUrl'), value: 'methodUrl'},
        {text: this.$t('externalSource.apiId'), value: 'apiId'},
        {text: this.$t('externalSource.typeId'), value: 'typeId'},
        {text: this.$t('externalSource.isAsync'), value: 'isAsync'},
        // {text: 'Request json', value: 'requestJson', sortable: false},
        // {text: 'Response json', value: 'responseJson', sortable: false},
      ],
      templateData: {
        isAsync: false,
        requestJson: {
          body: {},
          cookies: {},
          headers: {}
        },
        responseJson: {
          body: {},
          status: "",
          cookies: {},
          headers: {}
        }
      },
      
      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
      },
    }
  },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('externalSources/getDataById', {id, show: true})
   
      this.parseProperties(data, ['requestJson', 'responseJson'])

      if(data?.requestJson === null){
        data.requestJson = JSON.parse(JSON.stringify(this.templateData.requestJson))
      }
      if(data?.responseJson === null){
        data.responseJson = JSON.parse(JSON.stringify(this.templateData.responseJson))
      }

      this.newData = data
    },

    duplicate(id){
      const externalSources = this.externalSources.find(field => field.id === id)
      this.parseProperties(externalSources, ['requestJson', 'responseJson'])

      const externalSource = JSON.parse(JSON.stringify(externalSources))
      delete externalSource.id

      this.visibleCopy = true
      this.newData = externalSource
    },

    deleteMethod(id){
      this.$store.dispatch('externalSources/deleteMethod', {id, searchData: this.search})

      this.closeEditor()
    },

    async createMethod(){
      this.parseProperties(this.newData, ['requestJson', 'responseJson'])

      const newId = await this.$store.dispatch('externalSources/createMethod', {newData: this.newData, searchData: this.search})
      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    updateMethod(){
      this.parseProperties(this.newData, ['requestJson', 'responseJson'])

      this.$store.dispatch('externalSources/updateMethod',
          {data: JSON.parse(JSON.stringify(this.newData)), searchData: this.search}
      )
    },

    loadData(){
      this.$store.dispatch('externalSources/getMethods')
      this.$store.dispatch('externalSources/getMethodTypes')
      this.$store.dispatch('externalSources/getMethodApis')
    },
  },
  computed: {
    filteredData(){
      return this.$store.getters['externalSources/searchedExternalSources']
    },
    externalSources(){
      const sources = this.$store.getters['externalSources/externalSources']

      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? sources
          : this.filteredData
    },
    loading(){
      return this.externalSources && this.externalSources.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['externalSources/loadedAllData']
    }, 
    types(){
      return this.$store.getters['externalSources/types']
    },
    apis(){
      return this.$store.getters['externalSources/apis']
    },
    requireFields(){
      return (!this.newData.methodName || !this.newData.methodUrl || !this.newData.apiId || !this.newData.typeId)
    }
  },

  created(){
    this.loadData()   
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/CRUD.scss";
</style>