<template>
    <div class="progress">
        <template v-if="totalOutstanding" >
            <p class="progress__title">₹{{available}}</p>
            <BpmProgress :value="outstanding" :max="available" />
        </template>
        <div class="progress__label" :class="{'bold': !totalOutstanding}">
            <p class="progress__label__caption">{{ text_outstanding }}</p>
            <p class="progress__label__caption">{{ text_available }}</p>
        </div>
        <div class="progress__label">
            <p class="progress__label__sum">₹{{outstanding}}</p>
            <p class="progress__label__sum">₹{{available}}</p>
        </div>
        <template v-if="!totalOutstanding">
            <BpmProgress :value="outstanding" :max="available" />
            <p class="total">Total limit: ₹{{available}}</p>

            <MainTabs first_tab="Active periods" second_tab="Closed periods" :tabs_data="tabs_content"/>
        </template>
    </div>
</template>

<script>
import MainTabs from './MainTabs.vue';
export default {
    props: {
        available: {
            default: 0,
            type: Number
        },
        outstanding: {
            default: 0,
            type: Number
        },
        text_outstanding: {
            default: 'Total outstanding',
            type: String
        },
        text_available: {
            default: 'Available',
            type: String
        },
    },
    components: {
        MainTabs,
    },
    computed:{
        totalOutstanding(){
            return this.outstanding === 0
        },
    },
    data(){
        return{
            tabs_content:[{
                'month': 'December',
                'year': 2022,
                'sum': 15000,
                'grace': 'Grace untill 20 Jan',
                'image': 'gray_circle_sections',
                'danger': true
            },{
                'month': 'November',
                'year': 2022,
                'sum': 40000,
                'grace': '10 months',
                'image': 'gray_circle'
            },{
                'month': 'October',
                'year': 2022,
                'sum': 60000,
                'grace': '12 months',
                'image': 'gray_circle'
            }]
        }
    }

}
</script>