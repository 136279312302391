import {endpointsService} from "@/services/endpoints.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'

export const endpoints = {
    namespaced: true,
    state: {
      endpoints: [],
      searchedEndpoints: [],
      loadedAllData: false,
    },
    getters: {
      endpoints: state => state.endpoints,
      searchedEndpoints: state => state.searchedEndpoints,
      loadedAllData: state => state.loadedAllData,
    },
    mutations: {
      setEndpoints(state, value) {
        state.endpoints = [...state.endpoints, ...value.endpoint]
      },
      setSearchedEndpoints(state, value){
          state.searchedEndpoints = state.searchedEndpoints?.length
              ? [...state.searchedEndpoints, ...value]
              : value
      },
      deleteEndpoint(state, {id, searchData}){
        state.endpoints = state.endpoints.filter(field => field.id !== id)
        if(Object.values(searchData).some(item => item)) state.searchedEndpoints = state.searchedEndpoints.filter(field => field.id !== id)
      },
      addEndpoint(state, {dataEndpoint, searchData}){
        state.endpoints.push(dataEndpoint)
        if(Object.values(searchData).some(item => item)) {
          state.searchedEndpoints?.length
              ? state.searchedEndpoints.push(dataEndpoint)
              : state.searchedEndpoints = [dataEndpoint]
        }
      },
      cleanSearchedData(state){
          state.searchedEndpoints = null
      },
      cleanEndpoints(state){
          state.endpoints = []
      },
      setLoaded(state, loaded){
        state.loadedAllData = loaded
      }
    },
    actions: {
      async getEndpoints({commit, getters, dispatch}, payload) {
        const response = await endpointsService.getEndpoints({offset: payload?.offset || 0})

        if(getters.endpoints.length >= response.response.total - 1) commit('cleanEndpoints')
        commit('setEndpoints', response.response)

        const loaded = await requestWithOffset(response.response, payload, 'getEndpoints', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataFiltered({commit, dispatch}, searchData){
          const isType = false // need to hide type in request
          const keys = Object.keys(searchData)
          const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

          const data = {
              isType,
              type: availableKey,
              search: availableKey ? searchData[availableKey] : '',
              offset: searchData?.offset || 0
          }
          const response = await endpointsService.getEndpoints(data)

        if(!response?.response?.endpoint?.length) {
          dispatch('alert/error', i18n.t('endpoint.alert.searchError'), { root: true })
          return commit('setLoaded', true)
        }
        commit('setSearchedEndpoints', response.response.endpoint)

        const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataById({commit, dispatch}, {id, show}) {
        const response = await endpointsService.getEndpointById(id)

        commit('setLoaded', true)
        if(!response?.response?.id) return dispatch('alert/error', i18n.t('endpoint.alert.searchError'), { root: true })
        if(!show) {
          commit('setSearchedEndpoints', [response.response])
          commit('setLoaded', true)
        }

        return response.response
      },

      loadData({commit}){
        commit('setLoaded', false)
      },

        cleanSearchedData({commit}){
          commit('cleanSearchedData')
      },

      async createEndpoint({commit, dispatch}, {newData, searchData}){
          const response = await endpointsService.createEndpoint(newData)
          

          if(!response?.response || response?.status === 0) {
              dispatch('alert/error', i18n.t('endpoint.alert.createError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('endpoint.alert.createSuccess'), { root: true })

              const createdEndpoint = await endpointsService.getEndpointById(response.response.id)
              commit('addEndpoint', {dataEndpoint: createdEndpoint.response, searchData})

              return response.response.id
          }
      },
     
      async updateEndpoint({commit, dispatch}, {data, searchData}){
          const response = await endpointsService.updateEndpoint(data)

          if(!response?.response || response?.status === 0){
              dispatch('alert/error', i18n.t('endpoint.alert.updateError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('endpoint.alert.updateSuccess'), { root: true })

              commit('deleteEndpoint', {id: data.id, searchData})
              commit('addEndpoint', {dataEndpoint: data, searchData})
          }
      },
      async deleteEndpoint({commit, dispatch}, {id, searchData}){
          const response = await endpointsService.deleteEndpoint(id)

          if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
              dispatch('alert/error', i18n.t('endpoint.alert.deleteError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('endpoint.alert.deleteSuccess'), { root: true })

              searchData?.id
                ? dispatch('cleanSearchedData')
                : commit('deleteEndpoint', {id, searchData})
          }
      }
    }
}