<template>
  <div class="credit-line">
    <p class="credit-line__title">Line of credit</p>
    <p class="credit-line__subtitle">No active credit balance</p>
    <div class="credit-line__block">
      <v-img width="87" height="87" contain src="@/assets/images/transh/credit-line-img.svg" />
      <p class="credit-line__title">There will be an information about your options to pay</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreditLine",
}
</script>

<style lang="scss" scoped>
.credit-line {
  padding: 20px 15px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url("@/assets/icons/transh/rectangle-credit-line.svg");
  background-size: contain;
  background-repeat: no-repeat;
  &:before {
    content: url("@/assets/icons/transh/arrow_circle.svg");
    position: absolute;
    top: 4px;
    right: 15px;
    width: 40px;
    height: 40px;
  }
  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 8px;
    max-width: 176px;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    margin-bottom: 0;
    color: #777;
  }
  &__block {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
}
</style>