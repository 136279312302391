<template>
  <PhoneWrapper node-name="АУТЕНТИФИКАЦИЯ. ПРЕСЕТ: ПРОВЕРКА ОТП.">
    <h1 class="phone-screen__title">Transfer</h1>

    <div class="auth-otp__number">
      <p class="phone-screen__text text-center">Secure сode sent to: </p>
      <p class="phone-screen__title">+91 387 833 8015</p>
    </div>

    <bpmOtpInput :number="4" label="Enter the 4 digit OTP" />
    <p class="phone-screen__text phone-screen__text_grey text-center mt-9">In case you didn’t get OTP, you can request a new one in 29 sec</p>
    <div class="mt-auto mb-10" style="width: 100%">
      <bpmButton style="width: 100%">
        <template v-slot:content><span style="color: white">Confirm</span></template>
      </bpmButton>
      <p class="phone-screen__link mt-4">By continuing, I confirm that I have
        read the <b style="border-bottom: 1px solid black">Agreement</b> and agree to its terms</p>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";

export default {
  name: "AuthOtp",
  components: {PhoneWrapper},
}
</script>

<style lang="scss" scoped>
.auth-otp {
  &__number {
    margin-top: 80px;
    margin-bottom: 70px;
  }
}
.phone-screen__text{
  max-width: 240px;
}
</style>