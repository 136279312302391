<template>
  <PhoneWrapper node-name="НОДА: ПЛАТЁЖИ. ЭКРАН 3: НАСТРОЙКА СПОСОБОВ ОПЛАТЫ. ">
    <h1 class="phone-screen__title">Repayment methods</h1>
    <div class="block">
      <div v-for="(item, i) in methods" :key="i" class="flex flex_between text_border">
        <div class="flex">
          <img src="@/assets/images/gray_circle.png" alt="gray_circle">
          <div>
            <p class="">{{item.title}}</p>
            <p class="card__subtitle">{{item.subtitle}}</p>
          </div>
        </div>
        <span>&#62;</span>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "vueRepaymentMethods",
  components: {
    PhoneWrapper,
  },
  props: {
    methods: {
      default: () => [
        {
          title: 'My cards',
          subtitle: 'Set up regular payments'
        },{
          title: 'My digital mandates',
          subtitle: 'View information about your\n' +
              'digital mandates'
        },
      ],
      type: Array
    }
  }
}
</script>

<style scoped>

img{
  width: auto;
  height: 48px;
}
.text_border{
  border-bottom: 1px solid #777777;
}

</style>