<template>
  <div class="wrapper" :style="wrapperStyles">
    <p
        :style="styles"
        v-html="item.properties.text"
    />
  </div>
</template>

<script>
import fieldOptions from "@/mixins/fieldOptions";

export default {
  name: "PreviewHref",
  mixins: [fieldOptions]
}
</script>

<style scoped>
p {
  margin: 3px;
  font-weight: 400;
  font-size: 4px;
}
.wrapper {
  display: flex;
  width: 100%;
}
</style>