<template>
  <v-text-field
    :disabled="disabled"
    :value="searchText"
    v-model="value"
    @input="search"
    append-icon="mdi-magnify"
    color="deep-purple accent-2"
    outlined
    dense
    label="Search"
    hide-details
  ></v-text-field>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    disabled: {
      type: Boolean
    },
    searchText: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      timeout: null,
      value: '',
    }
  },
  methods: {
    // after entering some text in the input field
    // the text will be emit after 1 second
    search: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit('input', this.value);
      }, 1000);
    }
  },
  watch: {
    searchText(new_value) {
      this.value = new_value;
    }
  }
}
</script>
