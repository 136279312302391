<template>
  <PhoneWrapper node-name="НОДА: ПЛАТЕЖНАЯ ИНФОРМАЦИЯ. ЭКРАН 1: ОСНОВНОЙ.">
    <h1 class="phone-screen__title">Payments</h1>
    <div class="block">
      <div class="card__title card__title_bold">Pay now</div>
      <div v-for="(item, i) in payNow" :key="i" class="flex flex_between">
        <div class="flex">
          <img src="@/assets/images/gray_circle.png" alt="gray_circle">
          <p>{{item}}</p>
        </div>
        <span>&#62;</span>
      </div>
    </div>

    <div class="block">
      <div class="card">
        <div class="card_warning__title">
          At the moment you are not eligible for early repayment, because according to T&C, at least two monthly payments are required
        </div>
      </div>
    </div>

    <div class="block">
      <div class="card__title card__title_bold">Set your parameters</div>
      <div v-for="(item, i) in yourParameters" :key="i" class="flex flex_between">
        <div class="flex">
          <img src="@/assets/images/gray_circle.png" alt="gray_circle">
          <p>{{item}}</p>
        </div>
        <span>&#62;</span>
      </div>
    </div>

    <div class="block mt-15">
      <div class="flex flex_between">
        <div class="flex">
          <img src="@/assets/images/gray_circle.png" alt="gray_circle">
          <p>My balance</p>
        </div>
        <p>₹0.00</p>
        <span>&#62;</span>
      </div>
    </div>
  </PhoneWrapper>

</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "vuePaymentInformation",
  components:{
    PhoneWrapper
  },
  props: {
    payNow: {
      default: () => [
        'Make an EMI',
        'Close Grace period',
        'Early repayment'
      ],
      type: Array
    },yourParameters: {
      default: () => [
        'Repayment methods',
        'Set EMI parametrs',
      ],
      type: Array
    },
  }
}
</script>

<style scoped>

</style>