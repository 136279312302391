<template>
      <v-row class="mt-1">
        <v-col col="1"></v-col>
        <v-col cols="11">
        <div class="d-flex align-baseline justify-space-between">          
          <h3 v-if="isFirst" class="font-weight-bold d-flex ml-n10 align-center">
            <img class="mr-2" src="@/assets/icons/double_square.svg" alt="double_square">
            <span class="mr-2">{{ data.node.displayName }} <span class="text-caption"> | {{ data.node.id }}</span></span>
            <v-tooltip v-if="isFirst && !data.allPresets" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small color="#f5f5f5" elevation="0" @click="addPresets" v-bind="attrs" v-on="on">
                  <v-icon x-small class="black-icon">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('node.intoPreset.addPreset') }}</span>
            </v-tooltip>
          </h3>
          <p v-else></p>
          <div class="d-flex align-baseline" style="gap: 8px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small color="#f5f5f5" elevation="0" @click="deletePreset($event)" v-bind="attrs" v-on="on">
                  <v-icon x-small class="black-icon">mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('node.intoPreset.delAllRules') }}</span>
            </v-tooltip>
            <p class="preset-name">{{ presetName }}</p>
          </div>
        </div>
        <div v-if="isFirst" class="mb-2 d-flex align-center">
          <h4 class="mr-2 font-weight-bold mb-0">{{ $t('node.intoPreset.canvasName') }}</h4>
          <input class="canvas-name mr-2" :placeholder="$t('node.intoPreset.plNameField')" v-model="outputName" type="text" />
          <v-btn elevation="0" class="white--text mb-1" small color="deep-purple accent-2" @click="saveOutputName">
            {{ $t('save') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
</template>

<script>
import { EventBus } from "@/utils/event-bus";
export default{
   props: {
      data: Object
   },
   data(){
      return{
         shortName: '',
         outputName: this.data.outputNames[this.data.index],
      }
   },
   methods: {
      deletePreset($event) {
         this.$emit('deletePreset', $event.clientY)
         EventBus.$emit('deletePresets')
      },
      saveOutputName(){
        this.$store.dispatch('processes/changeOutputName', { outputName: this.outputName, nodeId: this.data.parentNodeId, index: this.data.index })
      },
      addPresets($event) {
        this.$emit('addPresets', $event.clientY)
      }
   },
   computed: {
      settings() {
         return this.$store.getters["node/settings"]
      },
      presetName(){
        let name = this.settings.availableTransitions
          .find(node => node.idNextNode === this.data.node.db_id).presets

          return name.length > 0 && name !== undefined
            ? name.find(preset => preset.id === this.data.connect.presetId).preset_name
            : this.$t('process.unAvailablePreset')          
      },
      isFirst(){
        return !this.data.first
      }
   }
}

</script>

<style scoped lang="scss">
.node-operations {
  gap: 8px;
}
.preset-name,.canvas-name{
  font-size: 14px;
  width: 100px;
}
</style>
