import {eventsService} from "@/services/events.service";

export const events = {
  namespaced: true,
  state: {
    events: [],
  },
  getters: {
    events: state => state.events,
  },
  mutations: {
    setEvents(state, events) {
      state.events = events;
    },
  },
  actions: {
    async setEvents({commit}){
      await eventsService.getEvents()
        .then((events) => {
          commit('setEvents', events.response.events);
        })
    }
  }
}
