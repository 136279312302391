<template>
  <v-col class="mb-1 pa-0">
    <component
        v-if="item?.presetName"
      :is="widgets[item.name]"
      :widgetData="item"
    />
  </v-col>
</template>

<script>
import MainWidget from "@/components/UI/widgets/MainWidget";
import WidgetButton from "@/components/UI/widgets/WidgetButton";
import WidgetCalendar from "@/components/UI/widgets/WidgetCalendar";
import WidgetModalWindow from "@/components/UI/widgets/WidgetModalWindow";
import WidgetSlider from "@/components/UI/widgets/WidgetSlider";
import WidgetTopBar from "@/components/UI/widgets/WidgetTopBar";
import WidgetWebButtonArrow from "@/components/UI/widgets/WidgetWebButtonArrow.vue";

export default {
  name: 'WidgetDisplay',
  props: {
    item: Object,
  },
  components: {
    MainWidget, WidgetButton, WidgetCalendar,
    WidgetModalWindow, WidgetSlider, WidgetTopBar,
    WidgetWebButtonArrow
  },
  data() {
    return {
      widgets: {
        topBar: WidgetTopBar,
        button: WidgetButton,
        slider: WidgetSlider,
        modalWindow: WidgetModalWindow,
        calendar: WidgetCalendar,
        webButtonArrow: WidgetWebButtonArrow
      }
    }
  }
}
</script>

<style scoped>

</style>