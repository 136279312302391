<template>
  <div class="block">
    <div class="card ads">
        <div class="description">
            <p>{{ title }}</p>
            <img src="@/assets/images/cat.png" alt="cat">
        </div>
        <button class="btn">{{btn_text}}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: 'Transfer any amount of the credit limit to your account right now',
      type: String
    },
    btn_text: {
      default: 'Withdraw now',
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn{
      border-radius: 25px;
      background-color: #fff;
      font-weight: 600;
      font-size: 16px;
      width: 100%;
      padding: 15px;
      position: relative;
      z-index: 5;
  }
</style>