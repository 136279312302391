<template>
  <PhoneWrapper node-name="НОДА: ПРОФИЛЬ">
    <div class="phone-screen__context profile-menu">
      <ProfileHeader title="Hey Kumar"/>

      <div class="profile-menu__block">
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle">Setings</p>
          </div>
          <img class="hidden" width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img class="hidden" width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Personal Data</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img class="hidden" width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">App Settings</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item bordered">
          <div class="profile-menu__column">
            <img class="hidden" width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Security</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>

        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle">Set up communication</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle">Statement</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle">Financial advice</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle">Invite your friends</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
      </div>
      <div class="profile-menu__footer">
        <p class="phone-screen__small-text profile-menu__link">Log out</p>
        <p class="phone-screen__small-text profile-menu__link">Write to the developer</p>
        <p class="phone-screen__small-text">v 12.00002</p>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";

export default {
  name: "ProfileMenu",
  components: {ProfileHeader, PhoneWrapper},
}
</script>

<style lang="scss">
.profile-menu {
  &__block {
    margin-top: 20px;
  }
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    &.bordered {
      padding-bottom: 15px;
      border-bottom: 1px solid #E2E5EB;
    }
  }
  &__column {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: 50px;
    gap: 20px;
  }
  &__link {
    color: #007CFF;
  }
}
</style>