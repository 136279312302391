<template>
  <div class="wrapper preview__wrapper d-flex" :style="wrapperStyles">
    <img v-if="item.properties.imageUrl" :src="item.properties.imageUrl" style="height:fit-content;margin:5px 10px">
    <div :style="styles" v-html="item.properties.text"></div>
  </div>
</template>

<script>
import fieldOptions from "@/mixins/fieldOptions";

export default {
  name: "PreviewText",
  mixins: [fieldOptions]
}
</script>

<style lang="scss">
.preview__wrapper {
  p {
    p {
      display: inline !important;
    }
  }
}
</style>