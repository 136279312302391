<template>
    <div class="block">
        <template v-if="grace > 0">
            <div v-if="showEmi || showGrace" class="block event">
                <div v-if="showEmi" class="card flex flex__column flex_between">
                    <div class="next_display">
                        <div class="flex card card__center">
                            <span class="back" @click="back()"> &#8592; </span>
                            <h1 class="card__title">EMI due</h1>
                        </div>
                        <p class="card__description">
                            If you don't payback till grace period end on 20-12, you'll have EMI due by 31 Dec’ 23 of  <strong>₹7,546.26</strong>
                        </p>
                    </div>
                    <nextDisplayBtns :btn_first="pay_emi" :btn_second="pay_grace"/>
                </div>
                <div v-if="showGrace" class="card flex flex__column flex_between">
                    <div class="next_display">
                        <div class="flex card card__center">
                            <span class="back" @click="back()"> &#8592; </span>
                            <h1 class="card__title">Grace due</h1>
                        </div>
                        <Overdue_payment />
                    </div>
                    <nextDisplayBtns :btn_first="pay_emi" :is_btn_second="false"/>
                </div>
            </div>
            <div class="flex event">
                <div class="card shadow overflow" @click="eventEmi()">
                    <p class="card__title">{{ grace_next_date }}</p>
                    <p class="value">₹0<span>.00</span></p>

                    <div class="grace_not_paid">
                        <p> ₹ {{grace_not_paid_sum}} {{grace_not_paid}}</p>
                    </div>
                    <span class="circle"></span>
                    <span class="date">
                        <p>31</p>
                        <span>Dec</span>
                    </span>
                </div>
                <div class="card shadow" @click="eventGrace()">
                    <p class="card__title">Grace till 20 Dec’ 22</p>
                    <p class="value">₹{{ grace }}<span>.00</span></p>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="showEmi || showGrace" class="block event">
                <div v-if="showEmi" class="card flex flex__column flex_between">
                    <div class="next_display">
                        <div class="flex card card__center">
                            <span class="back" @click="back()"> &#8592; </span>
                            <h1 class="card__title">EMI due</h1>
                        </div>
                        <p class="card__title card__title_bold">
                            You don’t have any EMIs in this period
                        </p>
                    </div>
                    <nextDisplayBtns :active="false" :btn_first="pay_emi" :btn_second="pay_grace"/>
                </div>
                <div v-if="showGrace" class="card flex flex__column flex_between">
                    <div class="next_display">
                        <div class="flex card card__center">
                            <span class="back" @click="back()"> &#8592; </span>
                            <h1 class="card__title">Grace due</h1>
                        </div>
                        <p class="card__title card__title_bold">
                            You don’t have any transfers in this period
                        </p>
                    </div>
                    <nextDisplayBtns :btn_first="pay_emi" :is_btn_second="false"/>
                </div>
            </div>

            <div class="flex event">
                <div class="card shadow" @click="eventEmi()">
                    <p class="card__title">Next EMI</p>
                    <p class="value">₹0<span>.00</span></p>
                </div>
                <div class="card shadow" @click="eventGrace()">
                    <p class="card__title">Grace</p>
                    <p class="value">₹{{ grace }}<span>.00</span></p>
                </div>
            </div>
        </template>
        <div v-if="grace > 0" class="flex">
            <bpm-button class="btn" variant="success"><template v-slot:content>{{pay_emi}}</template></bpm-button> 
            <bpm-button class="btn" variant="success"><template v-slot:content>{{pay_grace}}</template></bpm-button> 
        </div>
    </div>
</template>

<script>
import Overdue_payment from './overduePayment.vue'
import nextDisplayBtns from './nextDisplayBtns.vue'
export default {
    data(){
        return{
            grace_not_paid_sum: 754626,
            pay_emi: 'pay EMI',
            pay_grace: 'pay Grace',
            showEmi: false,
            showGrace: false,
        }
    },
    components: {
        Overdue_payment,
        nextDisplayBtns,
    },
    props: {
        grace: {
            default: 0,
            type: Number
        },
        grace_next_date: {
            default: 'Next Due date',
            type: String
        },
        grace_not_paid: {
            default: 'If Grace not paid',
            type: String
        },
    },
    methods: {
        eventEmi(){
            this.showEmi = !this.showEmi
        },
        eventGrace(){
            this.showGrace = !this.showGrace
        },
        back(){
            this.showEmi = this.showGrace = false
        }
    }
}
</script>
<style lang="scss" scoped>

  .back{
      position: absolute;
      top: 5px;
      left: 0;
  }
  .btn{
      flex: 1;
      color: #fff;
      padding: 24px;
      text-transform: uppercase;
  }
</style>