import JsonEditorVue from 'json-editor-vue'
import SpinnerLoader from '@/components/UI/SpinnerLoader.vue'
import SearchComponent from '@/components/UI/CRUD/Search/Search.vue'

export default {
  data(){
    return {
      scrolledTop: '10px',
      scrolledHeight: 'calc(100% - 20px)',
      startedTop: '80px',
      startedHeight: 'calc(100% - 90px)',

      sortTableBy: ['id'],
      sortTableDesc: [false],
      tableItemsPerPage: 200,
      tableFooterProps: {'items-per-page-options': [50, 100, 150, 200, 250, 300]},

      search: {},
      newData: {},
      visibleEditor: false,
      visibleCopy: false,
      visibleAdd: false,
    }
  },
  components: { JsonEditorVue, SpinnerLoader, SearchComponent },
  methods: {
    trackTopPositionCol(){
      const varEditor = document.querySelectorAll('.editor-col')[0]

      if(varEditor){
        if(window.scrollY > 70){
          varEditor.style.top = this.scrolledTop
          varEditor.style.height = this.scrolledHeight
        } else {
          varEditor.style.top = this.startedTop
          varEditor.style.height = this.startedHeight
        }
      }
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (a[index[0]] === b[index[0]]) {
          return 0;
        } else if (a[index[0]] === null) { 
          return 1;
        } else if (b[index[0]] === null) {
          return -1;
        } else if (!isDesc[0]) {
          return a[index[0]] < b[index[0]] ? -1 : 1;
        } else {
          return a[index[0]] < b[index[0]] ? 1 : -1;
        }
      });
      return items;
    },
    copyData(e){
      if(typeof e === "object") e = JSON.stringify(e)

      navigator.clipboard.writeText(e)
        .then(() => {
          console.log('Success copy');
        })
        .catch(err => {
          console.error('Error copy: ', err);
        })
    },

    // add ref with part 'jsonEditor' to JsonEditor element for change color in JsonEditor
    setJsonEditorColor() {
      const elements = Object.keys(this.$refs).filter(item => item.includes('jsonEditor'))
      if(!elements?.length) return

      const elementsByRef = elements
              .map(item => this.$refs[item]?.$el)
              .filter(item => item)

      const colorRegex = /#[a-fA-F0-9]{6}\b/g;
      const colors = this.$colorAdmin.match(colorRegex);

      elementsByRef.forEach(item => {
        item.style.setProperty('--jse-theme-color', colors[0])
        item.style.setProperty('--jse-theme-color-highlight', colors[1])
      })

      // custom style of JsonEditor
      // 'vanilla-jsoneditor/themes/jse-theme-dark.css'
    },

    showCreate(){
      this.closeEditor()
      this.$nextTick(() => {
        this.visibleEditor = true
        this.visibleAdd = true

        this.newData = JSON.parse(JSON.stringify(this.templateData))
      })
    },

    enterSelect(id) { 
      this.visibleAdd = false
      this.visibleCopy = false
      if(this.visibleMethod) this.visibleMethod = false
      if(this.newData.id === id) return

      // clear selected item 
      this.newData = {}

      this.visibleEditor = true 
      this.actionShow(id)
    },

    closeEditor(){
      this.visibleEditor = false
      this.visibleAdd = false
      this.visibleCopy = false

      if(this.visibleMethod) this.visibleMethod = false
      if(this.selectedMethod) this.selectedMethod = null

      this.newData = JSON.parse(JSON.stringify(this.templateData))
    },

    parseProperties(data, property){
      if(Array.isArray(property)){
        property.forEach(item => this.parseProperties(data, item))
      } else {
        if(typeof data[property] === "string" && data[property].length){
          data[property] = JSON.parse(data[property])
        }
      }
    },
    searchInput(data){
      this.search = data
    },
    dataStatus(data){
      if(data === null) return ''
      return data
          ? 'mdi-check'
          : 'mdi-close'
    },
  },
  computed: {
    topPositionCol: {
      cache: false,
      get(){
        if(window.scrollY > 70) return {'top': this.scrolledTop, 'height': this.scrolledHeight}
        return {'top': this.startedTop, 'height': this.startedHeight}
      }
    },
    tableWidth(){
      return this.visibleEditor ? 9 : 12
    },
  },
  watch: {
    visibleEditor(value){
      if(!value) return
        this.$nextTick(() => {
          this.setJsonEditorColor()
        })
    },
    visibleAdd(value){
      if(!value) return
        this.$nextTick(() => {
          this.setJsonEditorColor()
        })
    },
    visibleCopy(value){
      if(!value) return
        this.$nextTick(() => {
          this.setJsonEditorColor()
        })
    },
    'newData.id': {
      handler: function (value) {
        if(!value) return
        this.$nextTick(() => {
          this.setJsonEditorColor()
        })
      },
      deep: true
    },
    filteredData: {
      handler(value){
        if(!value?.length) this.$refs.searchComponent.setEmptySearchData() // тут ошибка на длину
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.trackTopPositionCol);
  },
  destroyed(){
    window.removeEventListener('scroll', this.trackTopPositionCol)
  }
}