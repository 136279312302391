<template>
  <div>
    <v-img
        :src="src ? originalSrc : ''"
        :max-width="maxWidth || '100px'"
        :max-height="maxHeight || '100px'"
    />
  </div>
</template>

<script>
import imageUiMixin from "@/mixins/imageUiMixin";

export default {
  name: 'PreviewImage',
  mixins: [imageUiMixin]
}
</script>

<style scoped>
div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}
</style>