<template>
    <div class="phone-screen__wrapper ">
        <div class="phone-screen phone-screen-custom">
        <div class="phone-screen__inner">
            <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
            <div class="phone-screen__main">
                <h1 class="phone-screen__title">Homescreen</h1>
                <Overdue_payment_amount />
                <Line_credit />
            </div>
        </div>
        </div>
    </div>
    
</template>

<script>
import Overdue_payment_amount from '../widgets/homescreen/overduePaymentAmount.vue'
import Line_credit from '../widgets/homescreen/lineCredit.vue'

export default {
    components:{
        Overdue_payment_amount,
        Line_credit,
    },
    data(){
        return{
            showPopup: false
        }
    },

}
</script>