<template>
  <div class="node-screen-actions__actions">
    <v-icon
      dark
      @click="disableScreen"
      :disabled="!canHide"
      x-large
    >
      {{ visibility ? 'mdi-eye-off' : 'mdi-eye' }}
    </v-icon>
    <v-icon
      dark
      x-large
      :disabled="!visibility"
      @click="editScreen"
    >
      mdi-pencil
    </v-icon>
    <v-icon
      dark
      x-large
      v-if="screenSettings"
      @click="openScreenSettings"
    >
      mdi-cog
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "NodeScreenActions",
  props: {
    setting: {
      type: Object,
      required: true,
    },
    canHide: {
      type: Boolean,
      default: false,
    },
    screenSettings: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      // eslint-disable-next-line no-prototype-builtins
      visibility: this.setting?.properties.hasOwnProperty('visibility') ? this.setting.properties.visibility : true,
    }
  },
  methods: {
    disableScreen () {
      this.visibility = !this.visibility;
      this.$store.dispatch("node/setScreenDisable", {screenId: this.setting.id, visibility: this.visibility});
    },
    editScreen () {
      this.$store.dispatch('node/setCurrentScreenId', this.setting.id);
    },
    openScreenSettings () {
      this.$emit('openScreenSettings', this.setting.id);
    }
  }
}
</script>

<style scoped lang="scss">
.node-screen-actions {
  &__actions {
    z-index: 11;
    transition: .4s all ease;
    position: absolute;
    width: 140px;
    height: 80px;
    display: flex;
    background: #7C4DFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    justify-content: center;
    gap: 5px;
    opacity: 0;

    i {
      cursor: pointer;
    }

    &.show-btns {
      opacity: 1;
    }
  }
}
</style>
