import { defaultApiInstance } from "@/api";

const path = 'projects'
export const projectsService =  {
  getProjects,
  getProjectById,
  createProject,
  updateProject,
  deleteProject,
  getProcess,
  saveProcess,
  deleteProcess,
  getInstance,
}

async function createProject(data) {
  return defaultApiInstance.post(`/${path}`, data);
}

async function getProjects(searchData) {
  let query = `&offset=${searchData.offset}`
  const type = searchData.isType
      ? `&type=${searchData.type}`
      : ''

  if(searchData?.type) query = `${searchData.search}${type}` + query

  return defaultApiInstance.get(`/${path}?q=${query}`);
}

async function getProjectById(id){
  return defaultApiInstance.get(`/${path}/${id}`);
}

async function updateProject(data) {
  return defaultApiInstance.patch(`/${path}/${data.id}`, data);
}

async function deleteProject(id) {
  return defaultApiInstance.delete(`/${path}/${id}`);
}

async function getProcess(id){
  return defaultApiInstance.get(`/${path}/${id}/processes`);
}

async function saveProcess({id, processId}){
  return defaultApiInstance.post(`/${path}/${id}/processes`, {"processId": processId});
}

async function deleteProcess({id, processId}){
  return defaultApiInstance.delete(`/${path}/${id}/processes`, { data: { processId: processId }});
}

async function getInstance(offset){
  return defaultApiInstance.get(`/instances?q=&offset=${offset}`);
}

export const unHighlightProjectsService = [{
    method: 'post',
    url: `/${path}`
  },{
    method: 'patch',
    url: `/${path}`
  },{
    method: 'delete',
    url: `/${path}`
  },{
    method: 'get',
    url: `/${path}`
}]