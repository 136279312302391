import {AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE} from '../constants/language'

export default {
  data() {
    return {
      languages: AVAILABLE_LANGUAGES,
      language: localStorage.systemLang ?? DEFAULT_LANGUAGE
    }
  },
  methods: {
    setLang(lang){
      localStorage.systemLang = lang
      
      // reload page
      location.reload()
    }
  }
}