import Quill from 'quill'
const Inline = Quill.import('blots/inline')


// underline
class Underline extends Inline {
  static create(className) {
    const node = super.create()

    node.setAttribute('class', className)
    return node
  }
  
  static formats(node) {
    return node.getAttribute('class')
  }
}
Underline.blotName = 'underline'
Underline.tagName = 'span'

// italic
class Italic extends Inline {
  static create(className) {
    const node = super.create()

    node.setAttribute('class', className)
    return node
  }
  
  static formats(node) {
    return node.getAttribute('class')
  }
}
Italic.blotName = 'italic'
Italic.tagName = 'span'

// weight
class Weight extends Inline {
  static create(className) {
    const node = super.create()

    node.setAttribute('class', className)
    return node
  }
  
  static formats(node) {
    return node.getAttribute('class')
  }
}
Weight.blotName = 'weight'
Weight.tagName = 'span'

// color
class Color extends Inline {
  static create(className) {
    const node = super.create()

    node.setAttribute('class', className)
    return node
  }
  
  static formats(node) {
    return node.getAttribute('class')
  }
}
Color.blotName = 'color'
Color.tagName = 'span'

// format
class Format extends Inline {
  static create(className) {
    const node = super.create()

    node.setAttribute('class', className)
    return node
  }
  
  static formats(node) {
    return node.getAttribute('class')
  }
}
Format.blotName = 'format'
Format.tagName = 'span'

// link
class Link extends Inline {
    static create(link) {
      let node = super.create()

      node.setAttribute('href', link)
      // node.setAttribute('target', '_blank')
      node.setAttribute('class', 'mod-text-link')
      return node
    }

    static formats(node) {
      return node.getAttribute('href')
    }
  }
Link.blotName = 'link'
Link.tagName = 'span'

Quill.register(Underline)
Quill.register(Italic)
Quill.register(Weight)
Quill.register(Color)
Quill.register(Format)
Quill.register(Link)

export default {

}