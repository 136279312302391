<template>
    <div v-if="presets.length || presets">
      <v-select
          :label="$t('node.intoPreset.selectPreset')"
          v-model="mainPreset"
          :items="presets"
          item-text="preset_name"
          item-value="id"
          :disabled="presets.length <= 1"
          outlined
          dense
          class="mt-4"
          @change="changePreset"
      ></v-select>
      <v-select
          :label="$t('node.intoPreset.selectScreen')"
          v-model="mainScreen"
          :items="availableScreens"
          item-text="screenName"
          item-value="screenId"
          outlined
          dense
          @change="changeScreen"
      ></v-select>
    </div>
    <v-select
          v-else
          :label="$t('node.intoPreset.selectScreen')"
          v-model="mainScreen"
          :items="availableScreens"
          item-text="screenName"
          item-value="screenId"
          outlined
          dense
          @change="changeScreen"
      ></v-select>
  </template>
  
  <script>
  import { EventBus } from '@/utils/event-bus';
  export default {
      name: 'EnterNodeMainPreset',
      data(){
          return{
              mainPreset: null,
              mainScreen: null
          }
      },
      props: {
          presets: {
              type: [Array, Boolean]
          },
          screens: {
              type: Array
          },
          nodeId: {
              type: Number
          }
      },
      created(){ 
          this.mainPreset = this.presets.length 
              ? this.$store.getters["processes/enterNode"]?.presetId 
              : null
          this.mainScreen = this.$store.getters["processes/idNodeStart"]
      },
      computed: {
          availableScreens(){
              return this.presets.length > 1
                  ? this.screens.filter(screen => screen.presetId === this.mainPreset)
                  : this.screens
  
          },
          isNodeStart(){
              return this.mainPreset
                  ? this.presets.map(preset => preset.id).includes(this.mainPreset)
                  : false
          },
      },
      methods: {
          setNodeStart(){
              EventBus.$emit('updatePresetIdOfEnterNode', {nodeId: this.nodeId, presetId: null, screenId: this.availableScreens[0]?.screenId})
          },
          changePreset(newPresetId){
              EventBus.$emit('updatePresetIdOfEnterNode', {nodeId: this.nodeId, presetId: newPresetId})
              this.mainPreset = newPresetId
          },
          changeScreen(newScreenId){
              EventBus.$emit('updatePresetIdOfEnterNode', {nodeId: this.nodeId, presetId: this.mainPreset, screenId: newScreenId})
          }
      }
  }
  </script>
  
  <style>
  
  </style>