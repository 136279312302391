<template>
  <div>
    <div class="container">
      верстка экранов транша
      <div class="node-edit__views">
        <EmiPlanCombined/>
        <MainScreen/>
        <StatusDisplay/>
        <AuthOtp/>
        <TransferFunds/>
        <ProductOrder/>
        <TarifsNode/>
        <EmiPlan/>
        <RedirectToFrame/>
        <FrameNode/>
      </div>
    </div>
  </div>
</template>

<script>
import TransferFunds from "@/components/nodes/creditLine/transh/TransferFunds.vue";
import MainScreen from "@/components/nodes/creditLine/transh/MainScreen.vue";
import ProductOrder from "@/components/nodes/creditLine/transh/ProductOrder.vue";
import TarifsNode from "@/components/nodes/creditLine/transh/TarifsNode.vue";
import EmiPlan from "@/components/nodes/creditLine/transh/EmiPlan.vue";
import RedirectToFrame from "@/components/nodes/creditLine/transh/RedirectToFrame.vue";
import FrameNode from "@/components/nodes/creditLine/transh/FrameNode.vue";
import AuthOtp from "@/components/nodes/creditLine/transh/AuthOtp.vue";
import StatusDisplay from "@/components/nodes/creditLine/transh/StatusDisplay.vue";
import EmiPlanCombined from "@/components/nodes/creditLine/transh/EmiPlanCombined.vue";
export default {
  name: "TranshUx",
  components: {
    EmiPlanCombined,
    StatusDisplay,
    AuthOtp,
    FrameNode,
    RedirectToFrame,
    EmiPlan,
    TarifsNode,
    ProductOrder,
    TransferFunds,
    MainScreen,
  }
}
</script>