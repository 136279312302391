<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <SearchComponent
          :searchData="['id', 'name']"
          prefix="references"
          @searchInput="searchInput"
          ref="searchComponent"
      />
  
      <v-card class="mt-5 pb-6 rounded-xl pa-6">
        <v-btn
          fab
          dark
          small
          color="primary"
          @click="showCreate"
          class="btn-add"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-data-table
          :headers="tableHeaders"
          :items="references"
          :loading="!loading"
          :loading-text="$t('multilang.load')"
          item-key="id"
          density="compact"
          :items-per-page="tableItemsPerPage"
          :footer-props="tableFooterProps"
          class="mt-4 table"
          :sort-by="sortTableBy"
          :sort-desc="sortTableDesc"
          :custom-sort="customSort"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.id }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.propertyId`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.propertyId }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.propertyId)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.name }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.name)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div v-if="item.description?.length" class="table-item">
              <span @click="enterSelect(item.id)">{{ item.description }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.description)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <div v-if="item?.value && Object.keys(item?.value).length" class="table-item">
              <span @click="enterSelect(item.id)">{{ shrinkData(item.value) }}</span>
            </div>
            <div v-else class="table-item" @click="enterSelect(item.id)"></div>
          </template>
          <template v-slot:[`item.order`]="{ item }">
            <div v-if="item?.order" class="table-item">
              <span @click="enterSelect(item.id)">{{ item.order }}</span>
            </div>
            <div v-else class="table-item" @click="enterSelect(item.id)"></div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>

    <v-col id="right-col" cols="3" style="position: relative;" v-if="visibleEditor">
      <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
        <div style="position: fixed;">
          <v-btn
            class="btn-close"
            icon
            color="red"
            @click="closeEditor"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
          <p v-if="newData.id" class="mr-6"> Id:
            <b style="color:#9c9c9c;">{{ newData.id }}</b>
            <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
          </p>
          <v-text-field
            :label="$t('name')"
            hide-details="auto"
            outlined
            v-model="newData.name"
            require
          ></v-text-field>
          <v-autocomplete
            :items="getMethods"
            v-model="newData.propertyId"
            item-value="id"
            item-text="name"
            outlined
            :placeholder="$t('references.propertyId')"
            hide-details
            class="mt-1"
          />
          <v-text-field
            :label="$t('description')"
            hide-details="auto"
            outlined
            v-model="newData.description"
          ></v-text-field>
          <div class="json-editor">
            <span>{{ $t('value') }}</span>
            <JsonEditorVue
              v-model="newData.value"
              v-bind="JsonEditorSetting"
              class="mb-3"
              style="position: relative;"
              ref="jsonEditor-value"
            />
            <v-icon class="copy_json" @click="copyData(newData.requestJson)">mdi-content-copy</v-icon>
          </div>
          <v-text-field
            :label="$t('order')"
            hide-details="auto"
            outlined
            v-model="newData.order"
          ></v-text-field>

          <div class="editor-col__btns">
            <template v-if="newData.id">
              <v-btn :disabled="requireFields" @click="updateReference">{{ $t('update') }}</v-btn>
              <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
              <v-btn @click="deleteReference(newData.id)">{{ $t('delete') }}</v-btn>
            </template>
            <template v-else>
              <v-btn :disabled="requireFields" @click="createReference">{{ $t('create') }}</v-btn>
            </template>
          </div>
        </div>
        <SpinnerLoader 
          v-else
          color="blue" 
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";

export default{
  name: "ReferencesUniversal",

  data(){
    return{
      templateData: {
        value: {}
      },

      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('references.propertyId'), value: 'propertyId'},
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('description'), value: 'description'},
        {text: this.$t('value'), value: 'value'},
        {text: this.$t('order'), value: 'order'}
      ],
      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
      },
    }
  },
  mixins: [CRUD_UI_Mixin],
  computed: {
    filteredData(){
      return this.$store.getters['references/searchedReferences']
    },
    references(){
      const references = this.$store.getters['references/references']

      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? references
          : this.filteredData
    },

    loading(){
      return this.references && this.references.length > 0 && this.loadedAllData
    },

    loadedAllData(){
      return this.$store.getters['references/loadedAllData']
    }, 

    getMethods(){
      const methods = this.$store.getters['references/methods']

      return methods.map(method => {
        method.name = `${method.id}: ${method.name}`
        return method
      })
    },

    requireFields(){
      return (!this.newData.name || !this.newData.propertyId)
    }
  },
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('references/getDataById', {id, show: true})

      data?.value
          ? this.parseProperties(data, 'value')
          : data.value = JSON.parse(JSON.stringify(this.templateData.value))

      this.newData = data
    },

    loadData(){    
      this.$store.dispatch('references/getReferences')
      this.$store.dispatch('references/getMethods')
    },

    async createReference(){
      this.parseProperties(this.newData, 'value')

      const newId = await this.$store.dispatch('references/createReference', {newData: this.newData, searchData: this.search})

      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    duplicate(id){
      const references = this.references.find(field => field.id === id)

      references?.value
          ? this.parseProperties(references, 'value')
          : references.value = JSON.parse(JSON.stringify(this.templateData.value))

      const reference = JSON.parse(JSON.stringify(references))
      delete reference.id

      this.visibleCopy = true
      this.newData = reference
    },

    deleteReference(id){
      this.$store.dispatch('references/deleteReference', {id, searchData: this.search})

      this.closeEditor()
    },

    updateReference(){
      this.parseProperties(this.newData, 'value')
    
      this.$store.dispatch('references/updateReference', {data: JSON.parse(JSON.stringify(this.newData)), searchData: this.search})
    },

    shrinkData(data){
      const lengthOfString = 100

      return data && Object.keys(data).length
          ? JSON.stringify(data).length > lengthOfString
            ? JSON.stringify(data).slice(0, lengthOfString) + '...'
            : data
          : data
    }
  },
  created(){
    this.loadData()
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/CRUD.scss";
</style>