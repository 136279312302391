<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <SearchComponent
          :searchData="['id', 'name']"
          prefix="properties"
          @searchInput="searchInput"
          ref="searchComponent"
      />

      <v-card class="mt-5 pb-6 rounded-xl pa-6">
        <v-btn
            fab
            dark
            small
            color="primary"
            @click="showCreate"
            class="btn-add"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-data-table
            :headers="tableHeaders"
            :items="properties"
            :loading="!loading"
            :loading-text="$t('multilang.load')"
            item-key="id"
            density="compact"
            :items-per-page="tableItemsPerPage"
            :footer-props="tableFooterProps"
            class="mt-4"
            :sort-by="sortTableBy"
            :sort-desc="sortTableDesc"
            :custom-sort="customSort"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.id }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.name }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.name)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.type }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.type)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)" v-html="item.description"></span>
              <v-icon class="copy" @click.prevent="copyData(item.description)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.parentId`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">
                {{ item?.parentId ? item.parentId + ':' : '' }} {{ parentsData.find(reference => reference.id === item.parentId)?.name }}
              </span>
              <v-icon v-if="item?.parentId" class="copy" @click.prevent="copyData(item.parentId)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.isGlobal`]="{ item }">
            <div class="table-item">
              <v-icon>{{ dataStatus(item?.isGlobal) }}</v-icon>
            </div>
          </template>
          <template v-slot:[`item.isGroup`]="{ item }">
            <div class="table-item">
              <v-icon>{{ dataStatus(item?.isGroup) }}</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col cols="3" style="position: relative;" v-if="visibleEditor">
      <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
        <div style="position: fixed;">
          <v-btn
              class="btn-close"
              icon
              color="red"
              @click="closeEditor"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
          <p v-if="newData.id" class="mr-6"> Id:
            <b style="color:#9c9c9c;">{{ newData.id }}</b>
            <v-icon class="copy_visible" @click.prevent="copyData(newData.id)">mdi-content-copy</v-icon>
          </p>
          <v-text-field
              :label="$t('name')"
              hide-details="auto"
              outlined
              v-model="newData.name"
              require
          ></v-text-field>
          <v-text-field
              :label="$t('description')"
              hide-details="auto"
              outlined
              v-model="newData.description"
              require
          ></v-text-field>
          <v-autocomplete
              :items="propertiesTypes"
              v-model="newData.typeId"
              item-value="id"
              item-text="name"
              outlined
              :placeholder="$t('property.type')"
              hide-details
              class="mt-1"
          />
          <div class="editor-col__content__checkbox">
            <v-checkbox
                v-model="newData.isGlobal"
                color="green"
                class="editor-col__content__checkbox-item"
            >
              <template v-slot:label>
                <div @click.stop="">
                  {{ $t('property.isGlobal') }}
                </div>
              </template>
            </v-checkbox>
            <v-checkbox
                v-model="newData.isGroup"
                color="green"
                class="editor-col__content__checkbox-item"
            >
              <template v-slot:label>
                <div @click.stop="">
                  {{ $t('property.isGroup') }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-autocomplete
              :items="parentData"
              v-model="newData.parentId"
              item-value="id"
              item-text="name"
              outlined
              :placeholder="$t('property.parentId')"
              hide-details
              class="mt-1"
          />
          <v-autocomplete
              :items="['ref_universal', 'references_fonts']"
              v-model="newData.tableReference"
              item-value="parentId"
              outlined
              :placeholder="$t('property.tableReference')"
              hide-details
              class="mt-1"
          />
          <v-text-field
              :label="$t('order')"
              hide-details="auto"
              outlined
              v-model="newData.order"
              type="number"
              hide-spin-buttons
          ></v-text-field>
          <div>
            <div class="json-editor">
              <span>{{ $t('property.refValues') }}</span>
              <JsonEditorVue
                  v-model="newData.refValues"
                  v-bind="JsonEditorSetting"
                  class="mb-3"
                  style="position: relative;"
                  ref="jsonEditor-refValues"
              />
              <v-icon class="copy_json" @click.prevent="copyData(newData.refValues)">mdi-content-copy</v-icon>
            </div>
            <div class="json-editor">
              <span>{{ $t('property.defaultValue') }}</span>
              <JsonEditorVue
                  v-model="newData.defaultValue"
                  v-bind="JsonEditorSetting"
                  class="mb-3"
                  style="position: relative;"
                  ref="jsonEditor-response"
              />
              <v-icon class="copy_json" @click.prevent="copyData(newData.defaultValue)">mdi-content-copy</v-icon>
            </div>
          </div>
          <div class="editor-col__btns">
            <template v-if="newData.id">
              <v-btn :disabled="requireFields" @click="updateMethod">{{ $t('update') }}</v-btn>
              <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
              <v-btn @click="deleteMethod(newData.id)">{{ $t('delete') }}</v-btn>
            </template>
            <template v-else>
              <v-btn :disabled="requireFields" @click="createMethod">{{ $t('create') }}</v-btn>
            </template>
          </div>
        </div>
        <SpinnerLoader
            v-else
            color="blue"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";
export default {
  name: "propertiesPage",
  data(){
    return {
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('name'), value: 'name'},
        {text: this.$t('property.type'), value: 'type'},
        {text: this.$t('description'), value: 'description'},
        {text: this.$t('property.parentId'), value: 'parentId'},
        {text: this.$t('property.isGlobal'), value: 'isGlobal'},
        {text: this.$t('property.isGroup'), value: 'isGroup'},
      ],
      templateData: {
        isGlobal: false,
        isGroup: false,
        defaultValue: {},
        refValues: {},
      },

      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
        readOnly: true,
      },
    }
  },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('properties/getDataById', {id, show: true})

      if(this.newData?.id) {
        data.defaultValue = JSON.parse(JSON.stringify(this.templateData.defaultValue))
        data.refValues = JSON.parse(JSON.stringify(this.templateData.refValues))
      }
      this.newData = data
    },

    duplicate(id){
      let properties = this.properties.find(field => field.id === id)

      const property = JSON.parse(JSON.stringify(properties))
      delete property.id

      this.visibleCopy = true
      this.newData = property
    },

    deleteMethod(id){
      this.$store.dispatch('properties/deleteProperty', {id, searchData: this.search})

      this.closeEditor()
    },

    async createMethod(){
      let newData = JSON.parse(JSON.stringify(this.newData))
      if(newData?.defaultValue) delete newData.defaultValue
      if(newData?.refValues) delete newData.refValues

      const newId = await this.$store.dispatch('properties/createProperty', {newData, searchData: this.search})
      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    updateMethod(){
      this.$store.dispatch('properties/updateProperty',
          {data: this.updateDataForSave(), searchData: this.search}
      )
    },

    updateDataForSave(){
      let data = JSON.parse(JSON.stringify(this.newData))
      if(data?.defaultValue) delete data.defaultValue
      if(data?.refValues) delete data.refValues

      return data
    },

    loadData(){
      this.$store.dispatch('properties/getData')
      this.$store.dispatch('properties/getPropertiesTypes')
    },
  },
  computed: {
    filteredData(){
      return this.$store.getters['properties/searchedPropertiesData']
    },
    properties(){
      const sources = this.$store.getters['properties/propertiesData']

      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? sources
          : this.filteredData
    },
    parentData(){
      const properties = this.$store.getters['properties/propertiesData']
      return properties
          .filter(item => item?.isGroup)
          .map(item => ({
            id: item.id,
            name: `${item.id}: ${item.name}`
          }))
    },
    loading(){
      return this.properties && this.properties.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['properties/loadedAllData']
    },
    parentsData(){
      return this.$store.getters['properties/parentsData']
    },
    propertiesTypes(){
      return this.$store.getters['properties/propertiesTypes']
    },
    requireFields(){
      return (!this.newData.name)
    },
  },

  created(){
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/CRUD.scss";
</style>