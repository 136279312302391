<template>
  <div style="position: relative;"> 
    <button v-for="item,i in content" :key="i" @click="setCurrent(i)" :style="color(i)" v-html="item" class="btn"></button>
    <div :style="backgroundColor" class="bottom-block"></div>
  </div>
</template>

<script>
export default {
    name: 'ChangerTab',
    data(){
        return{
            current: null,
        }
    },
    props:{
        data: {
            type: Object,
            require: true,
        }
    },
    computed: {
        pressedColor(){
            return 'color: ' + this.data.colorPressed
        },
        unpressedColor(){
            return 'color: ' + this.data.colorUnpressed
        },
        backgroundColor(){
            return 'background: ' + this.data.background
        },
        activeBtn:{
            cache: false,
            get(){
                return this.current ?? this.data.state
            }
        },
        content(){
            return JSON.parse(this.data.data)
        }
    },
    methods: {
        color(index){
            let activeColor = ''
            if(this.activeBtn == ++index) activeColor = 'color: ' + this.data.colorPressed + ';' + this.backgroundColor + "; border-radius:10px;"
            else {
                let border = ''
                if(index == 1) border = 'border-radius:0 0 10px 0;'
                else if(index == this.content.length) border = 'border-radius:0 0 0 10px;'
                else border = 'border-radius:0 0 10px 10px;'
                activeColor = 'color: ' + this.data.colorUnpressed + '; background: #fff;' + border
            }
            return activeColor + `width:${100/this.content.length}%`
        },
        setCurrent(index){
            this.current = ++index
        }
    }
}
</script>

<style lang="scss" scoped>
.btn{
    padding: 10px;
    z-index: 10;
    position: relative;

    h5{
        margin-bottom: 0 !important;
    }
}
.bottom-block{
    border-radius:0 0 10px 10px;
    position: absolute;
    width: 100%; 
    height: 15px; 
    bottom: -5px; 
    z-index: 5;
}

</style>