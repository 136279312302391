<template>
  <PhoneWrapper v-if="!select" node-name="НОДА: ПЛАТЁЖИ. ЭКРАН 2: БАЛАНС. ">
    <h1 class="phone-screen__title">My balance</h1>
    <div class="block">
      <div class="card card__center flex flex__column">
        <div class="card__late_payment">
          <p class="card__title">Available balance</p>
          <div class="overdue mb-3">
            <p class="num">₹0</p>
          </div>
        </div>
        <div class="flex block">
          <bpm-button class="btn" variant="success"><template v-slot:content>WITHDRAW</template></bpm-button>
          <bpm-button class="btn" variant="success"><template v-slot:content>TOP UP</template></bpm-button>
        </div>
      </div>
    </div>
  </PhoneWrapper>
  <PhoneWrapper v-else node-name="НОДА: ВЫБОР СПОСОБА ОПЛАТЫ. ПРЕСЕТ: ПОПОЛНЕНИЕ БАЛАНСА.">
    <h1 class="phone-screen__title">My balance</h1>
    <div class="block">
      <div class="card">
        <p class="card__title card__title_bold mb-5">Enter the payment amount</p>
        <template><BpmInput type="text" placeholder="₹0.00" /></template>
      </div>
    </div>
    <paymentOption :options="[{title: 'UPI'},{title: 'Card'},{title: 'Net banking'}]" />
  </PhoneWrapper>

</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import paymentOption from "@/components/nodes/creditLine/widgets/homescreen/paymentOption.vue";
export default {
  name: "vueMyBalance",
  components: {
    PhoneWrapper,
    paymentOption,
  },
  props: {
    select: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>
.btn{
  color: #fff;
  width: 100%;
}
</style>