<template>
  <PhoneWrapper node-name="НОДА: НАСТРОЙКИ ПРИЛОЖЕНИЯ. ЭКРАН 3: ТЕМА. ">
    <div class="phone-screen__context theme">
      <ProfileHeader title="Theme"/>
      <div class="theme__block">
        <div class="theme__item">
          <p class="phone-screen__subtitle phone-screen__subtitle_tin">Light</p>
          <img src="@/assets/icons/profile/woosh-icon.svg" alt="woosh">
        </div>
        <div class="theme__item">
          <p class="phone-screen__subtitle phone-screen__subtitle_tin">Dark</p>
          <img class="hidden" src="@/assets/icons/profile/woosh-icon.svg" alt="woosh">
        </div>
        <div class="theme__item">
          <p class="phone-screen__subtitle phone-screen__subtitle_tin">Automatic</p>
          <img class="hidden" src="@/assets/icons/profile/woosh-icon.svg" alt="woosh">
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "ChooseTheme",
  components: {PhoneWrapper, ProfileHeader}
}
</script>

<style lang="scss" scoped>
.theme {
  &__block {

  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
  }
}
</style>