<template>
  <PhoneWrapper node-name="НОДА: ЭЛЕКТРОННЫЕ ДОКУМЕНТЫ. ЭКРАН 3: СКАЧИВАНИЕ ДОКУМЕНТОВ. ">
    <div class="phone-screen__context statements-download">
      <ProfileHeader title="Statements"/>

      <div class="statements-download__block">
        <div class="statements-download__item">
          <div class="statements-download__row">
            <div class="statements-download__column">
              <img src="@/assets/icons/profile/pdf-icon.svg" alt="pdf">
              <p class="phone-screen__subtitle phone-screen__subtitle_tin">Key facts statement</p>
            </div>
            <img src="@/assets/icons/transh/download.svg" alt="download">
          </div>
          <div class="statements-download__row">
            <p class="phone-screen__subtitle phone-screen__subtitle_grey">PDF, 1,24 МБ</p>
          </div>
        </div>
        <div class="statements-download__item">
          <div class="statements-download__row">
            <div class="statements-download__column">
              <img src="@/assets/icons/profile/pdf-icon.svg" alt="pdf">
              <p class="phone-screen__subtitle phone-screen__subtitle_tin">Loan agreement</p>
            </div>
            <img src="@/assets/icons/transh/download.svg" alt="download">
          </div>
          <div class="statements-download__row">
            <p class="phone-screen__subtitle phone-screen__subtitle_grey">PDF, 1,24 МБ</p>
          </div>
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "StatementsDownload",
  components: {PhoneWrapper, ProfileHeader}
}
</script>

<style lang="scss" scoped>
.statements-download {
  &__block {
    margin-top: 20px;
  }
  &__item {
    border: 2px solid #F2F3F7;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 13px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .phone-screen__subtitle_grey {
      margin-left: 34px;
    }
  }
  &__column {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>