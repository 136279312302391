<template>
  <v-card
    class="pa-6 rounded-xl"
  >
    <h2 class="text-h5 mb-6">{{ title }}</h2>
    <div
      v-for="field,i in fields"
      :key="field.name"
      :style="{ display: !field.name.includes('font') && !field.label.includes('текст') ? 'block' : 'none' }"
      class="main-props__item"
    >
      <div class="d-flex align-end mb-2">
        <label class="main-props__label mr-4" :for="field.name">{{ $t(`settings.${field.label}`)  }}</label>
        <span v-if="field.type === 'color'" class="main-props__inputs">
          <span :class="{'gradient__active' : !isGradient(field.label)}" class="main-props__input" @click="setColorMode(field.label, 'mono')">{{ $t('gradientColor.mono') }}</span>
          <span :class="{'gradient__active' : isGradient(field.label)}" class="main-props__input" @click="setColorMode(field.label, 'gradient')">{{ $t('gradientColor.gradient') }}</span>
        </span>
      </div>  
      <v-text-field
        v-if="field.type === 'text' || field.type === 'number'"
        v-model="field.value"
        :type="field.type"
        :id="field.name"
        :placeholder="$t(`settings.${field.placeholder}`)"
        outlined
        dense
      />
      <div
        v-if="field.type === 'upload'"
        class="main-props__upload"
      >
        <MainFileUpload
          :disabled="!field.accept.includes('image')"
          @selectImg="selectImg(field.name, ...arguments)"
        />
        <div class="main-props__preview">
          <MainImage
            v-if="field.accept.includes('image')"
            :max-width="'50px'"
            :max-height="'50px'"
            :src="field.value"
          />
        </div>
      </div>
      <!-- ? -->
      <v-slider
        v-if="field.type === 'slider'"
        v-model="field.value"
        :max="field.max"
        :min="field.min"
        thumb-label
      ></v-slider>
      <div v-if="field.type === 'color'" class="main-props__color">
        <gradientColors v-if="isGradient(field.label)" v-model="field.value" :type="i" />
        <MainColorPicker v-else v-model="field.value" />
      </div>
    </div>
  </v-card>
</template>

<script>
import gradientMixin from "@/mixins/gradientMixin";
import gradientColors from '@/components/UI/gradientColor/gradientColors.vue'
export default {
  name: "MainPropsCategory",
  props: {
    fields: Array,
    title: String
  },
  components: {
    gradientColors
  },
  mixins: [gradientMixin],
  methods: {
    fileInput (elem, files) {
      if (files) {
        this.workFields.find(field => field.name === elem).value = files[0];
      } else {
        this.workFields.find(field => field.name === elem).value = '';
      }
      this.$forceUpdate();
    },
    selectImg (fieldName, img) {
      this.workFields.map(field => {
        if (field.name === fieldName) {
          field.value = img;
        }
        return field;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.main-props {
  // вынести в общие стили (назвать класс универсально)
  &__label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-left: 12px;
    margin-bottom: 8px;
  }

  &__upload {
    display: flex;
  }

  &__preview {
    margin-left: 24px;
  }

  &__panel {
    padding-bottom: 0 !important;
  }

  &__color {
    padding-bottom: 26px;
  }
  &__inputs{
    border: .5px solid #E2E8F0;
    border-radius: 10px;
    margin: 0;
    display: flex;
  }
  &__input {
    padding: 8px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
  }
}

.gradient__active{
  background: #E6E6E6;
  font-size: 12px;
  font-weight: 700;
  }
</style>
