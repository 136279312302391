<template>
  <div class="node-edit__views">
    <vueHistyry />
    <vueHistoryGrace />
    <vueFilterDate />
  </div>
</template>

<script>
import vueHistyry from "@/components/nodes/creditLine/history/vueHistory.vue";
import vueHistoryGrace from "@/components/nodes/creditLine/history/vueHistoryDetails.vue";
import vueFilterDate from "@/components/nodes/creditLine/history/vueFilterDate.vue";
export default {
  name: "HistoryUx",
  components: {
    vueHistyry,
    vueHistoryGrace,
    vueFilterDate,
  }
}
</script>

<style scoped>

</style>