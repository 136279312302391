<template>
  <PhoneWrapper node-name="НОДА: ИСТОРИЯ.">
    <h1 class="phone-screen__title">History</h1>
    <div class="block">
      <div class="card flex">
        <bpm-button
            class="btn"
            variant="success">
            <template v-slot:content>Date</template>
        </bpm-button>
        <bpm-button
            class="btn"
            variant="success">
          <template v-slot:content>Withdrawals</template>
        </bpm-button>
        <bpm-button
            class="btn active"
            variant="success">
          <template v-slot:content>Top ups</template>
        </bpm-button>
      </div>
      <div class="card" v-for="date in dates" :key="date.day">
        <div class="card__title mb-5">
          {{ date.day }}
        </div>
        <div class="flex flex_between card p0 mb-5" v-for="item,i in date.value" :key="i">
          <div class="flex card p0">
            <img src="@/assets/images/gray_circle.png" alt="gray_circle">
            <div class="felx__column">
              <p class="mb-0">{{ item.title }}</p>
              <p class="mb-0">{{ item.subtitle }}</p>
            </div>
          </div>
          <p class="mb-0" :class="{'color_success': item.success}">{{item.sum}}</p>
          <input v-if="check" type="checkbox">
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "vueHistyry",
  components: {
    PhoneWrapper,
  },
  data(){
    return{
      check: false
    }
  },
  props: {
    dates:{
      default: () => [
        {
          'day': 'Today',
          'value': [
            {
              "title": 'Grace repayment',
              "subtitle": 'UPI',
              "success": true,
              'sum': '+ ₹15,000'
            },
            {
              "title": 'Loan Tranfer',
              "subtitle": 'Сard **5678',
              'sum': '- ₹11,000'
            },
          ]
        },
        {
          'day': '1 august',
          'value': [
            {
              "title": 'Grace repayment',
              "subtitle": 'UPI',
              "success": true,
              'sum': '+ ₹2,430'
            },
          ]
        },
        {
          'day': '31 july',
          'value': [
            {
              "title": 'Loan Tranfer',
              "subtitle": 'Сard **5678',
              'sum': '- ₹3,400'
            },
          ]
        },{
          'day': '30 july',
          'value': [
            {
              "title": 'Grace repayment',
              "subtitle": 'UPI',
              "success": true,
              'sum': '+ ₹2,430'
            },
          ]
        },
      ],
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  background: #F2F3F7;
  border-radius: 8px;
  border: none;
  padding: 8px 12px;

  &.active{
    background: #000;
    color: #fff;
    padding-right: 30px;

    &::after{
      content: "\00d7";
      position: absolute;
      right: 25px;
    }
   }
}

img{
  height: 40px;
  width: auto;
}
.color_success{
  color: #26CC4A;
}

.card{
  padding: 0 8px !important;
}

input{
  height: 20px;
}

</style>
