<template>
  <v-img
    :src="src ? originalSrc : ''"
    :max-width="maxWidth || '100px'"
    :max-height="maxHeight || '100px'"
  />
</template>

<script>
import imageUiMixin from "@/mixins/imageUiMixin";

export default {
  name: 'MainImage',
  mixins: [imageUiMixin]
}
</script>
