<template>
    <div class="main_tabs">
        <fieldset class="tabs">
            <input type="radio" :id="first_tab" name="verify_income" :value="first_tab" v-model="tabs" >
            <label :for="first_tab" :class="{'active_tab': active_first}">{{ first_tab }}</label>

            <input type="radio" :id="second_tab" name="verify_income" :value="second_tab" v-model="tabs">
            <label :for="second_tab">{{ second_tab }}</label>
        </fieldset>
        <div class="tabs_content">
            <template v-if="active_first">
                <div v-for="item,i in tabs_data" :key="i" class="tab_content" :class="{'danger': item.danger}">
                    <img v-if="item.image === 'gray_circle'" src="@/assets/images/gray_circle.png" alt="gray_circle">
                    <img v-if="item.image === 'gray_circle_sections'" class="circle_sections" src="@/assets/images/gray_circle_sections.png" alt="gray_circle">
                    <div class="date">
                        <p>{{item.month}}</p>
                        <span>{{item.year}}</span>
                    </div>
                    <div class="payment">
                        <p>₹{{item.sum}}</p>
                        <span>{{item.grace}}</span>
                    </div>
                    <span>></span>
                </div>
            </template>
            <template v-else>
                <div class="tab_content">
                    <img src="@/assets/images/gray_circle.png" alt="gray_circle">
                    <div class="date"><p>any</p></div>
                    <div class="payment"><p>any</p></div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        first_tab: {
            default: 'Active periods',
            type: String
        },
        second_tab: {
            default: 'Closed periods',
            type: String
        },
        tabs_data: {
            default: () => [],
            type: Array
        }
    },
    computed:{
        active_first(){
            return this.tabs === this.first_tab
        }
    },
    data(){
        return{
            tabs: this.first_tab,
        }
    }

}
</script>