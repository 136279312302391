<template>
  <PhoneWrapper node-name="НОДА: СБОР ОБРАТНОЙ СВЯЗИ. ПРЕСЕТ: СООБЩЕНИЕ. ">
    <div class="phone-screen__context">
      <ProfileHeader title="App Settings"/>

      <div class="profile-menu__block">
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Language</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Theme</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "ChooseSetting",
  components: {PhoneWrapper, ProfileHeader}
}
</script>