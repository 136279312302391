<template>
  <PhoneWrapper node-name="НОДА: ЭЛЕКТРОННЫЕ ДОКУМЕНТЫ. ЭКРАН 2: ВЫПИСКИ.">
    <div class="phone-screen__context">
      <ProfileHeader title="Borrrower account statement"/>

      <p class="phone-screen__subtitle mt-3">Choose the period</p>
      <bpmInput class="mt-3" label="from" placeholder="select" />
      <bpmInput class="mt-3" label="to" placeholder="select" />

      <p class="phone-screen__text mt-4">Choose the way you want to get the statement</p>

      <bpmCheckbox label="E-mail" class="mt-1"/>
      <bpmCheckbox label="Residential adress" class="mt-1"/>
      <bpmCheckbox label="SMS" class="mt-1"/>
      <bpmCheckbox label="Whatsapp" class="mt-1"/>

      <bpmButton class="mt-auto mb-10" style="width: 100%">
        <template v-slot:content><span style="color: white">Confirm</span></template>
      </bpmButton>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "StatementsPeriod",
  components: {PhoneWrapper, ProfileHeader}
}
</script>