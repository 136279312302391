<template>
  <div class="node-edit__views">
    <vuePaymentInformation />
    <vueRepaymentMethods />
    <vueRecurrentPayments />
    <vueNewCard />
    <vueRegularAutopayments />
    <vueDigitalMandates />
    <vueSetEmiParametrs />
    <vueMyBalance />
    <vueWithdrawal />
    <vueTransfer />
    <vueEarlyRepayment />

  </div>
</template>

<script>
import vuePaymentInformation from "@/components/nodes/creditLine/payment/vuePaymentInformation.vue";
import vueRepaymentMethods from "@/components/nodes/creditLine/payment/vueRepaymentMethods.vue";
import vueRecurrentPayments from "@/components/nodes/creditLine/payment/vueRecurrentPayments.vue";
import vueNewCard from "@/components/nodes/creditLine/payment/vueNewCard.vue";
import vueRegularAutopayments from "@/components/nodes/creditLine/payment/vueRegularAutopayments.vue";
import vueDigitalMandates from "@/components/nodes/creditLine/payment/vueDigitalMandates.vue";
import vueSetEmiParametrs from "@/components/nodes/creditLine/payment/vueSetEmiParametrs.vue";
import vueMyBalance from "@/components/nodes/creditLine/payment/vueMyBalance.vue";
import vueWithdrawal from "@/components/nodes/creditLine/payment/vueWithdrawal.vue";
import vueTransfer from "@/components/nodes/creditLine/payment/vueTransfer.vue";
import vueEarlyRepayment from "@/components/nodes/creditLine/payment/vueEarlyRepayment.vue";
export default {
  name: "PaymentUx",
  components: {
    vuePaymentInformation,
    vueRepaymentMethods,
    vueRecurrentPayments,
    vueNewCard,
    vueRegularAutopayments,
    vueDigitalMandates,
    vueSetEmiParametrs,
    vueMyBalance,
    vueWithdrawal,
    vueTransfer,
    vueEarlyRepayment,
  }
}
</script>

<style scoped>

</style>