import MainButton from "@/components/UI/MainButton";
import MainUpload from "@/components/UI/MainUpload";
import MainFileUpload from "@/components/UI/MainFileUpload";
import MainImage from "@/components/UI/MainImage";
import SpinnerLoader from "@/components/UI/SpinnerLoader";
import PanelComponent from "@/components/UI/PanelComponent";
import MainColorPicker from "@/components/UI/MainColorPicker";

import PreviewText from "@/components/UI/previews/PreviewText";
import PreviewTitle from "@/components/UI/previews/PreviewTitle";
import PreviewImage from "@/components/UI/previews/PreviewImage";
import PreviewHref from "@/components/UI/previews/PreviewHref";

export default [
  MainButton,
  MainUpload,
  PreviewTitle,
  PreviewText,
  PreviewImage,
  PreviewHref,
  SpinnerLoader,
  MainFileUpload,
  MainImage,
  PanelComponent,
  MainColorPicker
]
