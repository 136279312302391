<template>
    <div :class="{'block': !border}">
        <div class="card gray" :class="{'border': border}">
            <div class="flex flex_between">
                <div>
                    <p class="card__subtitle mb-0">Period</p>
                    <p class="mb-0">{{ period }}</p>
                </div>
                <div v-if="amount">
                    <p class="card__subtitle mb-0">Loan amount</p>
                    <p class="mb-0">₹{{ loan_amount }}</p>
                </div>
                <div v-if="emi">
                    <p class="card__subtitle mb-0">EMI pay</p>
                    <p class="mb-0">₹{{ emi_pay }}</p>
                </div>
                <span v-if="more_btn" @click="more()">></span>
                <input v-else-if="checkbox" type="checkbox" class="checkbox">
                <span v-else></span>
            </div>
            <slot></slot>
            <div v-if="grace_block" class="flex progress_sections mt-2">
                <span v-if="grace">Grace untill {{ grace_untill }}</span>
                <BpmProgress v-else-if="progress" :value="30" :max="120" />
                <span v-if="!options" class="progress_sections__term">{{grace_term}} months</span>
            </div>
            <div v-if="showMore" class="flex flex_between mt-3">
                <div>
                    <p class="mb-0">Transfer</p>
                    <p class="card__subtitle mb-0">10 Nov</p>
                </div>
                <div class="flex flex__column flex__end">
                    <p class="mb-0">₹40,000</p>
                    <span v-if="complete" class="progress_sections complete">complete</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        more_btn: {
            default: true,
            type: Boolean
        },
        period: {
            default: 'Nov 2022',
            type: Date
        },
        grace_term: {
            default: 10,
            type: Number
        },
        loan_amount: {
            default: 40000,
            type: Number
        },
        emi_pay: {
            default: 7546,
            type: Number
        },
        emi: {
            default: true,
            type: Boolean
        },
        progress: {
            default: true,
            type: Boolean
        },
        complete: {
            default: false,
            type: Boolean
        },
        grace: {
            default: false,
            type: Boolean
        },
        options: {
            default: false,
            type: Boolean
        },
        checkbox: {
            default: false,
            type: Boolean
        },
        amount: {
            default: true,
            type: Boolean
        },
        grace_block: {
            default: true,
            type: Boolean
        },
        border: {
            default: false,
            type: Boolean
        },
        grace_untill: {
            default: '20 Dec’ 23',
            type: String
        },

    },
    data(){
        return{
            showMore: false,
        }
    },
    methods: {
        more(){
            this.showMore = !this.showMore
        }
    }

}
</script>

<style>
.checkbox{
    margin: 10px 5px 0 0;
}
.border{
    border: 2px solid;
    border-radius: 15x;
    margin-bottom: 5px;
}
</style>