import {conditionsRulesService} from "@/services/conditionsRules.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'

export const conditionsRules = {
    namespaced: true,
    state: {
      rules: [],
      searchedRules: [],
      loadedAllData: false,
    },
    getters: {
      rules: state => state.rules,
      searchedRules: state => state.searchedRules,
      loadedAllData: state => state.loadedAllData,
    },
    mutations: {
      setRules(state, value) {
          state.rules = [...state.rules, ...value.conditions]
      },
      setSearchedRules(state, value){
          state.searchedRules = state.searchedRules?.length
              ? [...state.searchedRules, ...value]
              : value
      },
      deleteRule(state, {id, searchData}){
        state.rules = state.rules.filter(field => field.id !== id)
        if(Object.values(searchData).some(item => item)) state.searchedRules = state.searchedRules.filter(field => field.id !== id)
      },
      addRule(state, {dataRule, searchData}){
        state.rules.push(dataRule)
        if(Object.values(searchData).some(item => item)) {
          state.searchedRules?.length
              ? state.searchedRules.push(dataRule)
              : state.searchedRules = [dataRule]
        }
      },
      cleanSearchedRules(state){
          state.searchedRules = null
      },
      cleanRules(state){
          state.rules = []
      },
      setLoaded(state, loaded){
        state.loadedAllData = loaded
      }
    },
    actions: {
      async getRules({commit, getters, dispatch}, payload) {
        const response = await conditionsRulesService.getConditionRules({offset: payload?.offset || 0})

        if(getters.rules.length >= response.response.total - 1) commit('cleanRules')
        commit('setRules', response.response)

        const loaded = await requestWithOffset(response.response, payload, 'getRules', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataFiltered({commit, dispatch}, searchData){
          const isType = false // need to hide type in request
          const keys = Object.keys(searchData)
          const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

          const data = {
              isType,
              type: availableKey,
              search: availableKey ? searchData[availableKey] : '',
              offset: searchData?.offset || 0
          }
          const response = await conditionsRulesService.getConditionRules(data)

        if(!response?.response?.conditions?.length) {
          dispatch('alert/error', i18n.t('rules.alert.searchError'), { root: true })
          return commit('setLoaded', true)
        }
        commit('setSearchedRules', response.response.conditions)

        const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataById({commit, dispatch}, {id, show}) {
        const response = await conditionsRulesService.getConditionRuleById(id)

        commit('setLoaded', true)
        if(!response?.response?.id) return dispatch('alert/error', i18n.t('rules.alert.searchError'), { root: true })
        if(!show) {
          commit('setSearchedRules', [response.response])
          commit('setLoaded', true)
        }

        return response.response
      },

      loadData({commit}){
        commit('setLoaded', false)
      },

      cleanSearchedData({commit}){
          commit('cleanSearchedRules')
      },

      async createRule({commit, dispatch}, {newData, searchData}){
          const response = await conditionsRulesService.createConditionRule(newData)
          

          if(!response.response || response.status === 0) {
              dispatch('alert/error', i18n.t('rules.alert.createError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('rules.alert.createSuccess'), { root: true })

              const createdRule = await conditionsRulesService.getConditionRuleById(response.response.id)
              commit('addRule', {dataRule: createdRule.response, searchData})

              return response.response.id
          }
      },
     
      async updateRule({commit, dispatch}, {data, searchData}){
          const response = await conditionsRulesService.updateConditionRule(data)

          if(!response?.response || response.status === 0){
              dispatch('alert/error', i18n.t('rules.alert.updateError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('rules.alert.updateSuccess'), { root: true })

              commit('deleteRule', {id: data.id, searchData})
              commit('addRule', {dataRule: data, searchData})
          }
      },
      async deleteRule({commit, dispatch}, {id, searchData}){
          const response = await conditionsRulesService.deleteConditionRule(id)

          if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
              dispatch('alert/error', i18n.t('rules.alert.deleteError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('rules.alert.deleteSuccess'), { root: true })

              searchData?.id
                ? dispatch('cleanSearchedData')
                : commit('deleteRule', {id, searchData})
          }
      }
    }
}