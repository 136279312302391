<template>
    <div>
      <!-- <div class="container"> -->
        <div class="node-edit__views">
            <vueActiveProduct />
            <vueActiveProductDebts />
            <vueHomeScreen />
            <vueLatePayment />
            <vueLocalBlock />
            <vueVKYC />
            <vuePaymentAmount />
            <vueGraceDue />
            <vueCloseGraceDue />
            <vueEmiParametrs />
        </div>
      <!-- </div> -->
    </div>
  </template>
  
  <script>
  import vueActiveProduct from '@/components/nodes/creditLine/homescreen/vueActiveProduct.vue'
  import vueActiveProductDebts from '@/components/nodes/creditLine/homescreen/vueActiveProductDebts.vue';
  import vueHomeScreen from '@/components/nodes/creditLine/homescreen/vueHomeScreen.vue';
  import vueLatePayment from '@/components/nodes/creditLine/homescreen/vueLatePayment.vue';
  import vueLocalBlock from '@/components/nodes/creditLine/homescreen/vueLocalBlock.vue';
  import vueVKYC from '@/components/nodes/creditLine/homescreen/vueVKYC.vue';
  import vuePaymentAmount from '@/components/nodes/creditLine/homescreen/vuePaymentAmount.vue';
  import vueGraceDue from '@/components/nodes/creditLine/homescreen/vueGraceDue.vue';
  import vueCloseGraceDue from '@/components/nodes/creditLine/homescreen/vueCloseGraceDue.vue';
  import vueEmiParametrs from '@/components/nodes/creditLine/homescreen/vueEmiParametrs.vue';

  export default {
    name: "HomeScreenUx",
    components: {
        vueActiveProduct,
        vueActiveProductDebts,
        vueHomeScreen,
        vueLatePayment,
        vueLocalBlock,
        vueVKYC,
        vuePaymentAmount,
        vueGraceDue,
        vueCloseGraceDue,
        vueEmiParametrs,
    }
  }
  </script>
  
  <style scoped>
  .container{
    width: 375px;
    height: 770px;
    border: 1px solid;
    padding: 16px;
  }
  
  </style>