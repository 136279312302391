import { defaultApiInstance } from "@/api";

export const actionsService = {
  getActions,
  createAction,
  changeAction,
  deleteAction,
}

async function getActions() {
  return await defaultApiInstance.get('actions');
}

async function createAction(name) {
  return await defaultApiInstance.post('actions', 
    {
      name,
      "action": "action.log()"
    }
  )
}

async function changeAction(action_id) {
  return await defaultApiInstance.post(`actions/${action_id}`, 
    {
      "name": "new_test_action",
      "action": "action.log()"
    }
  )
}

async function deleteAction(action_id) {
  return await defaultApiInstance.delete(`actions/${action_id}`);
}