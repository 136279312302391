import { defaultApiInstance } from "@/api";

export const extService = {
  getMethods,
  getMethodById,
  updateMethod,
  createMethod,
  deleteMethod,
  getMethodTypes,
  getMethodApis,
}

async function createMethod(data) {
    return defaultApiInstance.post('/extApi/methods', data);
}

async function getMethods(searchData) {
    let query = `&offset=${searchData.offset}`
    if(searchData?.type) query = `${searchData.search}&type=${searchData.type}` + query

    return defaultApiInstance.get(`/extApi/methods?q=${query}`);
}

async function getMethodById(id){
  return defaultApiInstance.get(`/extApi/methods/${id}`);
}

async function getMethodTypes(){
  return defaultApiInstance.get('/extApi/methods/types');
}

async function getMethodApis(){
  return defaultApiInstance.get('/extApi');
}

async function updateMethod(data) {
    return defaultApiInstance.patch(`/extApi/methods/${data.id}`, data);
}

async function deleteMethod(id) {
  return defaultApiInstance.delete(`/extApi/methods/${id}`);
}

export const unHighlightExtServices = [{
  method: 'post',
  url: '/extApi/methods'
},{
  method: 'patch',
  url: '/extApi/methods'
},{
  method: 'delete',
  url: '/extApi/methods'
},{
  method: 'get',
  url: '/extApi/methods'
}]