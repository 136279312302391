import { defaultApiInstance } from "@/api";

const path = 'actions'
export const actionsCRUDService = {
    getData,
    getDataById,
    updateAction,
    createAction,
    deleteAction,
    getActionsTypes,
}

async function createAction(data) {
    return defaultApiInstance.post(`/${path}`, data);
}

async function getData(searchData) {
    let query = `&offset=${searchData.offset}`
    const type = searchData.isType
        ? `&type=${searchData.type}`
        : ''
    if(searchData?.type) query = `${searchData.search}${type}` + query

    return defaultApiInstance.get(`/${path}?q=${query}`);
}

async function getDataById(id){
    return defaultApiInstance.get(`/${path}/${id}`);
}

async function getActionsTypes({offset}){
    let query = `&offset=${offset}`
    return defaultApiInstance.get(`/${path}/types?q=${query}`);
}

async function updateAction(data) {
    return defaultApiInstance.patch(`/${path}/${data.id}`, data);
}

async function deleteAction(id) {
    return defaultApiInstance.delete(`/${path}/${id}`);
}

export const unHighlightActionsService = [{
    method: 'post',
    url: `/${path}`
},{
    method: 'patch',
    url: `/${path}`
},{
    method: 'delete',
    url: `/${path}`
},{
    method: 'get',
    url: `/${path}`
}]