<template>
  <SpinnerLoader v-if="loading"/>
  <v-card
    v-else
    class="pb-6 rounded-xl"
  >
    <div v-if="error">
      {{ $t('multilang.error') }}
    </div>
    <div class="d-flex pa-6">
      <div class="d-flex">
        <h2 class="text-h5 mr-2">Nodes</h2>
        <v-btn
          class="mr-2"
          color="deep-purple accent-2"
          dark
          outlined
          @click="downloadExcel"
        >
          <v-icon>
            mdi-download
          </v-icon>
          {{ $t('download') }}
        </v-btn>
        <div>
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ref="excelFile"
            @input="uploadExcel"
            style="display: none"
          />
          <v-btn
            class="mr-2"
            color="deep-purple accent-2"
            dark
            outlined
            @click="$refs.excelFile.click()"
            id="translate__upload-file"
          >
            <v-icon>
              mdi-upload
            </v-icon>
            {{ $t('upload') }}
          </v-btn>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex">
        <v-select
          v-model="select"
          :items="searchOptions"
          class="mr-2"
          label="lang"
          color="deep-purple accent-2"
          outlined
          dense
          clearable
          @change="changeActiveSearchOptions"
        ></v-select>
        <SearchInput
          :disabled="!activeSearchOptions.length"
          :search-text="tableParams.searchText"
          @input="searchInTexts"
          id="translate__search"
        />
      </div>
    </div>

    <PaginationComponent
      class="mx-6"
      :disabled="tableLoading"
      :limit-items="[5, 10, 15, 20, 'all']"
      :limit="tableParams.limit"
      :current-page="tableParams.currentPage"
      :pageCount="pageCount"
      @change="paginationInput"
    />

    <v-data-table
      ref="data-table"
      :loading="tableLoading"
      :loading-text="$t('multilang.load')"
      :headers="headers"
      :items="texts"
      item-key="nodeName"
      sort-by="nodeName"
      group-by="nodeName"
      :hide-default-footer="true"
      :disable-pagination="true"
      @update:sort-by="sortTable"
      @update:sort-desc="sortTable"
      id="translate-table"
    >
      <template v-if="texts.length" v-slot:[`group.header`]="{items, isOpen, toggle}">
        <th colspan="100">
          <v-icon @click="toggle"
          :id="`translate-table__toggle_${items[0].nodeName}`"
          >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          {{ items[0].nodeName }}
        </th>
      </template>
      <template v-if="texts.length" v-slot:item="{ item }">
        <tr>
          <td>{{ item.screenName }}</td>
          <td>{{ item.fieldName }}</td>
          <td>
            <v-edit-dialog
              :return-value.sync="item.alias"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
              @save="changeText(item)"
              @open="editDialogOpen(item)"
            >
              {{ item.alias}}
              <template v-slot:input>
                <div class="mt-4 text-h6">
                  {{ $t('edit') }}
                </div>
                <v-textarea
                  auto-grow
                  dense
                  rows="1"
                  v-model="item.alias"
                  :placeholder="$t('multilang.enterContent')"
                  single-line
                ></v-textarea>
              </template>
            </v-edit-dialog>
          </td>
          <td
            v-for="translate in item.translations"
            :key="translate.id"
          >
            <v-edit-dialog
              :return-value.sync="translate.text"
              :save-text="$t('save')"
              :cancel-text="$t('cancel')"
              large
              @save="changeText(item)"
              @open="editDialogOpen(item)"
            >
              {{ translate.text }}
              <template v-slot:input>
                <div class="mt-4 text-h6">
                  {{ $t('edit') }}
                </div>
                <v-textarea
                  auto-grow
                  dense
                  rows="1"
                  v-model="translate.text"
                  :placeholder="$t('multilang.enterContent')"
                  single-line
                ></v-textarea>
              </template>
            </v-edit-dialog>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import SpinnerLoader from "@/components/UI/SpinnerLoader";
import SearchInput from "@/components/UI/SearchInput";
import PaginationComponent from "@/components/UI/PaginationComponent";

export default {
  name: "AppTranslate",
  components: {
    PaginationComponent,
    SearchInput,
    SpinnerLoader
  },
  data () {
    return {
      tableLoading: false,
      activeSearchOptions: [],
      options: {},
      error: false,
      loadAnchor: null,
      tableParams: {
        searchText: '',
        offset: 0,
        currentPage: 1,
        limit: 10,
        asc: null,
        desc: null,
      },
      editedText: {},
      loading: false,
      select: null,
    }
  },
  computed: {

    // texts for tables
    texts () {
      return this.$store.getters["multilang/texts"];
    },

    // count of paginations page
    pageCount () {
      return Math.ceil(this.$store.getters["multilang/totalResults"] / this.tableParams.limit);
    },

    // empty info
    emptyInfo () {
      return this.$store.getters["multilang/emptyInfo"];
    },

    // get all available languages from texts json
    availableLanguages () {
      return this.texts.length
          ? this.texts[0].translations.map(lang => {
            const empty = this.emptyInfo.find((inf) => inf.langName === lang.langName).emptyFieldsNumber;
            return {text: `${lang.langName} [empty ${empty}]`, value: lang.langName}
          })
          : [];
    },

    // generate headers with needed languages
    headers () {
      return [
        {
          text: 'Screen name',
          align: 'start',
          value: 'screenName',
          groupable: false
        },
        { text: 'Field name', value: 'fieldName'},
        { text: 'Alias', value: 'alias'},
        ...this.availableLanguages
      ]
    },

    // options for search input
    searchOptions () {
      return [
        // {
        //   text: 'screen name',
        //   value: "screenName"
        // },
        // {
        //   text: 'field ID',
        //   value: "field ID"
        // },
        // {
        //   text: 'field name',
        //   value: "fieldName"
        // },
        // {
        //   text: 'alias',
        //   value: "alias"
        // },
        ...this.availableLanguages
      ]
    },
  },

  // get initial info
  async beforeMount () {
    this.loading = true;
    try {
      await this.getTexts();
      await this.getEmptyInfo();
      this.error = false;
    } catch (e) {
      console.log('e - ', e);
      this.error = true;
      this.loading = false;
    } finally {
      this.loading = false;
    }
  },


  // mounted() {
  //   // choose anchor for loading table content on scroll
  //   this.loadAnchor = document.getElementsByClassName("main-content")[0];
  // },
  activated () {
    if (this.tableParams.limit === 'all') {
      window.addEventListener('scroll', this.loadOnScroll)
    }
  },

  // remove listener on destroy component
  deactivated () {
    window.removeEventListener('scroll', this.loadOnScroll)
  },

  methods: {

    /*
    * get texts for table with params
    * */
    async getTexts () {
      this.tableLoading = true;
      await this.$store.dispatch("multilang/getTexts", {...this.tableParams, filters: this.activeSearchOptions});
      this.tableLoading = false;
    },

    /*
    *  get texts and stack them on scroll
    * */
    async getTextsOnScroll (isReset = false) {
      this.tableLoading = true;
      this.$set(this.tableParams, 'offset', isReset ? 0 : this.tableParams.offset + 15);
      if (this.tableParams.offset >= this.$store.getters["multilang/totalResults"]) {
        window.removeEventListener('scroll', this.loadOnScroll);
      } else {
        await this.$store.dispatch('multilang/getTextsOnScroll', {...this.tableParams, limit: 15, filters: this.activeSearchOptions, isReset});
      }
      this.tableLoading = false;
    },

    // load tables data while scrolling
    async loadOnScroll (e) {
      console.log("e - ", e);
      // if((e.target.documentElement.offsetHeight + e.target.scrollingElement.scrollTop) >= e.target.scrollingElement.scrollHeight) {
      if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
        console.log("load");
        window.removeEventListener('scroll', this.loadOnScroll);
        await this.getTextsOnScroll();
        window.addEventListener('scroll', this.loadOnScroll);
        this.$set(this.tableParams, 'currentPage', this.tableParams.currentPage + 1);
      }
    },

    // get reset texts on sort or filters
    async getResetTexts () {
      this.$set(this.tableParams, 'offset', 0);
      this.$set(this.tableParams, 'currentPage', 1);
      console.log("anchor - ", this.loadAnchor);
      window.removeEventListener('scroll', this.loadOnScroll);
      if (this.tableParams.limit === 'all') {
        window.addEventListener('scroll', this.loadOnScroll);
        await this.getTextsOnScroll(true);
      } else {
        await this.getTexts();
      }
    },

    /*
    * Get info about empty fields in table
    * */
    async getEmptyInfo () {
      await this.$store.dispatch('multilang/getEmptyInfo');
    },

    // change pagination page
    async paginationInput ({limit, currentPage}) {
      this.$set(this.tableParams, 'limit', limit);
      this.$set(this.tableParams, 'currentPage', currentPage);
      if (this.tableParams.limit === 'all') {
        await this.getResetTexts();
      } else {
        this.$set(this.tableParams, 'offset', (this.tableParams.currentPage - 1) * this.tableParams.limit);
        if (this.tableParams.offset >= this.$store.getters["multilang/totalResults"]) {
          this.$set(this.tableParams, 'offset', 0);
          this.$set(this.tableParams, 'currentPage', 1);
        }
        window.removeEventListener('scroll', this.loadOnScroll);
        await this.getTexts();
      }
    },

    // sort table by column on server
    async sortTable (e) {
      if (typeof e === 'string') {
        this.$set(this.tableParams, 'desc', null);
        this.$set(this.tableParams, 'asc', e);
      } else if (typeof e === 'boolean' && e) {
        this.$set(this.tableParams, 'desc', this.tableParams.asc);
        this.$set(this.tableParams, 'asc', null);
      } else {
        this.$set(this.tableParams, 'desc', null);
        this.$set(this.tableParams, 'asc', null);
      }
      await this.getResetTexts();
    },

    // search by searchText and filters params in texts
    async searchInTexts (e) {
     this.$set(this.tableParams, 'searchText', e);
     await this.getResetTexts();
    },

    // download excel with user params
    async downloadExcel () {
      const downloadLink = await this.$store.dispatch('multilang/downloadExcel', {...this.tableParams, filters: this.activeSearchOptions});
      window.open(downloadLink);
    },

    // upload custom excel
    async uploadExcel (e) {
      const files = Array.from(e.target.scrollingElement.files);
      const uploadResponse = await this.$store.dispatch('multilang/uploadExcel', files);
      if (uploadResponse.response?.status === 'completed') {
        await this.getResetTexts();
      }
    },

    // change active search option && text if it null
    changeActiveSearchOptions (option) {
      if (this.activeSearchOptions.includes(option)) {
        const index = this.activeSearchOptions.indexOf(option);
        this.activeSearchOptions.splice(index, 1);
        if (!this.activeSearchOptions.length) {
          this.$set(this.tableParams, 'searchText', '');
        }
        this.searchInTexts(this.tableParams.searchText)
      } else {
        if (option) {
          this.activeSearchOptions.push(option);
        } else {
          this.activeSearchOptions = []
        }
      }
    },

    // save to temp editedText while open edit dialog
    editDialogOpen (item) {
      this.editedText = JSON.parse(JSON.stringify(item));
    },

    // save translations change
    async changeText (item) {
      try {
        this.tableLoading = true;
        await this.$store.dispatch('multilang/editText', {translation: item, tempEdit: this.editedText});
      } catch (e) {
        console.log('e - ', e);
      } finally {
        this.tableLoading = false;
      }
    },
  }
}
</script>
