<template>
  <PhoneWrapper node-name="НОДА: ОТОБРАЖЕНИЕ ФРЕЙМА. ПРЕСЕТ: RAZOR PAY.">
    <h1 class="phone-screen__title">Add a new card</h1>
    <div class="block">
      <div class="card">
        <div class="bank_card">
          <template><BpmInput type="number" placeholder="0000 0000 0000 0000" /></template>
          <template><BpmInput type="text" placeholder="Cardholder name" /></template>
          <div class="flex">
            <template><BpmInput type="number" placeholder="mm/yy" /></template>
            <template><BpmInput type="number" placeholder="CVC" /></template>
          </div>
        </div>
      </div>
      <div class="card">
        <template><BpmInput type="text" placeholder="Name you card" /></template>
      </div>
      <div class="card">
        <div class="card flex flex_between mb-3">
          <p class="card__title">Use by default</p>
          <input type="checkbox">
        </div>
        <div class="card flex flex_between">
          <p class="card__title">Set up regular autopayments</p>
          <span>&#62;</span>
        </div>
      </div>
      <nextButton btn_name="Save" />
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import nextButton from "@/components/nodes/creditLine/widgets/payment/nextButton.vue";
export default {
  name: "vueNewCard",
  components: {
    PhoneWrapper,
    nextButton,
  }
}
</script>

<style lang="scss" scoped>
.bank_card{
  background: #F8F8F8;
  border: 1px solid #696969;
  padding: 48px 16px 32px;
  border-radius: 16px;
  width: 100%;
  height: 211px;
  gap: 16px;

    input {
      background: #F8F8F8 !important;
    }
}
</style>