<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    floating
    hide-overlay
    class="nav-component"
    :permanent="miniSidebar"
    :expand-on-hover="miniSidebar"
    :width="304"
    :mini-variant="miniSidebar"
    :mini-variant-width="104"
    :style="{ zIndex: 11 }"
  >
    <v-list
      nav
    >
      <v-list-item-group
        v-model="selectedItem"
        sub-group
      >
        <router-link
          v-for="link in sidebarItems"
          v-slot="{ navigate }"
          :key="link.link"
          :to="link.link"
          custom
        >
          <v-list-item
            v-if="showGroupMenu(link.name)"
            @click="navigate"
          >
            <v-list-item-icon>
              <v-icon :color="link.color">{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text=" $t(`menu.${link.title}`)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group 
            v-else
            :prepend-icon="link.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="$t(`menu.${link.title}`)"></v-list-item-title>
              </v-list-item-content>
            </template>

            <router-link
              v-for="item,i in link.subItems"
              v-slot="{ navigate }"
              :key="i"
              :to="item.link"
              custom
            >
              <v-list-item
                @click="navigate"
                
              >
                <v-list-item-icon>
                  <v-icon :color="item.color">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text=" $t(`menu.${item.title}`)"></v-list-item-title>
                </v-list-item-content>

              </v-list-item>
            </router-link>
          </v-list-group>

        </router-link>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import menuOptions from "@/mixins/menuOptions";

export default {
  name: "NavComponent",
  mixins: [
    menuOptions
  ],
  props: {
    miniSidebar: Boolean
  },
  computed: {
    // logoSrc () {
    //   return this.$store.getters["project/projectLogo"];
    // }
  },
  data: () => ({
    // задавать
    selectedItem: 0,
    inactiveMenuItems: [
      'cms',
      'variables-and-rules',
      'integration',
      'references',
      'projects-and-processes',
      'properties',
      'actions',
    ]
  }),
  methods: {
    handleInput (value) {
      console.log(value)
    },
    showGroupMenu(name){
      return !this.inactiveMenuItems.includes(name)
    }
  },
  created () {
    this.selectedItem = this.sidebarItems.findIndex(link => link.name === this.$route.name)
  }
};
</script>

<style scoped lang="scss">
</style>
