<template>
    <div class="phone-screen__wrapper ">
        <div class="phone-screen phone-screen-custom">
        <div class="phone-screen__inner">
            <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
            <div class="phone-screen__main">
                <h1 class="phone-screen__title">Close Grace period</h1>
                <div class="block">
                    <div class="card">
                        <p class="card__title card__title_bold">Select an option</p>
                    </div>
                </div>
                
                <div class="block full_hight">
                    <div class="card flex flex__column flex_between">

                        <Overdue_payment :emi="false" :progress="false" :grace="true" :more_btn="false" :options="true" :checkbox="true"/>
                        <Overdue_payment :emi="false" :progress="false" :more_btn="false" :options="true" :checkbox="true" :amount="false">
                            <Overdue_payment :emi="false" :progress="false" :grace="true" :more_btn="false" :options="true" :border="true"/>
                            <Overdue_payment :emi="false" :progress="false" :grace="true" :more_btn="false" :options="true" :border="true"/>
                        </Overdue_payment>
                        <nextDisplayBtns btn_first="Pay now" :is_btn_second="false"/>

                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import Overdue_payment from '../widgets/homescreen/overduePayment.vue'
import nextDisplayBtns from '../widgets/homescreen/nextDisplayBtns.vue'

export default {
    components:{
        Overdue_payment,
        nextDisplayBtns,
    },
    computed: {
        // grace(){
        //     return 40000
        // },
        // maxAvailable(){
        //     return 100000
        // }
    },
    data(){
        return{
            showPopup: false
        }
    },

}
</script>