 <template>
    <div>
        <bpmButton class="button" :class="{'button_gradient' : isGradient}">
            <span style="color: white">{{ nameButton }}</span>
        </bpmButton>
    </div>
  </template>
  
  <script>
  import widgetOptions from "@/mixins/widgetOptions";
  export default {
      name: "WidgetButton",
      mixins: [widgetOptions],
      computed:{
        isGradient(){
            let gradient = this.fieldsType
            return gradient?.slice(-1)[0] === 'gradient'
        },
        nameButton(){
            let name = this.fieldsType
            if(name[1] === 'gradient') return
            let nameBtn = name[1] !== undefined
                ? name[1]
                : 'default name'
            if(nameBtn === 'getstarted') nameBtn = 'Get started'
            
            return nameBtn[0]?.toUpperCase() + nameBtn?.slice(1)
        }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .button{
    background: #B51ACE;
    border: none;
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    padding: 17px 50px;
    &_gradient{
        background: linear-gradient(90deg, rgba(169,45,210,1) 11%, rgba(151,71,255,1) 24%, rgba(51,156,137,1) 64%, rgba(67,176,42,1) 92%);
        &:hover{
            background: linear-gradient(90deg, rgba(169,45,210,1) 11%, rgba(151,71,255,1) 24%, rgba(51,156,137,1) 64%, rgba(67,176,42,1) 92%) !important;
            box-shadow: 0px 2px 10px rgba(78,106,88,0.35) !important;
        }
        &:focus{
            background: linear-gradient(90deg, rgba(169,45,210,1) 11%, rgba(151,71,255,1) 24%, rgba(51,156,137,1) 64%, rgba(67,176,42,1) 92%) !important;
        }
    }
    &:hover{
        background: #B51ACE;
        box-shadow: 0px 2px 10px rgba(78,106,88,0.35);
    }
    &:focus{
        background: #B51ACE;
    }
  }
  
  </style>