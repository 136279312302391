<template>
    <div class="block">
    <div class="card shadow">
        <p class="card__title">{{title}}</p>
        <p v-if="outstanding === 0" class="card__subtitle">{{ sub_title }}</p>
        <ProgressBar :available="available" :outstanding="outstanding" />

        <div v-if="outstanding === 0" class="flex" @click="1">
            <p>{{tarif_btn}}</p>
            <span>></span> 
        </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from './progressBar.vue';
export default {
    components: {
        ProgressBar
    },
    props: {
        outstanding: {
            default: 0,
            type: Number
        },
        available:{
            default: 0,
            type: Number
        },
        title: {
            default: 'Credit balance',
            type: String
        },
        sub_title: {
            default: "Kumar, you don't have active credit balance",
            type: String
        },
        tarif_btn: {
            default: 'Tariffs',
            type: String
        }
    }

}
</script>

<style lang="scss" scoped>

.bottom{
    display: flex;
    justify-content: space-between;
    margin: 10px 0 5px;
    p{
        margin: 0;
    }
    span{
        margin-right: 8px;
        color: #848485;
        height: 5px;
    }
}
</style>