<template>
  <PhoneWrapper node-name="НОДА: ВЫБОР СПОСОБА ОПЛАТЫ. ПРЕСЕТ: WITHDRAWAL.">
    <h1 class="phone-screen__title">Withdrawal</h1>
    <div class="block">
      <div class="card">
        <p class="card__title_bold card__title">Available for withdrawal:</p>
        <p class="mt-3 pl-4">₹3,500.00</p>
      </div>
      <div class="card">
        <p class="card__title_bold card__title mb-4">You may withdraw the balance minus the following:</p>
        <div class="flex flex_between">
          <p class="gray_text">Next EMI</p>
          <p>₹1,000.00</p>
        </div>
        <div class="flex flex_between">
          <p class="gray_text">Amount overdue</p>
          <p>₹350.00</p>
        </div>
        <div class="flex flex_between">
          <p class="gray_text">Overdue penalties charges</p>
          <p>₹150.00</p>
        </div>
      </div>
    </div>
    <paymentOption :options="[{title: 'UPI'} ,{title: 'Net banking'}]" />

  </PhoneWrapper>

</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import paymentOption from "@/components/nodes/creditLine/widgets/homescreen/paymentOption.vue";
export default {
  name: "vueWithdrawal",
  components: {
    PhoneWrapper,
    paymentOption
  }
}
</script>

<style scoped>
.gray_text{
  color: #7F7F7F;
}

</style>