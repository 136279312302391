<template>
  <PhoneWrapper node-name="ПЕРЕХОД НА ФРЕЙМ. ПРЕСЕТ: E-MANDATE.">
    <h1 class="phone-screen__title mb-6">Transfer</h1>
    <h1 class="phone-screen__title">Setup auto-repay. Sign Mandate (E-Nach)</h1>

    <div class="redirect__block">
      <div class="redirect__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Loan amount</p>
        <p class="phone-screen__title phone-screen__title_tin">₹40,000.00</p>
      </div>
      <div class="redirect__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Maximum EMI</p>
        <p class="phone-screen__subtitle phone-screen__subtitle_tin">₹7,546.26</p>
      </div>
      <div class="redirect__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Repayment amount</p>
        <p class="phone-screen__subtitle phone-screen__subtitle_tin">₹50,370.25</p>
      </div>
      <div class="redirect__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Installment date</p>
        <p class="phone-screen__subtitle phone-screen__subtitle_tin">last working day
          of month</p>
      </div>
      <div class="redirect__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Loan repayment date</p>
        <p class="phone-screen__subtitle phone-screen__subtitle_tin">30 Sep’ 23</p>
      </div>
      <div class="redirect__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Mandate valid until</p>
        <p class="phone-screen__subtitle phone-screen__subtitle_tin">30 Nov’ 23</p>
      </div>
    </div>
    <bpmButton class="mt-auto mb-10" style="width: 100%">
      <template v-slot:content><span style="color: white">Confirm</span></template>
    </bpmButton>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";

export default {
  name: "RedirectToFrame",
  components: {PhoneWrapper}
}
</script>

<style lang="scss" scoped>
.redirect {
  &__block {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .phone-screen__subtitle,
    .phone-screen__title{
      max-width: 125px;
    }
    .phone-screen__subtitle_tin {
      text-align: right;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>