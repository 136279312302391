const AVAILABLE_CANVAS_THEMES = [
  {
    name: "Light",
    value: "orange",
  },
  {
    name: "Dark",
    value: "purple",
  },
  // {
  //   name: "Зеленая",
  //   value: "green",
  // },
  // {
  //   name: "Тест ",
  //   value: "pale",
  // },
];

const DEFAULT_CANVAS_THEME = "orange";

export {
  AVAILABLE_CANVAS_THEMES,
  DEFAULT_CANVAS_THEME,
};
