<template>
  <PhoneWrapper node-name="НОДА: E-MANDATES.  ПРЕСЕТ: СПИСОК">
    <h1 class="phone-screen__title">Digital mandates</h1>
    <div class="block">
      <div class="card">
        <p class="mb-0">I confirm that the bank details have been entered correctly and belong to me. I agree to repay the loan even if I do not receive any money due to incorrect details.</p>
      </div>
      <div class="card">
        <div class="mandates" v-for="(item, i) in mandates" :key="i">
          <div class="flex flex_between" >
            <p>Status:</p>
            <p>{{item.status}}</p>
          </div>
          <div class="flex flex_between">
            <p>Date of signing:</p>
            <p>{{item.date}}</p>
          </div>
          <div class="flex flex_between">
            <p>Loan amount:</p>
            <p>{{item.loan_amount}}</p>
          </div>
          <div class="flex flex_between">
            <p>Repayment amount:</p>
            <p>{{item.repayment_amount}}</p>
          </div>
          <div class="flex flex_between">
            <p>Maximum EMI:</p>
            <p>{{item.max_emi}}</p>
          </div>
          <div class="flex flex_between">
            <p>Payment EMI date:</p>
            <p>{{item.payment_emi_date}}</p>
          </div>
          <div class="flex flex_between">
            <p>Loan repayment date:</p>
            <p>{{item.loan_repayment_date}}</p>
          </div>
          <div class="flex flex_between">
            <p>Mandate valid until:</p>
            <p>{{item.mandate_valid_until}}</p>
          </div>

        </div>
      </div>
    </div>
  </PhoneWrapper>

</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "vueDigitalMandates",
  components: {
    PhoneWrapper,
  },
  props: {
    mandates: {
      default: () => [
        {
          status: 'Active',
          date: '07.09.2022',
          loan_amount: '₹40 000',
          repayment_amount: '₹46 892,20',
          max_emi: '₹5 758,22',
          payment_emi_date: 'Penultimate day of each month',
          loan_repayment_date: '31.07.2023',
          mandate_valid_until: '30.09.2023',
        },{
          status: 'Cancelled',
          date: '01.07.2022',
          loan_amount: '₹12 000',
          repayment_amount: '13 406,77',
          max_emi: '₹2 406,77',
          payment_emi_date: 'Penultimate day of each month',
          loan_repayment_date: '31.05.2023',
          mandate_valid_until: '30.07.2023',
        },
      ],
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>

.mandates{
  border-bottom: 1px solid;
  padding-top: 10px;

  p{
    margin-bottom: 10px;
    &:nth-child(2n){
      text-align: right;
      color: #888888;
    }
  }
}

</style>