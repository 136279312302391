<template>
  <div class="tab-bar">
    <div class="tab-bar__item">
      <v-img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg"/>
      <span class="tab-bar__text">Home</span>
    </div>
    <div class="tab-bar__item">
      <v-img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg"/>
      <span class="tab-bar__text">History</span>
    </div>
    <div class="tab-bar__item">
      <v-img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg"/>
      <span class="tab-bar__text">Chat</span>
    </div>
    <div class="tab-bar__item">
      <v-img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg"/>
      <span class="tab-bar__text">Payments</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
}
</script>

<style lang="scss" scoped>
.tab-bar {
  display: flex;
  justify-content: space-around;
  padding: 9px 0;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #616161;
  }
}
</style>