<template>
  <div class="general-node-settings">
    <EnterNodeMainPreset :presets="presets" :screens="screensCurrentNode" :nodeId="nodeId" />
  <!-- Сначала выводим кастомные настройки для определенной ноды -->
    <GeneralPresets
        v-if="presets"
        :presets="presets"
        :selectedPreset="selectedPreset"
        @getNodePreset="getNodePreset"
    />
  <!-- Потом выводим общие настройки для определенной ноды, которые приходят с сервера -->
  </div>
</template>

<script>
import generalSettingsMixin from "@/mixins/generalSettingsMixin";
import GeneralPresets from "@/components/nodes/general-node-settings/GeneralPresets.vue";
import EnterNodeMainPreset from "./EnterNodeMainPreset.vue"

export default {
  name: 'GeneralNodeSettings',
  components: {
    GeneralPresets,
    EnterNodeMainPreset
  },
  mixins: [
    generalSettingsMixin
  ],
  data () {
    return {
      generalSettings: this.$store.getters["node/generalNodeSettings"]
    }
  },
  computed: {
    nodeType () {
      return this.$store.getters["node/nodeType"];
    },
    screensCurrentNode() {
      return this.$store.getters["node/availableScreens"]
          .filter(screen => screen.nodeId === this.nodeId)
    },
  },
  mounted () {
    if(!this.generalSettings) {
      this.$emit('hideGeneral', false)
    } else {
      this.$emit('hideGeneral', true)
    }
  }
}
</script>
<style lang="scss" scoped>
.general-node-settings{
  width: 100%;
}
</style>
