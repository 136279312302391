<template>
  <v-navigation-drawer
    v-model="drawer"
    right
    fixed
    hide-overlay
    stateless
    class="nav-business-process"
    :width="400"
    :style="{ zIndex: 11 }"
  >
    <div class="py-4 px-6 d-flex justify-space-between">
      <h2 class="text-h5">{{ title }}</h2>
      <v-btn
        icon
        @click="closeNav"
      >
        <v-icon>
          mdi-window-close
        </v-icon>
      </v-btn>
    </div>
    <v-divider></v-divider>
    <div class="py-4 px-6">
      <v-form
        ref="navProcessForm"
        v-model="valid"
        lazy-validation
      >
        <div>
          <label class="nav-business-process__label" for="name-of-process">{{ $t('process.processName') }}</label>
          <v-text-field
            v-model="nameOfProcessValue"
            :rules="[rules.required]"
            name="name-of-process"
            id="name-of-process"
            :placeholder="$t('process.plProcessName')"
            outlined
            dense
            required
          />
        </div>
        <v-select
          :label="$t('process.processTheme')"
          item-text="name"
          item-value="value"
          :value="isEditNav ? canvasTheme : createCanvasTheme"
          :items="availableCanvasThemesList"
          color="deep-purple accent-2"
          return-object
          outlined
          dense
          @change="isEditNav ? changeCanvasTheme($event) : changeCanvasThemeOnCreate($event)"
        />
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            dark
            color="grey"
            class="mr-2"
            @click="isEditNav ? deleteProcess() : closeNav()"
          >{{ buttonOne }}</v-btn>
          <v-btn
            small
            dark
            color="green darken-2"
            class="mr-2"
            @click="isEditNav ? saveProcess() : addProcess()"
          >{{ buttonTwo }}</v-btn>
<!--          <v-btn-->
<!--              v-if="isEditNav"-->
<!--              small-->
<!--              dark-->
<!--              color="deep-purple accent-2"-->
<!--              @click="copyProcess()"-->
<!--          >Копировать</v-btn>-->
        </div>
      </v-form>
    </div>
    <ConfirmModal
      ref="confirmDelete"
    />
  </v-navigation-drawer>
</template>

<script>
import ConfirmModal from "@/components/utils/ConfirmModal";
import canvas, {AVAILABLE_CANVAS_THEMES, DEFAULT_CANVAS_THEME} from "@/constants/canvas";

export default {
  name: 'NavBusinessProcess',
  components: {
    ConfirmModal
  },
  watch: {
    showNav: {
      handler: function (value) {
        this.drawer = value
      },
      immediate: true
    },
    nameOfProcess: {
      handler: function (value) {
        if (this.isEditNav) {
          this.nameOfProcessValue = this.nameOfProcessOld = value
        }
      },
      immediate: true
    },
    isEditNav: {
      handler: function (value) {
        if (value) {
          this.nameOfProcessValue = this.nameOfProcessOld = this.nameOfProcess
        } else {
          this.nameOfProcessValue = null
        }
      },
      immediate: true
    },
  },
  data: () => ({
    drawer: true,
    nameOfProcessValue: null,
    rules: {
      required: value => !!value || 'Required'
    },
    createCanvasTheme: DEFAULT_CANVAS_THEME,
    valid: true,
    nameOfProcessOld: '',
    updateName: false,
  }),
  computed: {
    showNav () {
      return this.$store.getters['processes/showNav']
    },
    isEditNav () {
      return this.$store.getters['processes/isEditNav']
    },
    process () {
      return this.$store.getters['processes/process']
    },
    nameOfProcess () {
      return this.$store.getters["processes/nameOfProcess"]
    },
    idOfProcess () {
      return this.$store.getters["processes/idOfProcess"]
    },
    title () {
      return this.isEditNav ? this.$t('process.editProcess') : this.$t('process.createProcess')
    },
    buttonOne () {
      return this.isEditNav ? this.$t('process.delete') : this.$t('process.cancel')
    },
    buttonTwo () {
      return this.isEditNav ? this.$t('process.save') : this.$t('process.create')
    },
    processes () {
      return this.$store.getters["processes/processes"];
    },
    canvasTheme () {
      return this.$store.getters["processes/canvasTheme"] || DEFAULT_CANVAS_THEME;
    },
    availableCanvasThemesList () {
      return AVAILABLE_CANVAS_THEMES;
    },
  },
  methods: {
    async copyProcess () {
      const proc_copy = {process_n8n: {...this.$store.getters["processes/process"].process_n8n}, process_name: `${this.$store.getters["processes/process"].process_name}_copy`};
      await this.createProcess(proc_copy);
    },
    closeNav () {
      this.reset()
      this.$store.dispatch('processes/changeShowNav', false);
    },
    async deleteProcess () {
      if (await this.$refs.confirmDelete.open(
          this.$t('process.deleteProcess'),
          this.$t('process.confDeleteProcess')
      )) {
        await this.$store.dispatch('processes/deleteProcess', this.idOfProcess)
        this.$store.dispatch('processes/changeReloadProcesses', true)
        this.closeNav()
      }
    },
    async saveProcess () {
      await this.$store.dispatch('processes/updateNameProcess', this.nameOfProcessValue);
      
      let index = this.processes.map(item => item.id).indexOf(+localStorage.getItem('processId'))
      this.$set(this.processes[index], 'process_name', this.nameOfProcessValue)
      this.$store.dispatch('processes/changeReloadProcesses', true)
      this.closeNav()
    },
    async addProcess () {
      this.validate()

      if (this.valid && this.nameOfProcessValue && this.nameOfProcessValue !== '') {
        const defaultProccessSchema = {
          nodes: {},
          connections: {},
          options: {
            /**
             * Размер сетки
             */
            cellSize: 50,
            /**
             * Включает/отключает привязки
             */
            gridBinding: true,
            canvasTheme: this.createCanvasTheme,
          },
          availableNodes: {}
        }

        const process_settings = {
          process_name: this.nameOfProcessValue,
          process_n8n: defaultProccessSchema
        }
        await this.createProcess(process_settings);
      }
    },
    async createProcess (workflow) {
      const resp = await this.$store.dispatch('processes/addProcess', workflow);
      if (resp?.status === 1) {
        this.$store.dispatch('processes/setNewProcess', workflow.process_name);
      }
      this.$store.dispatch('processes/changeReloadProcesses', true)
      this.closeNav()
    },
    validate () {
      this.$refs.navProcessForm.validate()
    },
    reset () {
      this.$refs.navProcessForm.resetValidation()
      this.nameOfProcessValue = this.nameOfProcessOld
    },
    changeCanvasThemeOnCreate (theme) {
      this.createCanvasTheme = theme.value;
    },
    changeCanvasTheme (theme) {
      this.$store.dispatch('processes/setCanvasTheme', theme.value)
    },
  }
}
</script>

<style scoped lang="scss">
.nav-business-process {
  z-index: 1000 !important;
  &__label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
  }
}
</style>
