<template>
  <div>
    <!-- <div v-show="isCompleted">
      <div v-if="isCompleted">
        <p>Сервис - {{ serviceItems.find(el => el.value === selectedValues.service).label }}</p>
        <p>Запрос - {{ queryItems[selectedValues.service].find(el => el.value === selectedValues.query).label }}</p>
        <p>Ответ - {{ answerItems[selectedValues.query].find(el => el.value === selectedValues.answer).label }}</p>
      </div>
    </div> -->
    <div v-show="!isCompleted">

      <v-row class="fields">
        <v-col cols="12" sm="3" class="px-0 py-0">
          <v-subheader v-text="$t('node.intoCondition.action')"></v-subheader>
        </v-col>
        <v-col cols="12" sm="9" class="px-0 py-0">

          <v-select
              :items="serviceItems"
              item-text="label"
              item-value="value"
              clearable
              label="Выберите сервис"
              v-model="selectedValues.service"
              @change="changeSelectValue('service')"
          />
        </v-col>
      </v-row>
      <v-row class="fields">
        <v-col cols="12" sm="3" class="px-0 py-0">
          <v-subheader v-text="$t('node.intoCondition.service')"></v-subheader>
        </v-col>
        <v-col cols="12" sm="9" class="px-0 py-0">
          <v-select 
            :items="queryItems[selectedValues.service]" 
            item-text="label" 
            item-value="value" 
            clearable
            label="Выберите запрос" 
            v-model="selectedValues.query" 
            @change="changeSelectValue('query')" 
            :disabled="!selectedValues.service"
          />
        </v-col>
      </v-row>
      <v-row class="fields">
        <v-col cols="12" sm="3" class="px-0 py-0">
          <v-subheader v-text="$t('node.intoCondition.request')"></v-subheader>
        </v-col>
        <v-col cols="12" sm="9" class="px-0 py-0">
          <v-select 
            :items="answerItems[selectedValues.query]" 
            item-text="label" 
            item-value="value" :error="isError" 
            clearable
            label="Выберите ответ" 
            v-model="selectedValues.answer" 
            @change="changeSelectValue('answer')" 
            :disabled="!selectedValues.query"
          />
        </v-col>
      </v-row>

    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceOperator",
  props: {
    isCompleted: {
      required: true,
      type: Boolean,
    },
    initValues: {
      type: Object,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      serviceItems: [
        {
          value: 'LMS',
          label: 'LMS',
        },
        {
          value: 'RISK ENGINE',
          label: 'RISK ENGINE',
        },
        {
          value: "info",
          label: "Получение инфо от устройства"
        },
        {
          value: 'CRM',
          label: 'CRM',
        }
      ],
      queryItems: {
        LMS: [
          {
            value:  'user_base',
            label: 'Проверка наличия пользователя в базе МБ'
          }
        ],
        "RISK ENGINE": [
          {
            value:  'scoring',
            label: 'Запрос результата скоринга'
          }
        ],
        info: [
          {
            value: "auth_check",
            label: "проверка авторизации на устройстве"
          }
        ]
      },
      answerItems: {
        user_base: [
          {
            value:  'base_yes',
            label: 'Пользователь есть в базе'
          },
          {
            value:  'base_no',
            label: 'Пользователя нет в базе'
          }
        ],
        scoring: [
          {
            value:  'address_data',
            label: 'Необходимы данные: Адрес регистрации'
          },
          {
            value:  'other_data',
            label: 'Необходимы данные: Прочие данные'
          },
          {
            value:  'job_data',
            label: 'Необходимы данные: Данные работодателя'
          },
          {
            value:  'doc_data',
            label: 'Необходимы данные: Загрузка документов'
          },
          {
            value:  'success_credit',
            label: 'Заявка на кредит одобрена'
          },
          {
            value:  'cancel_credit',
            label: 'В выдаче кредита отказано'
          },
          {
            value:  'watch_credit',
            label: 'На рассмотрении'
          },
        ],
        "auth_check": [
          {
            value: "auth",
            label: "клиент авторизован",
          },
          {
            value: "not_auth",
            label: "клиент не авторизован",
          }
        ]
      },
      selectedValues: {
          service: null,
          query: null,
          answer: null,
      }
    }
  },
  created() {
    if (this.initValues) {
      this.selectedValues = this.initValues
    }
  },
  computed: {
    isButtonsAvailable() {
      return this.selectedValues[Object.keys(this.selectedValues)[Object.keys(this.selectedValues).length - 1]];
    }
  },
  methods: {
    changeSelectValue(value) {
      let valueIndex = Object.keys(this.selectedValues).indexOf(value);
      Object.keys(this.selectedValues).forEach((key, index) => {
        if (index > valueIndex) {
          this.selectedValues[key] = null;
        }
      })
      // this.$forceUpdate();
    }
  },
  watch: {
    'isButtonsAvailable'(new_val) {
      if (new_val) {
        this.$emit('fillOperator', true, this.selectedValues);
      } else {
        this.$emit('fillOperator', false, this.selectedValues);
      }
    }
  }
}
</script>

<style scoped>

</style>