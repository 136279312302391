import { render, staticRenderFns } from "./TarifsNode.vue?vue&type=template&id=117b5a2a&scoped=true"
import script from "./TarifsNode.vue?vue&type=script&lang=js"
export * from "./TarifsNode.vue?vue&type=script&lang=js"
import style0 from "./TarifsNode.vue?vue&type=style&index=0&id=117b5a2a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "117b5a2a",
  null
  
)

export default component.exports