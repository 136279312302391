export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.$emit('close', value)
      }
    }
  },
}
