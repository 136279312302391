import { render, staticRenderFns } from "./IdentifyPhone.vue?vue&type=template&id=5951c602&scoped=true"
import script from "./IdentifyPhone.vue?vue&type=script&lang=js"
export * from "./IdentifyPhone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5951c602",
  null
  
)

export default component.exports