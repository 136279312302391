<template>
  <div class="phone-screen__wrapper ">
            <div class="phone-screen phone-screen-custom">
            <div class="phone-screen__inner">
                <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
                <div class="phone-screen__main">
                <h1 class="phone-screen__title">Homescreen</h1>
                    <popupBg />
                    <popupBlock />
                    <Next_emi_and_grace :grace="10" />
                    <Credit_balance :outstanding="40" :available="100" />
                </div>
            </div>
            </div>
        </div>
</template>

<script>
import popupBg from '../widgets/homescreen/popupBg.vue';
import popupBlock from '../widgets/homescreen/popupBlock.vue';
import Next_emi_and_grace from '../widgets/homescreen/nextEmiGrace.vue'
import Credit_balance from '../widgets/homescreen/creditBalance.vue';
        
export default {
    components: {
        popupBg,
        popupBlock,
        Next_emi_and_grace,
        Credit_balance,
    },
    props: {
        title: {
            default: 'Hey Kumar!',
            type: String
        },
        text: {
            default: 'We are pleased to announce that an increase in the credit limit of 20,000 rupees is available to you. Offer available until 18.11.2022.',
            type: String
        }
    }
}
</script>

<style lang="scss" scoped>

</style>