import { defaultApiInstance } from "@/api";

export const conditionsRulesService = {
  getConditionRules,
  getConditionRuleById,
  updateConditionRule,
  createConditionRule,
  deleteConditionRule,
}

async function createConditionRule(data) {
    return defaultApiInstance.post('/conditionsRules', data);
}

async function getConditionRules({offset, search}) {
    let query = `&offset=${offset}`
    if(search) query = search + query

    return defaultApiInstance.get(`/conditionsRules?q=${query}`);
}

async function getConditionRuleById(id){
  return defaultApiInstance.get(`/conditionsRules/${id}`);
}

async function updateConditionRule(data) {
    return defaultApiInstance.patch(`/conditionsRules/${data.id}`, data);
}

async function deleteConditionRule(id) {
  return defaultApiInstance.delete(`/conditionsRules/${id}`);
}

export const unHighlightConditionsRulesService = [{
  method: 'post',
  url: '/conditionsRules'
},{
  method: 'patch',
  url: '/conditionsRules'
},{
  method: 'delete',
  url: '/conditionsRules'
},{
  method: 'get',
  url: '/conditionsRules'
}]