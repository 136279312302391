<template>
  <PhoneWrapper node-name="ПЕРСОНАЛЬНЫЕ НАСТРОЙКИ. ЭКРАН: ОСНОВНОЙ">
    <div class="phone-screen__context settings">
      <ProfileHeader title="Personal Settings"/>

      <div class="d-flex justify-center align-center mt-4" >
        <v-img contain width="126" height="126" lazy-src="@/assets/icons/transh/avatar.svg" src="@/assets/icons/transh/avatar.svg" />
      </div>

      <div class="d-flex justify-center align-center mt-4">
        <p class="profile__subtitle mr-1">Kumar</p>
        <img src="@/assets/icons/profile/pen.svg" alt="pen">
      </div>

      <div class="settings__block">
        <div class="settings__item">
          <div class="d-flex align-center">
            <img src="@/assets/icons/transh/tab-bar-icon.svg" alt="icon">
            <p class="profile__subtitle profile__subtitle_tin ml-2">+91 987 833 8015</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="settings__item">
          <div class="d-flex align-center">
            <img src="@/assets/icons/transh/tab-bar-icon.svg" alt="icon">
            <p class="profile__subtitle profile__subtitle_tin ml-2">E-mail</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="settings__item">
          <div class="d-flex align-center">
            <img src="@/assets/icons/transh/tab-bar-icon.svg" alt="icon">
            <p class="profile__subtitle profile__subtitle_tin ml-2">Address</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="settings__item">
          <div class="d-flex align-center">
            <img src="@/assets/icons/transh/tab-bar-icon.svg" alt="icon">
            <p class="profile__subtitle profile__subtitle_tin ml-2">Change code word</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
      </div>
      <div class="profile__text mt-auto mb-6 text-center ma-auto" style="max-width: 120px; width: 100%">
        Last session
        14.08.2022 13:30
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";

export default {
  name: "PersonalSettings",
  components: {ProfileHeader, PhoneWrapper},
}
</script>

<style lang="scss" scoped>
.settings {
  &__block {
    margin-top: 40px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>