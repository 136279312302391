<template>
  <div class="main-upload">
    <input
      type="file"
      ref="file_input"
      hidden
      :multiple="multiple"
      :label="label"
      :disabled="disabled"
      :accept="accept"
      @change="onFileChange"
    />
    <v-btn
      color="deep-purple accent-2"
      text
      class="body-2 font-weight-bold"
      :disabled="disabled"
      @click.native="chooseFile"
    >{{ label }}</v-btn>
  </div>
</template>

<script>
export default {
  name: "MainUpload",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '',
      required: true
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    disabled: [Boolean]
  },
  methods: {
    chooseFile () {
      const fileInput = this.$refs.file_input;
      fileInput.click();
    },
    onFileChange (e) {
      let files = Array.from(e.target.files);
      if (files) {
        if (files.some(i => i.size > 1000000)) {
          this.$store.dispatch('alert/error', new Error('Некоторые файлы весят больше 1 МБ'))
          files = files.filter(i => i.size < 1000000)
        }
        if (files.length) {
          this.$emit('input', files);
        }
      }
    }
  }
}
</script>
