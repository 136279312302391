<template>
  <PhoneWrapper node-name="НОДА: ИСТОРИЯ. ЭКРАН 1: ОБЩАЯ ИНФОРМАЦИЯ.">
  <div class="background"></div>
    <div class="block date_filter">
      <div class="card">
          <div class="flex flex_between date">
            <p>Date</p>
            <bpm-button
                class="btn"
                variant="success">
              <template v-slot:content>x</template>
            </bpm-button>
          </div>
        <div class="filter_btn flex flex__content_center">
          <bpm-button
              class="btn"
              variant="success">
            <template v-slot:content>Start date</template>
          </bpm-button>
          <bpm-button
              class="btn"
              variant="success">
            <template v-slot:content>End date</template>
          </bpm-button>
        </div>
          <ul class="weekdays">
            <li>Mon</li>
            <li>Tue</li>
            <li>Wed</li>
            <li>Thu</li>
            <li>Fri</li>
            <li>Sat</li>
            <li>Sun</li>
          </ul>
          <div v-for="(month,i) in months" :key="i" class="card mb-0">
            <p class="mb-2">{{month.month}}</p>
            <ul class="days">
              <li v-for="(item,q) in month.days" :key="q" :class="{'circle': is_active_date(item, month.month)}">
                {{item}}
              </li>
            </ul>
          </div>
        <div class="full_btn flex flex__column ">
          <bpm-button
              variant="success">
            <template v-slot:content>Apply</template>
          </bpm-button>
          <bpm-button
              variant="success">
            <template v-slot:content>Cancel</template>
          </bpm-button>
        </div>
      </div>
    </div>
  </PhoneWrapper>

</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "vueFilterDate",
  components: {
    PhoneWrapper,
    // nextDisplayBtns,
  },
  data(){
    return{
      months:[
        {
          month: "April 22",
          days: ['', '', '', '', '', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
        },{
          month: "May 22",
          days: ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
          // days: ['', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
        },
      ],
      active_date: {
        month: "May 22",
        day: 20
      }
    }
  },
  methods: {
    is_active_date(day, month){
      return this.active_date.month === month && this.active_date.day === day
    }
  }

}
</script>

<style lang="scss" scoped>
.full_btn{
  gap: 15px !important;
  button{
    background: #7C7C7C;
    border: none;
    border-radius: 8px;
      &:nth-child(2n) {
        background: #F2F2F2;
        &:hover,:focus{
          background: #F2F2F2;
        }
      }
      &:hover,:focus{
        background: #7C7C7C;
      }
    }
  }


.background{
  height: 20%;
  width: 100%;
  background: #000;
  opacity: .5;
  z-index: 2;
  position: absolute;
}

.date_filter{
  z-index: 10;
  margin-top: 60px;
  width: calc(100% + 34px) !important;
  background: #fff;
  border-radius: 20px 20px 0 0;
  padding: 10px;
  overflow-y: hidden;
}
.date{
  .btn{
    padding: 0 10px;
    height: 30px;
    width: 30px;
  }
}
.date,.filter_btn{
  .btn{
    background: #F2F3F7;
    border: none;
  }
}
.filter_btn{
  color: gray;
  .btn{
    border-radius: 10px;
    padding: 10px 30px;
  }
}
.circle{
  border: 1px solid;
  border-radius: 50%;
}

.weekdays {
  margin: 0;
  padding: 10px 0;
}

.weekdays li {
  color: #969FA8;
  text-align: center;
  width: 13.6%;
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
}

.days {
  padding: 10px 0;
  margin: 0;
}

.days li {
  list-style-type: none;
  display: inline-block;
  width: 13.6%;
  text-align: center;
  margin-bottom: 5px;
  font-size: 17px;
  color: #777;
}

</style>