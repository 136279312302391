<template>
    <div class="phone-screen__wrapper ">
        <div class="phone-screen phone-screen-custom">
        <div class="phone-screen__inner">
            <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
            <div class="phone-screen__main">
            <h1 class="phone-screen__title">Homescreen</h1>
                <Next_emi_and_grace :grace="grace" />
                <Credit_balance :outstanding="grace" :available="maxAvailable" />
            </div>
        </div>
        </div>
    </div>
    
</template>

<script>
import Next_emi_and_grace from '../widgets/homescreen/nextEmiGrace.vue'
import Credit_balance from '../widgets/homescreen/creditBalance.vue'
export default {
    components:{
        Next_emi_and_grace,
        Credit_balance,
    },
    computed: {
        grace(){
            return 40000
        },
        maxAvailable(){
            return 100000
        }
    },
    data(){
        return{
            showPopup: false
        }
    },
}
</script>
