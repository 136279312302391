<template>
    <div class="block">
        <div class="card flex card_warning">
        <img src="@/assets/images/gray_circle.png" class="card__circle" alt="gray_circle">
        <div class="card__content">
            <p class="card_warning__title">{{ title }} <span>></span></p>
            <div class="card_warning__sum">₹{{sum}}</div>
            <div class="card_warning__description">
                <p>Planned payment date: {{date}}</p>
                <p>Fines accrued: ₹{{fines}}</p>
            </div>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        title: {
            default: 'Overdue payment amount',
            type: String
        },
        fines: {
            default: 9313,
            type: Number
        },
        date: {
            default: '31.10.2022',
            type: String
        },
        sum: {
            default: 8320,
            type: Number
        }
    }
  
  }
  </script>

  