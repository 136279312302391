<template>
    <div>
      <div class="slider">
        <p class="slider__title">Grace period up to 51 days</p>
        <p class="slider__subtitle">free, without hidden fees and overpayments</p>
        <img src="@/assets/images/cat_fun.svg" alt="imgCat">
      </div>
    </div>
  </template>
  
  <script>
  import widgetOptions from "@/mixins/widgetOptions";
  export default {
    name: "WidgetSlider",
    mixins: [widgetOptions],
  }
  </script>
  
  <style lang="scss" scoped>
    .slider{
        padding: 20px 20px 0;
        background: #E9F6E5;
        text-align: center;
        border-radius: 0 0 30px 30px;
        &__title{
            font-weight: 600;
            font-size: 20px;
        }
        &__subtitle{
            font-weight: 400;
            font-size: 17px;
            margin: 0 auto 20px;
        }
        img{
            width: 60%;
        }
    }
  
  </style>