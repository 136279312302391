<template>
    <div>
        <template v-if="isArray">
            <div v-for="text,i in withoutTag" :key="i" class="d-flex flex-column" style="position: relative;">
                 <quillEditor :options="textEditorOption" style="width:96%" class="mb-3" :value="text" @input="update(i, $event)" />
                 <button v-if="list.nodeType !== 'dropDownList'" class="btn_delete" @click="del(i)">&#x2715;</button>
             </div> 
             <v-btn v-if="list.nodeType !== 'dropDownList'" elevation="0" class="white--text" small color="deep-purple accent-2" @click="add">
                 Добавить
           </v-btn>
        </template>
        <quillEditor v-else :options="textEditorOption" style="width:100%" class="mb-3" v-model="withoutTag" />
    </div>
 </template>
 
 <script>
 import { quillEditor } from 'vue-quill-editor'
 export default {
     name: 'MainList',
     data(){
         return{
             listData: this.list,
             textEditorOption: {
                 modules: {
                     toolbar: ['bold', 'italic', 'underline', 'link',
                         { 'color': ['#FFFFFF', '#0E1A22', '#242424', '#B51ACE', '#E127FF', '#43B02A', '#5A717C', '#707070', '#A0A0A0', '#E32A5F'] }
                     ]
                 },
             }
         }
     },
     components: {
         quillEditor,
     },
     props: {
         list: {
            type: Object
        }
    },
     methods: {
         del(index){
            let parse = JSON.parse(this.listData.properties.data)
            parse.splice(index, 1)
            
            this.listData.properties.data = JSON.stringify(parse)
         },
         add(){
            let parse = JSON.parse(this.listData.properties.data)
            parse.push('')

            this.listData.properties.data = JSON.stringify(parse)
         },
         update(index, $event){
             this.withoutTag = {index, text: $event}
         }
     },
     computed: {
         isArray(){
             return Array.isArray(JSON.parse(this.listData.properties.data))
         },
         withoutTag:{
             cache: false,
             get(){
                if(this.isArray) return JSON.parse(this.listData.properties.data)
                return this.listData.properties.data
             },
             set(val){
                if(this.isArray){
                    let parse = JSON.parse(this.listData.properties.data)
                    parse[val.index] = val.text

                    return this.listData.properties.data = JSON.stringify(parse)
                }
                this.listData.properties.data = val
             }
         }
         
     }
 
 }
 </script>
 
 <style lang="scss" scoped>
 .btn_delete{
     position: absolute; 
     right: 0; 
     top: 20px;
 }
 </style>