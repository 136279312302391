<template>
    <div class="flex flex__end next_display_btn">
        <bpm-button class="btn" :class="{'active' : active,'disabled': !active}" variant="success"><template v-slot:content>{{btn_first}}</template></bpm-button> 
        <bpm-button v-if="is_btn_second" class="btn" :class="{'active' : active,'disabled': !active}" variant="success"><template v-slot:content>{{btn_second}}</template></bpm-button> 
    </div>
</template>

<script>
export default {
    props: {
        active: {
            default: true,
            type: Boolean
        },
        btn_first:{
            default: '',
            type: String
        },
        btn_second:{
            default: '',
            type: String
        },
        is_btn_second: {
            default: true,
            type: Boolean
        }
    }
}
</script>