<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <SearchComponent
          :searchData="['id', 'name']"
          prefix="actionsCRUD"
          @searchInput="searchInput"
          ref="searchComponent"
      />

      <v-card class="mt-5 pb-6 rounded-xl pa-6">
        <v-btn
            fab
            dark
            small
            color="primary"
            @click="showCreate"
            class="btn-add"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-data-table
            :headers="tableHeaders"
            :items="actions"
            :loading="!loading"
            :loading-text="$t('multilang.load')"
            item-key="id"
            density="compact"
            :items-per-page="tableItemsPerPage"
            :footer-props="tableFooterProps"
            class="mt-4"
            :sort-by="sortTableBy"
            :sort-desc="sortTableDesc"
            :custom-sort="customSort"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.id }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.actionName`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.actionName }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.actionName)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.actionAlias`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.actionAlias }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.actionAlias)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.actionType`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)" v-html="item.actionType"></span>
              <v-icon class="copy" @click.prevent="copyData(item.actionType)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.parameters`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">
                <pre v-if="item.parameters.length">{{item.parameters}}</pre>
              </span>
              <v-icon v-if="item.parameters.length" class="copy" @click.prevent="copyData(item.parameters)">mdi-content-copy</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col cols="3" style="position: relative;" v-if="visibleEditor">
      <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
        <div style="position: fixed;">
          <v-btn
              class="btn-close"
              icon
              color="red"
              @click="closeEditor"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
          <p v-if="newData.id" class="mr-6"> Id:
            <b style="color:#9c9c9c;">{{ newData.id }}</b>
            <v-icon class="copy_visible" @click.prevent="copyData(newData.id)">mdi-content-copy</v-icon>
          </p>
          <v-text-field
              :label="$t('name')"
              hide-details="auto"
              outlined
              v-model="newData.actionName"
              require
          ></v-text-field>
          <v-text-field
              v-if="newData.id"
              :label="$t('actionCRUD.actionAlias')"
              hide-details="auto"
              outlined
              v-model="newData.actionAlias"
              readonly
              disabled
          ></v-text-field>
          <v-autocomplete
              :items="actionsTypes"
              v-model="newData.actionType"
              item-value="id"
              item-text="name"
              outlined
              :placeholder="$t('actionCRUD.actionType')"
              hide-details
              class="mt-1"
          />
          <div>
            <div class="json-editor">
              <span>{{ $t('actionCRUD.parameters') }}</span>
              <JsonEditorVue
                  v-model="newData.parameters"
                  v-bind="JsonEditorSetting"
                  class="mb-3"
                  style="position: relative;"
                  ref="jsonEditor-parameters"
              />
              <v-icon class="copy_json" @click.prevent="copyData(newData.parameters)">mdi-content-copy</v-icon>
            </div>
          </div>
          <div class="editor-col__btns">
            <template v-if="newData.id">
              <v-btn :disabled="requireFields" @click="updateMethod">{{ $t('update') }}</v-btn>
              <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
              <v-btn @click="deleteMethod(newData.id)">{{ $t('delete') }}</v-btn>
            </template>
            <template v-else>
              <v-btn :disabled="requireFields" @click="createMethod">{{ $t('create') }}</v-btn>
            </template>
          </div>
        </div>
        <SpinnerLoader
            v-else
            color="blue"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";
export default {
  name: "ActionsPage",
  data(){
    return {
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('actionCRUD.actionName'), value: 'actionName'},
        {text: this.$t('actionCRUD.actionAlias'), value: 'actionAlias'},
        {text: this.$t('actionCRUD.actionType'), value: 'actionType'},
        {text: this.$t('actionCRUD.parameters'), value: 'parameters'},
      ],
      templateData: {
        parameters: []
      },
      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
      },
    }
  },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('actionsCRUD/getDataById', {id, show: true})

      data?.parameters
          ? this.parseProperties(data, 'parameters')
          : data.parameters = JSON.parse(JSON.stringify(this.templateData.parameters))

      this.newData = data
    },

    duplicate(id){
      let actions = this.actions.find(field => field.id === id)

      const action = JSON.parse(JSON.stringify(actions))
      delete action.id

      this.visibleCopy = true
      this.newData = action
    },

    deleteMethod(id){
      this.$store.dispatch('actionsCRUD/deleteAction', {id, searchData: this.search})

      this.closeEditor()
    },

    async createMethod(){
      const newId = await this.$store.dispatch('actionsCRUD/createAction',
          {newData: this.updateDataForSave(), searchData: this.search}
      )
      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    updateMethod(){
      this.$store.dispatch('actionsCRUD/updateAction',
          {data: this.updateDataForSave(), searchData: this.search}
      )
    },

    updateDataForSave(){
      let data = JSON.parse(JSON.stringify(this.newData))

      if(data?.actionAlias) delete data.actionAlias
      if(!data.parameters.length) delete data.parameters
      else this.parseProperties(data, 'parameters')

      return data
    },

    loadData(){
      this.$store.dispatch('actionsCRUD/getData')
      this.$store.dispatch('actionsCRUD/getActionsTypes')
    },
  },
  computed: {
    filteredData(){
      return this.$store.getters['actionsCRUD/searchedActionsData']
    },
    actions(){
      const sources = this.$store.getters['actionsCRUD/actionsData']

      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? sources
          : this.filteredData
    },
    loading(){
      return this.actions && this.actions.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['actionsCRUD/loadedAllData']
    },
    actionsTypes(){
      return this.$store.getters['actionsCRUD/actionsTypes']
    },
    requireFields(){
      return (!this.newData.actionName)
    },
  },

  created(){
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/CRUD.scss";
</style>