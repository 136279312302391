import { defaultApiInstance } from "@/api";

export const endpointsService = {
  getEndpoints,
  getEndpointById,
  updateEndpoint,
  createEndpoint,
  deleteEndpoint,
}

async function createEndpoint(data) {
    return defaultApiInstance.post('/endpoints', data);
}

async function getEndpoints({offset, search}) {
    let query = `&offset=${offset}`
    if(search) query = search + query
    return defaultApiInstance.get(`/endpoints?q=${query}`);
}

async function getEndpointById(id){
  return defaultApiInstance.get(`/endpoints/${id}`);
}

async function updateEndpoint(data) {
    return defaultApiInstance.patch(`/endpoints/${data.id}`, data);
}

async function deleteEndpoint(id) {
  return defaultApiInstance.delete(`/endpoints/${id}`);
}

export const unHighlightEndpointsService = [{
  method: 'post',
  url: '/endpoints'
},{
  method: 'patch',
  url: '/endpoints'
},{
  method: 'delete',
  url: '/endpoints'
},{
  method: 'get',
  url: '/endpoints'
}]