<template>
  <div class="edit-name">
    <div class="edit-name__back">
    </div>
    <PersonalSettings />
    <div class="edit-name__popup popup">
      <div class="popup__header">
        <p>Edit nickname</p>
        <img src="@/assets/icons/profile/Close-icon.svg" width="32" height="32" alt="close">
      </div>
      <bpmInput class="mt-5"/>
      <bpmButton class="mt-5" style="width: 100%">
        <template v-slot:content><span style="color: white">Apply</span></template>
      </bpmButton>
      <p class="profile__subtitle profile__subtitle_tin text-center mt-5">Сancel</p>
    </div>
  </div>
</template>

<script>
import PersonalSettings from "@/components/nodes/creditLine/profile/PersonalSettings.vue";

export default {
  name: "PersonalSettingEditName",
  components: {PersonalSettings},
}
</script>

<style lang="scss" scoped>
.edit-name {
  position: relative;
  &__back {
    width: 100%;
    height: 812px;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.5;
    background: #000;
    z-index: 2;
    border-radius: 50px;
  }
}
.popup {
  p {
    margin-bottom: 0;
  }
  background: #fff;
  position: absolute;
  padding: 20px;
  left: 18px;
  bottom: 74px;
  z-index: 2;
  width: 340px;
  height: 289px;
  box-shadow: 0px -2px 24px rgb(0 0 0 / 8%), 0px -8px 24px rgb(0 0 0 / 10%);
  border-radius: 20px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>