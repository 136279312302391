<template>
    <div>
      <NodeOperations
          :data="mainData"
          @deletePreset="popupDelTrans(mainData), setLocationPopup($event)"
          @popup="showBgPopup()"
          @addPresets="addPresets(connections.connection), setLocationPopup($event)"
      />
      <OperationSettings 
          @popup="showBgPopup()" 
          :data="mainData"
          @updateRules="setSettings" 
      />
  
      <portal-target name="dialog_option" class="node-edit__portal-target" v-if="bgToOptions">
          <v-card
              v-if="visibleAddOption"
              class="rounded-lg"
              :style="`top:${positionPopup}px; position: absolute;`"
          >
              <v-card-title>{{ $t('node.intoPreset.addPreset') }}</v-card-title>
              <v-card-text class="text-body-1 pb-0">
                  <v-btn x-small absolute top right color="#f5f5f5" elevation="0" @click="showAddPresets">
                  <v-icon x-small class="black-icon">mdi-close</v-icon>
                  </v-btn>
                  <v-select
                      :label="$t('node.preset')"
                      :items="selectPreset"
                      item-text="preset_name"
                      item-value="id"
                      v-model="newPresetId"
                      color="deep-purple accent-2"
                      outlined
                      dense
                  />
              </v-card-text>
            </v-card>

            <v-card
                v-if="visibleDeleteOption"
                class="rounded-lg"
                :style="`top:${positionPopup}px; position: absolute;`"
            >
              <v-card-title>{{ $t('node.intoCondition.confDelete') }}</v-card-title>
              <v-card-text class="text-body-1">{{ $t('node.intoCondition.confDeleteTrans') }}</v-card-text>
              <v-card-actions class="px-4">
                  <v-spacer></v-spacer>
                  <v-btn
                      small
                      color="grey"
                      class="white--text mr-1"
                      @click="popupDelTrans()"
                      elevation="0"
                      >{{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      small
                      color="deep-purple accent-2"
                      class="white--text"
                      @click="deletePreset(), popupDelTrans()"
                      elevation="0"
                      >{{ $t('delete') }}
                  </v-btn>
              </v-card-actions>
          </v-card>
      </portal-target>
  
    </div>
  </template>
  
  <script>
  import NodeOperations from "@/components/nodes/NodeOperations";
  import OperationSettings from "@/components/nodes/operators/OperationSettings";
  import popupPosition from "@/mixins/popupPosition";
  export default {
      name: 'NodePresets',
      props: {
          connections: Object
      },
      data() {
          return {
              visibleDeleteOption: false,
              bgToOptions: false,
              delTrans: {},
              nameConnect: '',
              newNodeId: '',
              visibleAddOption: false,
              newPresetId: '',
          }
      },
      mixins: [popupPosition],
      components: {
        OperationSettings,
        NodeOperations
     },
     methods: {
        // open popup with delete transition
          popupDelTrans(delTrans) {
              this.visibleDeleteOption = !this.visibleDeleteOption
              if (delTrans) this.delTrans = {
                  ...delTrans,
                  parentNodeId: this.connections.parentNodeId
              }
              this.showBgPopup()
          },
          // open darked background on right side (column - conditional transitions)
          showBgPopup() {
              this.bgToOptions = !this.bgToOptions
              this.$forceUpdate();
          },
          setSettings(){
            this.$store.dispatch("node/setSettings", { id: this.connections.node.db_id, preset: this.connections.preset, type: this.connections.node.type, connections: this.connections.connections })
          },
          addPresets(connection){
              this.showAddPresets()
  
              this.nameConnect = connection.connectName
              this.newNodeId = connection.node.db_id
          },
          showAddPresets(){
              this.visibleAddOption = !this.visibleAddOption
              this.showBgPopup()
          },
          deletePreset() {
              this.$store.dispatch('processes/setConnectionsNodes', {
                  data: this.delTrans,
                  option: 'delete_presetId'
              })
              this.delTrans = ''
              this.$emit('updateConditions')
          },
          newTransition(presetId) {
              this.$store.dispatch('processes/setConnectionsNodes', {data: {...this.mainData, presetId}, option: 'presetId' })
              this.newNodeId = this.nameConnect = this.newPresetId = ''
              this.$emit('updateConditions')
          },
     },
     computed: {
          selectPreset(){
              if(this.newPresetId === '') return this.connections.unselectedPresets.filter(preset => preset.id_node === this.newNodeId)
              return [this.newPresetId]
          },
          mainData(){
              return {
                ...this.connections.connection, 
                nextNodeId: this.connections.connection.node.id, 
                presetId: this.connections.connection.connect?.presetId, 
                nameConnect: this.connections.connection?.connectName, 
                lastInGroup: this.connections?.lastInGroup, 
                groupInput: this.connections.connection?.groupInput, 
                firstInGroup: this.connections.connection?.firstInGroup, 
                fromGroupNextGroup: this.connections.fromGroupNextGroup, 
                groupId: this.connections.connection.groupId, 
                outputNames: this.connections?.outputNames, 
                parentNodeId: this.connections.parentNodeId
            }
          }
      },
      watch: {
          newPresetId(presetId){
              if(presetId){
                  this.bgToOptions = false
                  this.visibleAddOption = false
                  this.newTransition(presetId)
              }
          }
      },
  }
  </script>
  
  <style scoped lang="scss">
  .node-edit {
      &__card {
        background-color: #f8f9fa !important;
      }
  
      &__nav {
        position: relative;
        background-color: #ffffff;
        box-shadow: 0 0 32px 0 rgb(136 152 170 / 15%);
      }
  
      &__views {
        row-gap: 0px;
        column-gap: 60px;
        .phone-screen__wrapper{
          margin-bottom: -5em;
        }
      }
  
      &__slider {
        position: relative;
        width: 1px;
        border: none;
        cursor: col-resize;
        user-select: none;
        text-align: center;
  
        &__line {
          width: 1px;
        }
      }
  
      &__expand-arrow {
        transition: .5s all ease;
        cursor: pointer;
        position: absolute;
        z-index: 10;
        top: 50%;
  
        &_right {
          right: -30px;
        }
  
        &_left {
          left: -30px;
        }
      }
  
      &__portal-target {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(#212121, 0.4);
        top: 0;
        left: 0;
        z-index: 8;
        display: flex;
        justify-content: center;
        align-items: start;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      // ?
      &__screens {
  
        &-move,
        &-enter-active,
        &-leave-active {
          transition: all 0.5s ease;
        }
  
        &-enter-from,
        &-leave-to {
          opacity: 0;
          transform: translateX(30px);
        }
  
        &-leave-active {
          position: absolute;
        }
      }
    }
  .separator-line{
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(140,161,203,1) 50%, rgba(255,255,255,1) 100%);
    width: 100%;
    height: 1px;
    &__hide{
      display: none;
    }
  }
  </style>