<template>
  <div class="operation-settings" v-if="(!data.connect?.conditions)">
    <div class="d-flex">
        <v-row>
          <v-col col="1"></v-col>
          <v-col cols="11" class="d-flex  align-end">
            <v-autocomplete
                class="select_conditions"
                :items="savedConditionsCopy"
                @input="onConditionChange"
                item-value="id"
                item-text="ruleName"
                color="deep-purple accent-2"
                outlined
                :label="$t('node.intoCondition.selectCondition')"
                return-object
                hide-details
            >
              <!--    NEED TO DELETE IF PAGINATION WILL BE REMOVED      -->
              <!--          <template v-slot:append-item>-->
              <!--            <div v-intersect="onIntersect" class="pa-4 teal&#45;&#45;text">-->
              <!--              Loading more items ...-->
              <!--            </div>-->
              <!--          </template>-->
            </v-autocomplete>
            </v-col>
        </v-row>
    </div>
  </div>

  <div v-else class="operation-settings">
    <div>
      <v-row v-for="condition,i in data.connect.conditions" :key="condition.id">
        <OperatorButton :operator="operator(i)" @changeOperator="changeOperator(i)" />
        <ConditionsSettings 
          :data="condition"
          @saveCondition="saveCondition($event, i)"
          @changeCondition="showPopup('visibleDelOption', true), setDeleteIndex(exportNum(i), $event)"
          @changePosition="setLocationPopup($event)"
          v-model="newConditionId"
          :selectedRules="selectedRules"
        />
      </v-row>
    </div>

    <portal to="dialog_option">
      <v-card v-if="visibleDelOption" class="rounded-lg" :style="`top:${positionPopup}px; position: absolute;`">
        <v-card-title>{{$t('node.intoCondition.confDelete')}}</v-card-title>
        <v-card-text class="text-body-1 pb-0">{{$t('node.intoCondition.deleteRule')}}</v-card-text>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="grey"
            class="white--text mr-1"
            @click="showPopup('visibleDelOption')"
            elevation="0"
          >{{ $t('delete') }}</v-btn>
          <v-btn
            small
            color="deep-purple accent-2"
            class="white--text"
            @click="changeCondition(), showPopup('visibleDelOption')"
            elevation="0"
          >{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="visibleSelectCondition" class="rounded-lg">
        <v-card-title>{{ $t('node.intoCondition.selectCondition') }}</v-card-title>
        <v-card-text class="text-body-1 pb-0">
          <v-select
            :label="$t('node.intoCondition.condition')"
            :items="savedConditions"
            item-text="name"
            item-value="id"
            v-model="newConditionId"
            color="deep-purple accent-2"
            outlined
            dense
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.name }}
            </template>
            <template slot="item" slot-scope="data">
              {{ data.item.name }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <button
                    id="btn__delete-saved-conditions"
                    v-if="data.item.canDelete"
                    @click="showDeleteSavedConditions(data.item.id)"
                    v-bind="attrs"
                    v-on="on"

                  >
                    <v-icon small>mdi-close</v-icon>
                  </button>
                </template>
                <span>{{ $t('node.intoCondition.deleteCondition') }}</span>
              </v-tooltip>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="grey"
            class="white--text mr-1"
            @click="showPopup('visibleSelectCondition')"
            elevation="0"
          >{{ $t('cancel') }}</v-btn>
          <v-btn
            small
            color="deep-purple accent-2"
            class="white--text"
            @click="saveCondition(savedConditions.find(item => item.id === newConditionId)), showPopup('visibleSelectCondition')"
            elevation="0"
          >{{ $t('save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </portal>

    <portal to="dialog_option">
      <v-card v-if="visibleDelCondition" class="rounded-lg">
        <v-card-title>{{ $t('node.intoCondition.confDelete') }}</v-card-title>
        <v-card-text class="text-body-1 pb-0">{{ $t('node.intoCondition.confDeleteRule') }}</v-card-text>
        <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="grey"
            class="white--text mr-1"
            @click="showPopup('visibleDelCondition', false)"
            elevation="0"
          >{{ $t('cancel') }}</v-btn>
          <v-btn
            small
            color="deep-purple accent-2"
            class="white--text"
            @click="deleteSavedConditions(), showPopup('visibleDelCondition', false)"
            elevation="0"
          >{{ $t('delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </portal>

    <div class="d-flex justify-center mt-2" v-if="data.connect.conditions">
      <v-btn color="grey" class="mr-2" :disabled="addRule" outlined small fab @click="addCondition('or')">OR</v-btn>
      <v-btn color="grey" :disabled="addRule" outlined small fab @click="addCondition('and')">AND</v-btn>
    </div>
  </div>
</template>

<script>
import ServiceOperator from "@/components/nodes/operators/ServiceOperator";
import ConditionsSettings from "@/components/nodes/operators/ConditionsSettings";
import OperatorButton from "@/components/nodes/operators/OperatorButton";
import popupPosition from "@/mixins/popupPosition";
import searchConditions from "@/mixins/searchConditions";
import { EventBus } from "@/utils/event-bus";
/* eslint-disable */
const operatorComponents = {
  outerService: ServiceOperator,
}

export default {
  name: "OperationSettings",
  components: {
    ConditionsSettings,
    OperatorButton
  },
  props: {
    data: Object
  },
  mixins: [popupPosition, searchConditions],
  data() {
    return {
      visibleDelOption: false,
      deleteIndex: '',
      visible: false,
      visibleSelectCondition: false,
      newConditionId: '',
      visibleDelCondition: false,
      delConditionId: '',
      option: '',
      locationPopup: 0,
    }
  },
  computed: {
    outerConnections() {
      return this.$store.getters["node/outerConnections"];
    },
    nodesInfo(){
      return this.$store.getters["node/nodesInfo"];
    },
    savedConditions(){
      return this.$store.getters["node/conditions"]
    },
    selectedRules: {
      cache: false,
      get(){
        return Object.values(this.data.connect.conditions).map(item => item.id)
      }
    },
    mainData(){
      return {parentNodeId: this.data.parentNodeId, nextNodeId: this.data.node.id, presetId: this.data.connect?.presetId, nameConnect: this.data.connectName, groupInput: this.data.groupInput, endNodeId: this.data.endNodeId, firstInGroup: this.data.firstInGroup, lastInGroup: this.data.lastInGroup, groupId: this.data.groupId, fromGroupNextGroup: this.data.fromGroupNextGroup }
    },
    addRule:{
      cache:false,
      get(){
        return Object.values(this.data.connect.conditions).at(-1).id == undefined
      }
    },
  },
  methods: {
    // add new condition
    addCondition(operator) {
      this.$store.dispatch('processes/setConnectionsNodes', {data: {...this.mainData, operator}, option: 'condition' })
      this.$forceUpdate();
    },
    async saveCondition(condition, index = null) {
      this.$store.dispatch('processes/setConnectionsNodes', {data: {...this.mainData, condition, index}, option: 'condition' })
      this.$forceUpdate();
    },
    changeCondition() {
      this.$store.dispatch('processes/setConnectionsNodes', {data: {...this.mainData, conditionId: this.deleteIndex}, option:  this.option })
      EventBus.$emit('deletePreset')
      this.$forceUpdate();
    },
    cancelCondition(){
      this.$store.dispatch('processes/setConnectionsNodes', {data: this.mainData, option: 'cancel' })
      this.$forceUpdate();
    },
    changeOperator(data){
      let index = +this.exportNum(data) - 1
      let newOperator = this.operator(data) === 'or'
        ? 'and'
        : 'or'

      this.$store.dispatch('processes/setConnectionsNodes', {data: {...this.mainData, index, newOperator}, option: 'change_operator' })
      this.$forceUpdate();
    },
    // change visible incoming varieble and call popup
    showPopup(name, popup = true) {
      this[name] = !this[name]
      if (popup) this.$emit("popup");
      this.$forceUpdate();
    },
    // set conditions id for delete
    showDeleteSavedConditions(id) {
      this.delConditionId = id
      this.showPopup('visibleDelCondition', false)
    },
    // delete saved condition
    deleteSavedConditions() {
      this.$store.dispatch('node/deleteSavedConditions', this.delConditionId)
      this.delConditionId = ''
    },
    // set delete condition index
    setDeleteIndex(id, option) {
      this.newConditionId = ''
      this.deleteIndex = id
      this.option = option
    },
    operator(name){
      return this.data.connect.conditionExpression.find(item => item.p1 === name).operation
    },
    exportNum(str){
      return str.replace(/[a-z/-]/g, '')
    },
  },
  watch:{
    newConditionId(val){ 
      if(val === '') return
      

      this.saveCondition({
        "canDelete": true,
        "name": '',
        "condition": '',
        "service": '',
        "method": '',
        "answerPrefix": '=',
        "answer_id": '',
        "result_api_field": ''
      })

      this.saveCondition(
        this.savedConditions.find(item => item.id === val)
      )

      if(this.data.connect?.conditions == undefined) this.$emit('updateRules')
      this.newConditionId = ''
    }
  },
  created(){
    EventBus.$on('deletePresets', () => { 
      this.newConditionId = ''
    })
  },
  beforeDestroy() {
    EventBus.$off('deletePresets')
  }
}
</script>