<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <SearchComponent
          :searchData="['id', 'endpointUrl']"
          prefix="endpoints"
          @searchInput="searchInput"
          ref="searchComponent"
      />

      <v-card class="mt-5 pb-6 rounded-xl pa-6">
        <v-btn
            fab
            dark
            small
            color="primary"
            @click="showCreate"
            class="btn-add"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-data-table
            :headers="tableHeaders"
            :items="endpoints"
            :loading="!loading"
            :loading-text="$t('multilang.load')"
            item-key="id"
            density="compact"
            :items-per-page="tableItemsPerPage"
            :footer-props="tableFooterProps"
            class="mt-4"
            :sort-by="sortTableBy"
            :sort-desc="sortTableDesc"
            :custom-sort="customSort"
        >
          <template v-slot:[`item.id`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.id }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.instanceId`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.instanceId }}: {{instances.find(instance => instance.id === item.instanceId ).name}}</span>
              <v-icon class="copy" @click.prevent="copyData(item.instanceId)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.endpointUrl`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.endpointUrl }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.endpointUrl)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.function`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item.function }}</span>
              <v-icon class="copy" @click.prevent="copyData(item.function)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.logId`]="{ item }">
            <div class="table-item">
              <span @click="enterSelect(item.id)">{{ item?.logId ? item.logId + ':' : '' }} {{ references.find(reference => reference.id === item.logId)?.name }}</span>
              <v-icon v-if="item?.logId" class="copy" @click.prevent="copyData(item.logId)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.apiMethodId`]="{ item }">
            <div class="table-item" >
              <span @click="enterSelect(item.id)"><pre>{{apiMethod(item)}}</pre></span>
              <v-icon class="copy" @click.prevent="copyData(item.apiMethodId)">mdi-content-copy</v-icon>
            </div>
          </template>
          <template v-slot:[`item.nodeFuncId`]="{ item }">
            <div class="table-item" >
              <span @click="enterSelect(item.id)">{{item.nodeFuncId}}</span>
              <v-icon class="copy" @click.prevent="copyData(item.nodeFuncId)">mdi-content-copy</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col cols="3" style="position: relative;" v-if="visibleEditor">
      <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
        <div style="position: fixed;">
          <v-btn
              class="btn-close"
              icon
              color="red"
              @click="closeEditor"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
          <p v-if="newData.id" class="mr-6"> Id:
            <b style="color:#9c9c9c;">{{ newData.id }}</b>
            <v-icon class="copy_visible" @click.prevent="copyData(newData.id)">mdi-content-copy</v-icon>
          </p>
          <v-autocomplete
              :items="instances"
              v-model="newData.instanceId"
              item-value="id"
              :item-text="item => `${item.id}: ${item.name}`"
              outlined
              :placeholder="$t('endpoint.instance')"
              hide-details
              class="mt-1"
          />
          <v-text-field
              :label="$t('endpoint.endpointUrl')"
              hide-details="auto"
              outlined
              v-model="newData.endpointUrl"
              require
          ></v-text-field>
          <v-text-field
              :label="$t('endpoint.function')"
              hide-details="auto"
              outlined
              v-model="newData.function"
          ></v-text-field>
          <v-autocomplete
              :items="references"
              v-model="newData.logId"
              item-value="id"
              :item-text="item => `${item.id}: ${item.name}`"
              outlined
              :placeholder="$t('endpoint.logId')"
              hide-details
              class="mt-1"
          />
          <Switcher
              :params="switcherParams"
              :selectedParam="typeOfRule"
              @switchParam="switchParam"
              :disabled="!!newData?.id"
          />
<!--          <div v-if="typeOfRule === $t('rules.method')" class="d-flex align-center" style="gap:5px; position: relative;">-->
          <v-autocomplete
              v-if="typeOfRule === $t('endpoint.method')"
              :items="extMethods"
              v-model="newData.apiMethodId"
              item-value="id"
              :item-text="item => `${item.id}: ${item.methodName}`"
              outlined
              :placeholder="$t('endpoint.apiMethodId')"
              hide-details
              class="mt-1"
          />
          <v-text-field
              v-if="typeOfRule === $t('endpoint.node')"
              :label="$t('endpoint.nodeFuncId')"
              hide-details="auto"
              outlined
              v-model="newData.nodeFuncId"
          ></v-text-field>
          <div>
            <div class="json-editor">
              <span>{{ $t('endpoint.request') }}</span>
              <JsonEditorVue
                  v-model="newData.requestJson"
                  v-bind="JsonEditorSetting"
                  class="mb-3"
                  style="position: relative;"
                  ref="jsonEditor-request"
              />
              <v-icon class="copy_json" @click.prevent="copyData(newData.requestJson)">mdi-content-copy</v-icon>
            </div>
            <div class="json-editor">
              <span>{{ $t('endpoint.response') }}</span>
              <JsonEditorVue
                  v-model="newData.responseJson"
                  v-bind="JsonEditorSetting"
                  class="mb-3"
                  style="position: relative;"
                  ref="jsonEditor-response"
              />
              <v-icon class="copy_json" @click.prevent="copyData(newData.responseJson)">mdi-content-copy</v-icon>
            </div>
          </div>
          <div class="editor-col__btns">
            <template v-if="newData.id">
              <v-btn :disabled="requireFields" @click="updateMethod">{{ $t('update') }}</v-btn>
              <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
              <v-btn @click="deleteMethod(newData.id)">{{ $t('delete') }}</v-btn>
            </template>
            <template v-else>
              <v-btn :disabled="requireFields" @click="createMethod">{{ $t('create') }}</v-btn>
            </template>
          </div>
        </div>
        <SpinnerLoader
            v-else
            color="blue"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";
import Switcher from '@/components/UI/switcher.vue'

export default {
  name: "EndpointsPage",
  data(){
    return {
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('endpoint.instance'), value: 'instanceId'},
        {text: this.$t('endpoint.endpointUrl'), value: 'endpointUrl'},
        {text: this.$t('endpoint.function'), value: 'function'},
        {text: this.$t('endpoint.logId'), value: 'logId'},
        {text: this.$t('endpoint.apiMethodId'), value: 'apiMethodId'},
        {text: this.$t('endpoint.nodeFuncId'), value: 'nodeFuncId'}
      ],
      templateData: {
        endpointUrl: "",
        requestJson: null,
        responseJson: null,
      },

      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
      },
      typeOfRule: this.$t('endpoint.method'),
      switcherParams: [this.$t('endpoint.method'), this.$t('endpoint.node')]
    }
  },
  components: { Switcher },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('endpoints/getDataById', {id, show: true})

      this.parseProperties(data, ['requestJson', 'responseJson'])

      if(data?.requestJson === null){
        data.requestJson = JSON.parse(JSON.stringify(this.templateData.requestJson))
      }
      if(data?.responseJson === null){
        data.responseJson = JSON.parse(JSON.stringify(this.templateData.responseJson))
      }

      this.newData = data
    },

    duplicate(id){
      let endpoints = this.endpoints.find(field => field.id === id)
      this.parseProperties(endpoints, ['requestJson', 'responseJson'])

      const endpoint = JSON.parse(JSON.stringify(endpoints))
      delete endpoint.id

      this.visibleCopy = true
      this.newData = endpoint
    },

    deleteMethod(id){
      this.$store.dispatch('endpoints/deleteEndpoint', {id, searchData: this.search})

      this.closeEditor()
    },

    async createMethod(){
      this.parseProperties(this.newData, ['requestJson', 'responseJson'])

      const newId = await this.$store.dispatch('endpoints/createEndpoint', {newData: this.newData, searchData: this.search})
      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    updateMethod(){
      this.parseProperties(this.newData, ['requestJson', 'responseJson'])

      this.$store.dispatch('endpoints/updateEndpoint', {data: JSON.parse(JSON.stringify(this.newData)), searchData: this.search})
    },

    loadData(){
      this.$store.dispatch('endpoints/getEndpoints')
      this.$store.dispatch('externalSources/getMethods')
      this.$store.dispatch('projectsList/getInstance')
      this.$store.dispatch('references/getReferences')
    },

    switchParam(param){
      if(this.typeOfRule === param) return
      this.typeOfRule = param

      if(this.switcherParams.indexOf(param) === 0) delete this.newData.nodeFuncId
      else delete this.newData.apiMethodId
    },

    apiMethod(item){
      const data = this.extMethods.find(method => method.id === item.apiMethodId)
      return (data?.id && data?.methodName)
          ? {id: data?.id, value: data?.methodName}
          : ''
    }
  },
  computed: {
    filteredData(){
      return this.$store.getters['endpoints/searchedEndpoints']
    },
    endpoints(){
      const sources = this.$store.getters['endpoints/endpoints']

      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? sources
          : this.filteredData
    },
    loading(){
      return this.endpoints && this.endpoints.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['endpoints/loadedAllData']
    },
    types(){
      return this.$store.getters['endpoints/types']
    },
    apis(){
      return this.$store.getters['endpoints/apis']
    },
    requireFields(){
      return (!this.newData.instanceId || !this.newData.endpointUrl)
    },
    instances(){
      return this.$store.getters['projectsList/instances']
    },
    references(){
      return this.$store.getters['references/references']
    },
    extMethods(){
      return this.$store.getters['externalSources/externalSources']
    }
  },

  created(){
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/CRUD.scss";
</style>