import { defaultApiInstance } from "@/api";

export const multilangService =  {
  getTexts,
  getEmptyInfo,
  downloadExcel,
  checkDownloadStatus,
  uploadExcel,
  checkUploadStatus,
  editText
}

const refactorParams = (filters = null, asc = null, desc = null) => {
  let filter = '';
  // add filters to query params
  if (filters) {
    filters.forEach((f) => {
      if (filter === '') {
        filter = `${f}`
      } else {
        filter +=`;${f}`
      }
    });
  }

  // add sort to query params
  let sort = asc || desc ? asc ? `asc=${asc}` : `desc=${desc}` : '';

  return {sort, filter};
}

async function getTexts({limit, offset, searchText, filters, asc, desc}) {
  const refactoredParams = refactorParams(filters, asc, desc);

  return await defaultApiInstance.get(searchText === ''
      ? `texts/all?limit=${limit}&offset=${offset}&${refactoredParams.sort}`
      : `texts/all?limit=${limit}&offset=${offset}&q=${searchText}&filter=${refactoredParams.filter}&${refactoredParams.sort}`
  );
}

async function getEmptyInfo() {
  return await defaultApiInstance.get('texts/emptyinfo');
}

async function downloadExcel({searchText, filters, asc, desc}) {
  const refactoredParams = refactorParams(filters, asc, desc);

  return await defaultApiInstance.get(searchText === ''
    ? `texts/excel/download?${refactoredParams.sort}`
    : `texts/excel/download?q=${searchText}&filter=${refactoredParams.filter}&${refactoredParams.sort}`
  );
}

async function uploadExcel(files) {
  return await defaultApiInstance.post(`texts/excel/upload`, files)
}

async function checkDownloadStatus() {
  return await defaultApiInstance.get('texts/excel/download/confirm');
}

async function checkUploadStatus() {
  return await defaultApiInstance.get('texts/excel/upload/status');
}

async function editText(translation) {
  const payload = {
    texts: [translation]
  }
  return await defaultApiInstance.patch('texts', payload);
}
