<template>
  <PhoneWrapper node-name="НОДА: ПРИВЯЗАННЫЕ СПОСОБЫ ОПЛАТЫ. ЭКРАН 2: НАСТРОЙКА АВТОПЛАТЕЖЕЙ.">
    <h1 class="phone-screen__title">Set up regular autopayments</h1>
    <div class="block">
      <div class="card">
        <p class="gray mb-1">Payment amount</p>
        <p>According to your payment schedule</p>
      </div>
      <div class="card">
        <p class="gray mb-1">Frequency</p>
        <p>Monthly</p>
      </div>
      <div class="card">
        <template><BpmInput label="Date of the first payment" type="date" placeholder="mm/yy" /></template>
        <p class="under_input">The next payment will be on October 21</p>
      </div>
      <nextButton btn_name="Save" />
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import nextButton from "@/components/nodes/creditLine/widgets/payment/nextButton.vue";
export default {
  name: "vueRegularAutopayments",
  components: {
    PhoneWrapper,
    nextButton,
  }
}
</script>

<style scoped>

.gray{
  color: #888888;
}
.under_input{
  font-size: 12px;
  padding-left: 20px;
}

</style>