<template>
  <v-card class="pb-6 rounded-xl">
    <div class="d-flex pa-6" style="gap:50px">
      <div class="pt-2 search">

        <h2>{{ $t('search') }}</h2>
        <div class="d-flex" style="gap:20px">
          <v-text-field
              v-for="(searchItem,i) in searchData"
              :key="i"
              :label="$t(searchItem)"
              hide-details="auto"
              outlined
              v-model="search[searchItem]"
              @keyup.enter="searchMethod"
              :type="searchItem === 'id' ? 'number' : ''"
              hide-spin-buttons
          ></v-text-field>
          <div class="d-flex search-actions">
            <v-icon class="search-actions__action" :disable="searchWarning" @click="searchMethod">
              mdi-magnify
            </v-icon>
            <v-icon class="search-actions__action" @click="cleanSearchField">
              mdi-close
            </v-icon>
          </div>
        </div>
        <span v-if="searchWarning" style="color:#b53838;">{{ $t('variables.errorSearchSeveralParameters') }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "SearchComponent",
  props: {
    searchData: {
      type: Array,
      require: true,
      default: () => ['id']
    },
    prefix: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      search: {},
      searchWarning: false,
      newSearch: false
    }
  },
  methods: {
    searchMethod(){
      if(Object.keys(this.search).every(item => this.search[item].length)) return
      if(Object.keys(this.search).every(item => !this.search[item].length)) return this.cleanSearchField()

      this.$store.dispatch(`${this.prefix}/loadData`)
      this.$store.dispatch(`${this.prefix}/cleanSearchedData`)

      this.newSearch = true
      this.search.id
        ? this.$store.dispatch(`${this.prefix}/getDataById`, {id: this.search.id})
        : this.$store.dispatch(`${this.prefix}/getDataFiltered`, this.search)

      // need to do not clear search
      setTimeout(() => this.newSearch = false, 500)
    },
    cleanSearchField(){
      this.setEmptySearchData()
      this.$store.dispatch(`${this.prefix}/cleanSearchedData`)
    },
    setEmptySearchData(){
      if(this.newSearch) return

      let searchLabels = {}
      this.searchData.forEach(item => {
        searchLabels[item] = ''
      })
      this.search = searchLabels
    }
  },
  watch: {
    search: {
      handler: function (value) {
        this.searchWarning = Object.keys(this.search)
            .filter(item => value[item].length)
            .length > 1
        this.$emit('searchInput', this.search)
      },
      deep: true
    }
  },
  created(){
    this.setEmptySearchData()
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/CRUD.scss";
</style>