<template>
    <div class="node-edit__views">
        <div class="phone-screen__wrapper ">
            <div class="phone-screen phone-screen-custom">
                <div class="phone-screen__inner">
                    <component v-for="widget in json" :key="widget.id" :is="widgets[widget.name]" :data="widget" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import json from './widgetsJson.json'

import topBar from './WidgetTopBar.vue'
import slider from './WidgetSlider.vue'
import modalWindow from './WidgetModalWindow.vue'
import calendar from './WidgetCalendar.vue'
import button from './WidgetButton.vue'

let widgets = {
    topBar,
    slider,
    modalWindow,
    calendar,
    button,
}

export default {
    name: "MainWidget",
    data(){
        return{
            json: json,
            widgets: widgets
        }
    },
    computed: {
        typeWidget(){
            return this.json.map(widget => widget.name)
        }
    }

}
</script>