<template>
  <bpm-button class="btn" :class="{'black': black, 'danger': danger}" variant="success"><template v-slot:content>{{btn_name}}</template></bpm-button>
</template>

<script>
export default {
  name: "nextButton",
  props: {
    btn_name: {
      default: '',
      type: String
    },
    color: {
      default: 'black',
      type: String
    }
  },
  computed: {
    black(){
      return this.color === 'black'
    },
    danger(){
      return this.color === 'danger'
    },
  }
}
</script>

<style lang="scss" scoped>
.btn{
  position: absolute;
  bottom: 25px;
  width: calc(100% - 68px);
  left: 34px;
}

.black {
  color: #fff;
  background: #000;
  box-shadow: 0px 4px 15px rgba(13, 23, 42, 0.25), 0px -10px 15px rgba(176, 176, 176, 0.25);

  &:hover,&:focus{
    color: #fff;
    background: #000;
  }
}

.danger{
  background: none;
  color: #FF0000;
  text-transform: uppercase;
  border: 2px solid #FF0000;

    &:hover,&:focus{
       border: 2px solid #FF0000;
       background: none;
       color: #FF0000;
     }

}
</style>