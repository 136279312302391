const staticUrl = process.env.VUE_APP_STATIC_URL;

export default {
  props: {
    src: {
      required: true,
    },
    maxWidth: {
      type: String,
    },
    maxHeight: {
      type: String,
    }
  },
  computed: {
    originalSrc() {
      return `${staticUrl}/${this.src}`;
    }
  }
}