<template>
  <div class="panel-component">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PanelComponent"
}
</script>

<style scoped lang="scss">
.panel-component {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.05);
}
</style>
