<template>
  <div class="popupBg"></div>
</template>

<script>
export default {

}
</script>

<style scoped>
.popupBg{
    width: 100%;
    height: calc(100% - 50px);
    background: #000;
    opacity: .6;
    position: absolute;
    border-radius: 30px;
    z-index: 10;
}
</style>