import { defaultApiInstance } from "@/api";

const path = 'properties'
export const propertiesService = {
    getData,
    getDataById,
    updateProperty,
    createProperty,
    deleteProperty,
    getPropertiesTypes,
    getMethodApis,
}

async function createProperty(data) {
    return defaultApiInstance.post(`/${path}`, data);
}

async function getData(searchData) {
    let query = `&offset=${searchData.offset}`
    const type = searchData.isType
        ? `&type=${searchData.type}`
        : ''
    if(searchData?.type) query = `${searchData.search}${type}` + query

    return defaultApiInstance.get(`/${path}?q=${query}`);
}

async function getDataById(id){
    return defaultApiInstance.get(`/${path}/${id}`);
}

async function getPropertiesTypes({offset}){
    let query = `&offset=${offset}`
    return defaultApiInstance.get(`/${path}/types?q=${query}`);
}

async function getMethodApis(){
    return defaultApiInstance.get(`/${path}`);
}

async function updateProperty(data) {
    return defaultApiInstance.patch(`/${path}/${data.id}`, data);
}

async function deleteProperty(id) {
    return defaultApiInstance.delete(`/${path}/${id}`);
}

export const unHighlightPropertiesService = [{
    method: 'post',
    url: `/${path}`
},{
    method: 'patch',
    url: `/${path}`
},{
    method: 'delete',
    url: `/${path}`
},{
    method: 'get',
    url: `/${path}`
}]