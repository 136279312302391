import {chatgptService} from "@/services/chatgpt.service";

export const chatgpt = {
    namespaced: true,
    state: {
        streamedString: [{content: '', finish: true}],
    },

    getters: {
        streamedString: state => state.streamedString,
    },

    mutations: {
        setStreamedText(state, value) {
            state.streamedString = value;
        }
    },

    actions: {
        setStreamedText({commit}, content) {
            commit("setStreamedText", content);
        },
        async showMethods() {
           return await chatgptService.showMethods();
        }
    }
}