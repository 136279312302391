<template>
  <PhoneWrapper :node-name="nodeName">
    <div class="phone-screen__context verify-email">
      <ProfileHeader :title="headerTitle"/>
      <p class="phone-screen__title mt-5">{{ screenTitle }}</p>
      <p class="phone-screen__text verify-email__text mt-3" v-if="screenSubtitle">{{ screenSubtitle }}</p>
      <bpmInput class="mt-2" :placeholder="placeholder"/>
      <bpmButton class="mt-auto mb-10" style="width: 100%">
        <template v-slot:content><span style="color: white">{{ buttonText }}</span></template>
      </bpmButton>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";

export default {
  name: "UniversalVerify",
  components: {ProfileHeader, PhoneWrapper},
  props: {
    nodeName: {
      type: String,
      required: true,
    },
    headerTitle: {
      type: String,
      required: true,
    },
    screenTitle: {
      type: String,
      required: true,
    },
    screenSubtitle: {
      type: String,
    },
    placeholder: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.verify-email {
  &__text {
    max-width: 235px;
  }
}
</style>