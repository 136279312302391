import { projectService } from "@/services/project.service";

const refTypes = {
  project_name: {
    type: 'text',
    name: "project_name",
    label: 'projectName',
    placeholder: 'plProject',
    category: 'main'
  },
  company: {
    type: 'text',
    name: "company",
    label: 'companyName',
    placeholder: 'plCompany',
    category: 'main'
  },
  font: {
    type: 'upload',
    name: "font",
    multiple: false,
    accept: '.ttf, .otf, .fon, .fnt',
    label: '',
    placeholder: "plSelectFile",
    category: 'main'
  },
  fontSize: {
    type: 'slider',
    name: "fontSize",
    label: '',
    min: 10,
    max: 50,
    category: 'main'
  },
  logo: {
    type: 'upload',
    name: "logo",
    multiple: false,
    accept: 'image/*',
    label: 'logo',
    placeholder: "plSelectFile",
    category: 'image'
  },
  icon: {
    type: 'upload',
    name: "favicon",
    multiple: false,
    accept: 'image/*',
    label: 'favicon',
    placeholder: "plSelectFile",
    category: 'image'
  },
  preloader: {
    type: 'color',
    name: "preloader",
    label: 'preloaderColor',
    category: 'color'
  },
  timeResendCode: {
    type: 'number',
    name: "timeResendCode",
    label: 'timeResendCode',
    placeholder: 'plTimeResendCode',
    category: 'main'
  },
  globalUrl: {
    type: 'text',
    name: "globalUrl",
    label: 'globalUrl',
    placeholder: 'plGlobalUrl',
    category: 'main'
  },
  imageUrl: {
    type: 'text',
    name: "imageUrl",
    label: 'imageUrl',
    placeholder: 'plImageUrl',
    category: 'image'
  },
  mainColor:{
    type: 'color',
    name: "color",
    label: 'mainColor',
    category: 'color'
  },
  activeColor:{
    type: 'color',
    name: "activeColor",
    label: 'activeColor',
    category: 'color'
  },
  backgroundСolor:{
    type: 'color',
    name: "background",
    label: 'bgColor',
    category: 'color'
  },
}

const labels = {
  fontMainText: {
    fontSize: 'labelFontSize',
    font: 'labelFontFamily',
    color: "labelColorText"
  },
  fontTitle: {
    font: "labelFontHeadline",
    fontSize: "labelSizeHeadline"
  }
}

const generateWorkFields = (obj, ref = null) => {
  let result = [];
  for (const field in obj) {
    if (Object.prototype.toString.call(obj[field]) === "[object Object]") {
      result = [...result, ...generateWorkFields(obj[field], field)];
    } else {
      result.push(
        {
          ...refTypes[field],
          value: obj[field],
          label: Object.prototype.hasOwnProperty.call(labels, ref) ? labels[ref][field] : refTypes[field]?.label,
          ref: ref === 'properties' ? null : ref
        }
      );
    }
  }
  return result.sort((a, b) => a.label > b.label ? 1 : -1);
}

export const project = {
  namespaced: true,
  state: {
    project: null,
    projectProperties: null,
  },

  getters: {
    project: state => state.project,
    projectLangs: state => state.project?.langs,
    projectProperties: state => state.projectProperties,
    projectId: state => state.project.id,
    workFields: state => state.project ? generateWorkFields(state.projectProperties) : null,
    projectLogo: state => state.projectProperties?.properties?.logo
  },

  mutations: {
    setProjects(state, projects) {
      console.log('projects - ', projects);
    },
    setProject(state, project) {
      state.project = project;
      if (project) {
        state.projectProperties = {
          project_name: project.name,
          properties: { ...project.properties}
        }
      }
    },
  },

  actions: {
    async getProjects({commit}) {
      const response = await projectService.getProjects();
      commit('setProjects', response)
    },
    async getProject({commit}, id) {
      await projectService.getProject(id)
        .then(project => {
          commit("setProject", project.response);
        })
        .catch(() => {
          commit("setProject", null);
        })
    },
    async saveProject({ getters, dispatch }, project) {
      if (project.length) {
        const result = {}
        const project__name = project.find(field => field.name === 'project_name')?.value
        if (project__name) {
          result.project_name = project__name;
          project = project.filter(field => field.name !== 'project_name');
        }
        Object.defineProperty(result, 'properties', {
          value: {},
          enumerable: true
        })
        project.forEach(field => {
          if (field.ref) {
            if (!result.properties?.[field.ref]) {
              Object.defineProperty(result.properties, field.ref, {
                value: {},
                enumerable: true
              })
            }
            result.properties[field.ref][field.name] = field.value
          } else {
            result.properties[field.name] = field.value
          }
        })
        await projectService.saveProject(result, getters.projectId)
          .then(() => {
            dispatch('getProject', getters.projectId);
          })
      }
    }
  }
}