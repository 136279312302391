<template>
  <PhoneWrapper node-name="ОФОРМЛЕНИЕ ПРОДУКТА">
    <h1 class="phone-screen__title mb-5">Transfer</h1>
    <p class="phone-screen__subtitle product-order__subtitle">Grace Period up to 20 Dec’ 22</p>
    <p class="phone-screen__text">Pay in full and cover processing fee in 20days after billing date, get zero interest and fees</p>

    <div class="mt-6">
      <bpmInput label="Choose the amount and the term" placeholder="₹40,000" />
      <bpmRange :rangeValues="[1000, 100000]"/>
    </div>

    <div class="d-flex justify-space-between mt-4" style="width: 100%">
      <bpmCheckbox label="SMS alert. 69 rupees per month"/>
      <v-img width="20" height="20" src="@/assets/icons/transh/hint.svg"/>
    </div>

    <div class="product-order__period period">
      <div class="period__item active">5 months</div>
      <div class="period__item">10 months</div>
      <div class="period__item">20 months</div>
    </div>

    <div class="mt-4">
      <p class="phone-screen__text mb-1 text-center">Processing fee: ₹1,000</p>
      <p class="phone-screen__text mb-1 text-center">Optional term fee: ₹0</p>
      <p class="phone-screen__text text-center">Interest: ₹1,509,75</p>
    </div>

    <div class="product-order__emi mt-4">
      <p class="phone-screen__subtitle text-center mb-2">EMI details</p>
      <p class="phone-screen__text text-center mb-2">First EMI: ₹10,523.22</p>
      <p class="phone-screen__text text-center">First EMI: ₹10,523.22</p>
    </div>

    <div class="product-order__redirects redirects mt-5">
      <div class="redirects__item">
        <p class="phone-screen__subtitle">Full EMI plan</p>
        <img width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
      </div>
      <div class="redirects__item">
        <p class="phone-screen__subtitle">Tariffs</p>
        <img width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
      </div>
    </div>

    <bpmButton class="mt-6" style="width: 100%">
      <template v-slot:content><span style="color: white">Continue</span></template>
    </bpmButton>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "ProductOrder",
  components: {
    PhoneWrapper,
  }
}
</script>

<style lang="scss" scoped>
.product-order {
  &__subtitle {
    align-self: flex-start;
  }
  &__period {
    margin-top: 24px;
  }
  &__emi {
    background: #FFDCFE;
    height: 104px;
    width: 110%;
    padding-top: 12px;
  }
}

.period {
  display: flex;
  border-radius: 16px;
  border: 1px solid black;
  &__item {
    padding: 8px 12px;
    color: #777;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    &.active {
      background: #777777;
      border-radius: 16px;
      color: #fff;
      border: 1px solid #777;
    }
  }
}

.redirects {
  width: 100%;
  //display: flex;
  //flex-direction: column;
  &__item {
    display: flex;
    justify-content: space-between;
  }
}
</style>