<template>
  <div v-if="properties" class="d-flex align-center" style="gap:5px">
    <img :src="properties.imageUrl" alt="arrow">
    <span v-html="properties.text"></span>
  </div>
</template>

<script>
export default {
    name: 'WidgetWebButtonArrow',
    props: {
        widgetData: {
            type: Object
        }
    },
    computed: {
      properties(){
        if(this.widgetData?.presets !== undefined) return this.widgetData.presets[0].nodeFields[0].properties
        return false
      }
    }
}
</script>

<style>

</style>