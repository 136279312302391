<template>
    <div class="phone-screen__wrapper ">
        <div class="phone-screen phone-screen-custom">
        <div class="phone-screen__inner">
            <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
            <div class="phone-screen__main">
            <h1 class="phone-screen__title">Amount of Payment</h1>
            <div class="block">
                <div class="card">
                    <div class="flex flex_between">
                        <p class="card__subtitle">Payment amount</p>
                        <p>₹12,166.26</p>
                    </div>
                    <p class="mb-0">If you payback till grace period end on 20-01, you'll have EMI due by 31 Jan’ 23 of <strong>₹9,846.26</strong></p>
                </div>
            </div>
            <payment_option />
                
            </div>
        </div>
        </div>
    </div>
    
</template>

<script>
import payment_option from '../widgets/homescreen/paymentOption.vue';
export default {
    components:{
        payment_option
    },
    computed: {
    },
    data(){
        return{}
    },
}
</script>
