<template>
  <div
      :class='["message"]'
      v-html="text"
      @click="handleClick"
  />
</template>

<script>
export default {
  name: "ChatMessage",
  props: [
    'text',
    'author',
  ],
  methods: {
    handleClick(e) {
      const CustomEvent = e.srcElement.attributes?.['data-event']?.value;
      const CustomAction = e.srcElement.attributes?.['data-action']?.value;
      const processId = e.srcElement.attributes?.['data-id']?.value;
      if (CustomEvent) {
        if (CustomEvent)
        this.$emit("handleMethod", {customEvent: CustomEvent, customAction: CustomAction, processId});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.message {
  background: #eee;
  color: #000;
  border-radius: 10px;
  padding: 1rem;
  width: fit-content;
  max-width: 300px;
}
</style>