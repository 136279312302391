<template>
  <div>
    <div class="modal-window">
      <img v-if="visibleImg" class="mb-2" src="@/assets/images/cat_sad.svg" alt="catImg">

      <template v-if="visibleGoBack">
        <p class="title">Leaving so soon?</p>
        <p class="subtitle subtitle__goback">If you cancel the application process, you'll have to enter and verify your details again next time</p>
      </template>

      <template v-if="visibleCancel">
        <p class="title">Are you sure you want to cancel the application process?</p>
      </template>

      <template v-if="visiblePhone">
        <p class="title title__left">Phone number and Aadhar number dont't match</p>
        <p class="subtitle subtitle__left">Start a chat our customer support executiev to reset password</p>
        <div class="buttons">
          <bpmButton class="button">
            <template v-slot:content><span style="color: #fff">Git it!</span></template>
          </bpmButton>
        </div>
      </template>

      <template v-if="visibleAcc">
        <p class="title title__left">Uh-oh! Account blocked for 00:59 minutes</p>
        <p class="subtitle subtitle__left">You entered an incorrect PIN 3 times. Try again after a while!</p>
        <div class="buttons">
          <bpmButton class="button">
            <template v-slot:content><span style="color: #fff">Back</span></template>
          </bpmButton>
          <bpmButton class="button">
            <template v-slot:content><span style="color: #fff">Support</span></template>
          </bpmButton>
        </div>
      </template>

      <div v-if="visibleCancel || visibleGoBack" class="buttons">
        <bpmButton class="button btn_white">
          <template v-if="!visibleImg" v-slot:content><span style="color: #B51ACE">Cancel</span></template>
          <template v-else v-slot:content><span style="color: #B51ACE">Yes</span></template>
        </bpmButton>
        <bpmButton class="button">
          <template v-if="!visibleImg" v-slot:content><span style="color: #fff">Continue</span></template>
          <template v-else v-slot:content><span style="color: #fff">No</span></template>
        </bpmButton>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import widgetOptions from "@/mixins/widgetOptions";
  export default {
      name: "WidgetModalWindow",
      mixins: [widgetOptions],
      computed: {
        visibleGoBack(){
          return this.fieldsType[0] === 'goBack'
        },
        visibleImg(){
          let image = this.fieldsType
          if(image.length === 1 && !this.visibleGoBack) return false
          return image.slice(-1)[0] === 'image' || this.visibleGoBack
        },
        visibleCancel(){
          return this.fieldsType[0] === 'cancel'
        },
        visiblePhone(){
          return this.fieldsType[0] === 'phone'
        },
        visibleAcc(){
          return this.fieldsType[0] === 'account'
        },
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .modal-window{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    padding: 32px 20px;
    flex-wrap: wrap;
    border-radius: 0 0 30px 30px;
  }
  .title{
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    word-break: break-word;
    text-align: center;
    &__left{
      text-align: left;
      width: 100%;
    }
  }
  .subtitle{
    font-weight: 400;
    font-size: 15px;
    padding: 0;
    word-break: break-word;
    text-align: center;
    &__goback{
      color: #707070;
    }
    &__left{
      text-align: left;
      width: 100%;
    }
  }
  .buttons{
    display: flex;
    gap:10px;
    width: 100%;
  }
  .button{
    width: 100%;
    background: #B51ACE;
    border: none;
    font-weight: 600;
    font-size: 17px;
    &:hover{
      background: #B51ACE;
      box-shadow: 0px 2px 10px rgba(78,106,88,0.35);
    }
    &:focus{
      background: #B51ACE;
    }
  }
  .btn_white{
    background: none;
    &:hover{
      background: none;
    }
    &:focus{
      background: none;
    }
  }
  
  </style>