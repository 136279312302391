<template>
  <div class="wrapper" :style="wrapperStyles">
    <p :style="styles" v-html="item.properties.text"></p>
  </div>
</template>

<script>
import fieldOptions from "@/mixins/fieldOptions";

export default {
  name: "PreviewTitle",
  mixins: [fieldOptions]
}
</script>

<style scoped>
p {
  margin-top: 5px;
  font-weight: 900;
  font-size: 10px;
  text-align: center !important;
}
</style>