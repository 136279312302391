import {defaultApiInstance, authApiInstance} from "@/api";

export const userServices = {
  login,
  logout,
  refresh,
};

const device_id = navigator.appVersion;

async function login(login, password) {
  return await authApiInstance.post('login', {login, password, device_id});
}

async function refresh(refreshToken) {
  return await authApiInstance.post('token/refresh', {refresh: refreshToken});
}

async function logout() {
  const token = localStorage.getItem('accessToken');
  return await defaultApiInstance.post(`logout?access_token=${token}`,{device_id});
}
