<template>
  <v-col class="mb-1 pa-0 input-field">
    <div :class="{'field-center': fieldRef.type === 'bpmTimer'}">
      <component
          :is="fieldRef.type || 'bpmInput'"
          :number="Number(item.properties.numberSymbols)"
          :mode="fieldRef?.mode ? fieldRef.mode : null"
          :label="item.properties.label || ' '"
          :placeholder="item.properties.placeholder || ''"
          :backgroundColor="item.properties.background"
          :value="item.properties?.text?.replace(/<\/?[^>]+>/gi, '') || ''"
          :options="dropDownList || ''"
          v-show="(!item.properties.hasOwnProperty('visibility') || item.properties.visibility)"
          />
    </div>
  </v-col>
</template>

<script>

export default {
  name: "FieldDisplay",
  props: {
    item: Object,
  },
  data () {
    return {
      fields: {
        input: {
          type: 'bpmInput',
        },
        'input.photo': {
          type: 'bpmFileInput',
          mode: 'photo',
        },
        'input.OTP': {
          type: 'bpmOtpInput'
        },
        'input.dropDownList': {
          type: 'bpmSelect',
        },
        'input.passcode': {
          type: 'bpmOtpInput',
        },
        'input.range': {
          type: 'BpmRange',
        },
        'input.search': {
          type: 'bpmInput',
        },
        progressBar: {
          type: 'BpmProgress',
        },
        radiobutton: {
          type: 'BpmRadio',
        },
        checkbox: {
          type: 'bpmCheckbox',
        },
        timer: {
          type: 'bpmTimer',
        }
      }
    }
  },
  computed: {
    fieldRef () {
      return this.fields?.[this.item.nodeType] ? this.fields[this.item.nodeType] : this.fields.input;
    },
    dropDownList(){
      if(this.item.nodeType === 'input.dropDownList') return JSON.parse(this.item.properties.data).map((item,i) => { return {value : item.replace(/<\/?[^>]+>/gi, ""), label: item.replace(/<\/?[^>]+>/gi, ""), id: i, 'disabled': false}})
      return ''
    }
  },
};
</script>

<style lang="scss">
.input-field {
  p {
    margin-bottom: 0 !important;
  }
}
.field-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
