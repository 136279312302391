<template>
  <div class="general-presets">
    <div
      v-for="(preset, index) in presets"
      :key="preset.id"
      @click="getNodePreset(preset.id)"
    >
      <div class="d-flex align-center my-3 justify-space-between" style="cursor: pointer">
        <div
          class="general-presets__preset"
          :class="{ [`general-presets__preset--active`]: preset.id === selectedPreset }"
        >{{ preset.preset_name }} <span class="text-caption"> | {{ preset.id }}</span></div>
        <div
          v-if="isSelectedPreset(preset.id, index)"
          class="general-presets__arrow"
        >
          <v-icon large> 
            mdi-menu-left
          </v-icon>
        </div>
      </div>
      <v-divider></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneralPresets',
  props: {
    presets: {
      type: Array,
      required: true,
    },
    selectedPreset: {
      type: Number,
      nullable: true,
    }
  },
  methods: {
    getNodePreset (preset) {
      this.$emit("getNodePreset", preset)
    },

    isSelectedPreset(presetId, index){
      if(!this.selectedPreset && index == 0) return true
      return presetId === this.selectedPreset
    }
  }
}
</script>

<style lang="scss">
.general-presets {
  &__preset {
    cursor: pointer;
    width: 100%;
  }
}
</style>
