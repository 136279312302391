<template>
    <div class="phone-screen__wrapper ">
        <div class="phone-screen phone-screen-custom">
        <div class="phone-screen__inner">
            <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
            <div class="phone-screen__main">
            <h1 class="phone-screen__title">Video KYC</h1>
                <videoVKYC />
            </div>
        </div>
        </div>
    </div>
    
</template>

<script>
import videoVKYC from '../widgets/homescreen/videoVKYC.vue';
export default {
    components:{
        videoVKYC
    },
    computed: {
    },
    data(){
        return{}
    },
}
</script>
