<template>
  <div class="phone-screen__wrapper">
    <div class="phone-screen phone-screen-custom">
      <div class="phone-screen__inner">
        <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
        <div class="phone-screen__main">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="phone-screen__name"> НОДА: {{ nodeName }}</div>
  </div>
</template>

<script>
export default {
  name: "PhoneWrapper",
  props: {
    nodeName: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
  .phone-screen__inner {
    background: #fff;
    border-radius: 35px;
  }
</style>