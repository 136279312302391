<template>
  <PhoneWrapper node-name="НОДА: ИСТОРИЯ.">
    <h1 class="phone-screen__title">Details</h1>
    <div class="block">
      <div class="card flex card__center flex__column">
        <img src="@/assets/images/gray_circle.png" alt="gray_circle">
        <p class="mb-0">Grace repayment</p>
        <p>+ ₹15,000</p>
        <p class="mb-0 gray_text">Payment completed</p>
        <p class="gray_text">10 Nov’ 22  10:30</p>
      </div>
      <div class="card flex card__center">
        <bpm-button
            class="btn"
            variant="success">
            <template v-slot:content> <img src="@/assets/images/download.png" alt="download"> Download receipt</template>
        </bpm-button>
        <bpm-button
            class="btn"
            variant="success">
            <template v-slot:content> <img src="@/assets/images/info.png" alt="info"> Dispute a transaction</template>
        </bpm-button>
      </div>
      <div class="card">
        <p class="card__title mb-5">Details</p>
        <div class="mb-2" :class="{'text_border': isDrop}" v-for="(item,i) in detailsData" :key="i">
         <div class="flex flex_between">
           <p class="mb-1" :class="{'gray_text': item.gray_text}">{{ item.text }}</p>
           <p v-if="item.sum" class="mb-0">{{ item.sum }}
             <span v-if="isDrop" @click="more($event)">&#8964;</span>
           </p>
         </div>
          <div v-if="isDrop">
            <div class="flex flex_between pr-3" v-for="(drop,i) in item.drop" :key="i">
              <p class="mb-1 gray_text">{{drop.data}}</p>
              <p class="mb-1">{{drop.sum}}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isDrop" class="card">
        <p class="card__title mb-5">Payment method</p>
        <p class="mb-0">Card cardname ·· 5442</p>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "vueHistoryGrace",
  components: {
    PhoneWrapper,
  },
  props: {
    node_type: {
      // default: 'Grace repayment',
      default: 'Early repayment',
      // default: 'EMI repayment',
      type: String
    }
  },
  methods: {
    more(event){
      console.log(event)
    }
  },
  data(){
    return {
      details: [
        {
          type: 'Grace repayment',
          data: [{text: 'October 2022 bill paid out'}]
        },
        {
          type: 'EMI repayment',
          data: [
              {
              text: '20.12.2022', gray_text: true, sum: '₹5,600',
              drop: [
                {data: 'Principal debt', sum: '₹4,000'},
                {data: 'Interest', sum: '₹600'},
                {data: 'Processing fee', sum: '₹1,000'},
              ]
            },
            {text: '20.12.2022', gray_text: true, sum: '₹4,600'},
            {text: '20.01.2023', gray_text: true, sum: '₹4,600'},
            {text: '20.02.2023', gray_text: true, sum: '₹4,600'},
          ]
        },
        {
          type: 'Early repayment',
          data: [
            {text: 'Early repayment fee 4%:',
          sum: '₹600.00'},
            {text: 'Total payable after payment:',
          sum: '₹54,356.12'}
          ]
        },

      ]
    }
  },
  computed: {
    detailsData(){
      return this.details.find(item => item.type === this.node_type).data
    },
    isDrop(){
      return this.detailsData.find(item => item.drop)?.drop !== undefined
    }
  }
}
</script>

<style lang="scss" scoped>

img{
  width: 48px;
  height: auto;
}
.btn{
  padding: 12px 8px;
  background: #DBDBDB;
  border-radius: 16px;
  border: none;
  max-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &:hover,&:focus{
    background-color: #DBDBDB;
  }
  img{
    width: 20px;
  }
}

.gray_text{
  color: #7F7F7F;
}
.text_border{
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(176, 176, 176, 0.25);
}


</style>