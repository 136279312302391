<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="close(false)"
  >
    <v-card class="rounded-lg">
      <v-card-title class="text-h5">{{ title }}</v-card-title>
      <v-card-text class="text-body-1">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click.native="close(false)"
        >{{ $t('modal.cancel') }}</v-btn>
        <v-btn
          color="deep-purple accent-2"
          text
          class="body-2 font-weight-bold"
          @click.native="close(true)"
        >{{ $t('modal.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmModal",
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false
      }
    };
  },
  methods: {
    open (title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = {...this.options, ...options};
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close (isAgree) {
      this.resolve(isAgree);
      this.dialog = false;
    }
  }
}
</script>
