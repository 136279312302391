import { defaultApiInstance } from "@/api";

export const nodeService = {
  getNode,
  saveScreenSettings,
  saveNode,
  getAvailableNodes,
  getNodeId,
  deleteNode,
  getConditions,
  getAvailableScreens,
}


async function getAvailableNodes(offset) {
  return await defaultApiInstance.get(`nodes/palette?offset=${offset}`);
}
// eslint-disable-line no-unused-vars
async function getNodeId(payload) {
  return await defaultApiInstance.post('nodes', payload);
}
// DELETE node /api/v1/bpm/nodes/{node_id}
async function deleteNode(id) {
  return await defaultApiInstance.delete(`nodes/${id}`);
}
async function getNode(type, id, preset, connections, lang='en') {

  let queryConnections = connections ? `?new_process_format=true&connections=${connections}&lang=${lang}` : `?lang=${lang}`
  if (type === 'node') {
    sessionStorage.setItem(`node-${id}-${preset}-lang`, lang);
    sessionStorage.setItem('lastSelectedLang', lang);
  }
  return preset
    ? await defaultApiInstance.get(`nodes/${id}/${preset}${queryConnections}`)
    : await defaultApiInstance.get(`nodes/${id}${queryConnections}`);
}

async function saveScreenSettings(id, settings) {
  return defaultApiInstance.patch(`nodes/${id}`, settings);
}

async function saveNode(id, preset, node) {
  return preset
    ? await defaultApiInstance.patch(`nodes/${id}/${preset}`, node)
    : await defaultApiInstance.patch(`nodes/${id}`, node);
}

async function getConditions(offset) {
  return await defaultApiInstance.get(`conditionsRules?offset=${offset}`);
}

async function getAvailableScreens(node_id) {
  return await defaultApiInstance.get(`nodes/availableScreens/${node_id}`)
}