<template>
        <div class="phone-screen__wrapper ">
            <div class="phone-screen phone-screen-custom">
            <div class="phone-screen__inner">
                <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
                    <div class="phone-screen__main">
                        <h1 class="phone-screen__title">Homescreen</h1>
                        <Next_emi_and_grace :grace="grace"/>
                        <Credit_balance :outstanding="grace" :available="maxAvailable" />
                        <Transfer_credit_limit />
                    </div>
                </div>
            </div>
        </div>
        
</template>

<script>
import Next_emi_and_grace from '../widgets/homescreen/nextEmiGrace.vue'
import Credit_balance from '../widgets/homescreen/creditBalance.vue'
import Transfer_credit_limit from '../widgets/homescreen/transferCreditLimit.vue'
export default {
    components:{
        Next_emi_and_grace,
        Credit_balance,
        Transfer_credit_limit,
    },
    computed: {
        grace(){
            return 0
        },
        maxAvailable(){
            return 100000
        },
    },
    data(){
        return{
            showPopup: false,
            showEmi: false,
            showGrace: false,
        }
    },
    methods: {
        eventEmi(){
            this.showEmi = !this.showEmi
        },
        eventGrace(){
            this.showGrace = !this.showGrace
        },
    }
}
</script>