<template>
  <div class="block">
    <div class="card shadow">
        <div class=" mb-2">
            <div class="card__content">
                <div class="flex card__title">
                    <img src="@/assets/images/gray_circle.png" class="card__circle" alt="gray_circle">
                    <p>{{ title }}</p>
                </div>
                <div v-for="item,i in tabs_content" :key="i" class="flex flex_between credit_line" :class="{'pt-12': item.notification}">
                    <div v-if="item.notification" class="credit_line__notification">
                        <p>{{ notification }}</p>
                        <span class="credit_line__notification-triangle"></span>
                    </div>
                    <div class="credit_line__date">
                        <p>{{item.date}}</p>
                        <span>{{item.next_due_date}}</span>
                    </div>
                    <div class="credit_line__payment">
                        <p>₹{{item.sum}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex credit_line_total mb-0">
            <img src="@/assets/images/gray_circle.png" class="card__circle" alt="gray_circle">
            <p>{{total}}</p>
            <p>₹{{total_summ}}</p>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            tabs_content: [{
                'date': '31 Jan',
                'next_due_date': 'Next Due date',
                'sum': 10290,
                'notification': false
            },{
                'date': '31 Jan',
                'next_due_date': 'Next Due date',
                'sum': 7546,
                'notification': true
            }]
        }
    },
    props: {
        title: {
            default: 'Line of credit',
            type: String
        },        
        total: {
            default: 'Total outstanding',
            type: String
        },        
        notification: {
            default: 'If Grace ₹15,000.00 paid by 20 Jan',
            type: String
        },        
        total_summ: {
            default: 55000,
            type: Number
        },        
    }
}
</script>