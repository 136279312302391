<template>
    <div class="progress">
        <div class="progress__description mb-1">
            <div class="d-flex" style="gap:10px">
                <div>
                    <p class="mb-1">Paid</p>
                    <b>{{ data.progress }}/{{ data.size }}</b>
                </div>
                <div>
                    <p class="mb-1">Period</p>
                    <b>{{ data.size }} month</b>
                </div>
            </div>
            <div>
                <p class="mb-1">EMI due</p>
                <b>2.300.00</b>
            </div>
        </div>
        <div class="progress__line">
            <div v-for="item in +data.size" :key="item" class="progress__line__item" :style="background(item)"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBarDiscrete',
    props: {
        data: {
            type: Object,
            require: true
        }
    },
    methods: {
        background(index){
            if(+this.data.progress >= index) return 'background-color:' + this.data.backgroundActiveColor
            return 'background-color:' + this.data.backgroundColor
        }
    }
}
</script>

<style lang="scss" scoped>
.progress{
    display: flex; 
    flex-direction: column;

    &__description{
        display: flex; 
        justify-content: space-between;
    }

    &__line{
        display: flex; 
        flex-direction: row;
        gap: 5px;
        justify-content: space-between; 
    
        &__item{
            height: 5px; 
            width: 100%;
            border-radius: 10px;
        }
    }
}
</style>