<template>
  <PhoneWrapper node-name="НОДА: ИДЕНТИФИКАЦИЯ. ПРЕСЕТ: EMAIL">
    <div class="phone-screen__context identify-email">
      <ProfileHeader title="Сhange phone number"/>
      <p class="phone-screen__text mt-9">All VIVA account-related information will be sent to this mobile number.
      </p>
      <bpmInput label="Mobile number:" placeholder="+91 987 833 8015" class="mt-8" />
      <p class="phone-screen__small-text mt-1">Your OTP will be sent on this number</p>
      <bpmButton class="mt-auto mb-10" style="width: 100%">
        <template v-slot:content><span style="color: white">Next</span></template>
      </bpmButton>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";

export default {
  name: "IdentifyPhone",
  components: {ProfileHeader, PhoneWrapper},
}
</script>

<style lang="scss" scoped>

</style>