<template>
  <v-menu
    absolute
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :color="value"
        dark
        block
        v-on="on"
      />
    </template>
    <v-color-picker
      :value="value"
      class="mx-auto"
      @input="handleInput"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'MainColorPicker',
  props: {
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    handleInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
