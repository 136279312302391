<template>
  <v-col cols="11">
    <div class="if-operation__options">
      <div class="mb-5 d-flex justify-space-between conditions-settings__hide">
        <v-autocomplete
            class="select_conditions"
            :items="savedConditionsCopy"
            @input="onConditionChange"
            item-value="id"
            item-text="ruleName"
            color="deep-purple accent-2"
            v-model="newConditionId"
            :label="$t('node.intoCondition.selectCondition')"
            style="width:calc(100% - 40px);"
            return-object
            hide-details
        >
<!--    NEED TO DELETE IF PAGINATION WILL BE REMOVED      -->
<!--          <template v-slot:append-item>-->
<!--            <div v-intersect="onIntersect" class="pa-4 teal&#45;&#45;text">-->
<!--              Loading more items ...-->
<!--            </div>-->
<!--          </template>-->
        </v-autocomplete>
        <span class="text-caption"> | {{ newConditionId }}</span>
         <div style="width:40px;">
          <v-tooltip left v-if="canShowConditions">
            <template v-slot:activator="{ on, attrs }">               
              <v-btn x-small text @click="visibleFields(), editCondition()" :color="colorGlasses" v-bind="attrs" v-on="on">
                <v-icon>mdi-glasses</v-icon>
              </v-btn>
            </template>
            <span>{{$t('node.intoCondition.showRule')}}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">               
              <v-btn x-small text @click="deleteConditionPopup($event)" color="error" v-bind="attrs" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('node.intoCondition.delRule')}}</span>
          </v-tooltip>
         </div>
      </div>

      <div v-if="visible || fieldsParams.status === 'edit'" class="conditions-settings">
        <div>
          <label class="conditions-settings__label">{{$t('node.intoCondition.condition')}}</label>
          <v-select
            :items="[fieldsParams.condition]"
            v-model="fieldsParams.condition"
            @change="clearOptions(3)"
            outlined
            dense
            readonly
          />
        </div>
        <div>
          <label class="conditions-settings__label">{{$t('node.intoCondition.service')}}</label>
          <v-select
            :items="[fieldsParams.service]"
            item-text="value"
            item-value="id"
            v-model="fieldsParams.service"
            @change="clearOptions(2)"
            outlined
            dense
            readonly
          />
        </div>
        <div>
          <label class="conditions-settings__label">{{$t('node.intoCondition.action')}}</label>
          <v-select
            :items="[fieldsParams.method]"
            item-text="value"
            item-value="id"
            v-model="fieldsParams.method"
            @change="clearOptions(1)"
            outlined
            dense
            readonly
          />
        </div>
        <div>
          <label class="conditions-settings__label">{{$t('node.intoCondition.answer')}}</label>
            <div class="d-flex">
               <v-select
                  :items="getAvailableAnswer"
                  item-text="name" item-value="id"
                  v-model="fieldsParams.answer_id"
                  :disabled="fieldsParams.method === ''"
                  outlined
                  dense
                  readonly
                  style="width: 40%;"
                  />
               <v-select
                  :items="[fieldsParams.answerPrefix]"
                  v-model="fieldsParams.answerPrefix"
                  outlined
                  dense
                  readonly
                  style="width: 20%;"
                  />
               <v-text-field
                  label="result api field"
                  v-model="fieldsParams.result_api_field"
                  style="width: 40%;"
                  outlined
                  dense
                  disabled
                  />
            </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn elevation="0" class="white--text mb-1" small color="deep-purple accent-2" @click="visibleFields(), editCondition()">
            {{ $t('close') }}
          </v-btn>
        </div>
      </div>
    </div>
    <!-- <span v-if="errorCondition" class="red--text ml-3">{{ errorCondition }}</span> -->
  </v-col>
</template>

<script>
import searchConditions from "@/mixins/searchConditions";
import { EventBus } from "@/utils/event-bus";
export default {
   props: {
      data: Object,
      selectedRules: Array
   },
   mixins: [searchConditions],
   data() {
      return {
         visible: false,
         fieldsParams: {},
         change: false,
         answerPrefix: ['>', '<', '=', '!='],
         newConditionId: this.data.id
      }
   },
   methods: {
      // change visible composition condition
      editCondition(visible = null) {
         if (visible === false) return this.visible = visible
         if (visible === 'change') {
            this.change = true
         }
         this.visible = !this.visible
      },
      // save new condition or change old
      saveCondition() {
         this.$emit('saveCondition', this.fieldsParams)
         this.$forceUpdate();
      },
      deleteConditionPopup($event) {
         this.$emit('changeCondition', 'delete_condition')
         this.$emit('changePosition', $event.clientY)
      },
      changeCondition(newId) {
         this.$store.dispatch('node/changeCondition', { id: this.id, ...this.nodeId_presetId, newId })
      },
      // get condition if id have in savedConditions or add empty
      getSavedCondition() {
         if(this.data.name !== undefined) {
            this.fieldsParams = this.data
            return this.fieldsParams.status = 'done'
         }
         if (this.data !== undefined) {
            this.fieldsParams = {
               "status": 'new',
               "canDelete": true,
               "name": '',
               "condition": '',
               "service": '',
               "method": '',
               "answerPrefix": '=',
               "answer_id": '',
               "result_api_field": ''
            }
         }
      },
      // automatically create name condition from selected elements
      autoName() {
         if (this.fieldsParams.condition && this.fieldsParams.service && this.fieldsParams.method)
            this.fieldsParams.name = `${this.fieldsParams.condition}` 
            this.fieldsParams.name += ` в ${this.getAvailableServices.find(item => item.id === this.fieldsParams.service).api_name}` 
            this.fieldsParams.name += ` через ${this.getAvailableMethods.find(item => item.id === this.fieldsParams.method).name}.`
            this.fieldsParams.name += ` Ответ ${this.fieldsParams.answerPrefix} ${this.getAvailableAnswer.find(item => item.id === this.fieldsParams.answer).name}`
      },
      clearOptions(count) {
         if (count > 2) this.fieldsParams.service = ''
         if (count > 1) this.fieldsParams.method = ''
         if (count > 0) this.fieldsParams.answer = ''
      },
      visibleFields(){
        this.fieldsParams.status === 'edit'
          ? this.fieldsParams.status = 'done'
          : this.fieldsParams.status = 'edit'
      }
   },
   computed: {
      // check fieldsParams on empty filds
      fieldsReady() {
         for (let key in this.fieldsParams) {
            if (this.fieldsParams[key] === '') return false
         }
         return true
      },
      // get serviсes in available condition
      // getAvailableServices() {
      //    if (this.fieldsParams.condition !== '') return this.conditionsOptions[this.fieldsParams.condition]
      //    return ['']
      // },
      // get methods in available condition
      // getAvailableMethods() {
      //    if (this.fieldsParams.service !== '') return this.getAvailableServices?.find(item => item.id === this.fieldsParams.service)?.methods
      //    return ['']
      // },

      // get answer in available condition
      getAvailableAnswer() {
         if (this.fieldsParams.method !== '') return this.getAvailableMethods?.find(item => item.id === this.fieldsParams.method)?.answers
         return ['']
      },
      // errorCondition(){
      //    if(Object.keys(this.conditionsOptions).length !== 0){
      //       if(!this.getAvailable.includes(this.fieldsParams.condition) && this.fieldsParams.status !== 'new') return 'Не корректное условие, не хватает данных'
      //       if(this.getAvailableServices === undefined) return 'Не корректный cервис, не хватает данных'
      //       if(this.getAvailableMethods === undefined) return 'Не корректное действие, не хватает данных'
      //       if(this.getAvailableAnswer === undefined) return 'Не корректный ответ, не хватает данных'
      //    }
      //    return false
      // },
      colorGlasses(){
        return this.fieldsParams.status === 'edit'
          ? '#A37CF0'
          : '#e2e2e2'
      },
      savedConditions(){
         let conditions = this.$store.getters["node/conditions"]
         // remove filter that hides selected condition
         // if(this.newConditionId === undefined) return conditions.filter((item) => !this.selectedRules.includes(item.id))
         return conditions
      },

      canShowConditions(){
         return !(this.fieldsParams.name === 'Переход' || this.fieldsParams.name === 'Выход')
      }
   },
   watch:{
      data(){
         this.getSavedCondition()
      },
      newConditionId(val){ 
        if(val) this.$emit('saveCondition', this.savedConditions.find(item => item.id === val))
      }
   },
   mounted() {
      this.getSavedCondition()
   },
   created(){
    EventBus.$on('deletePreset', () => { 
      this.newConditionId = ''
    })
  },
  beforeDestroy() {
    EventBus.$off('deletePreset')
  }
}
</script>

<style scoped lang="scss">
.conditions-settings{
   border:1px solid #A37CF0;
   padding: 20px 10px 10px;
   border-radius: 8px;
   margin-bottom: 25px;

   &__hide{
      border:1px solid #e2e8f0;
      padding:4px 10px;
      border-radius: 10px;
   }

   &__label {
      display: block;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 8px;
   }
}

.v-text-field{
  margin: 0;
}
.if-operation {
  // &__options {
  //   border: 1px solid $second-color;
  //   border-radius: 3px;
  //   padding: 2px;
  //   margin: 10px 0px;
  //   position: relative;
  //   .v-subheader {
  //     height: 30px !important;
  //     padding: 0;
  //     padding-right: 8px;
  //     align-items: center;
  //     justify-content: end;
  //     text-align: right;
  //   }
  //   .v-input {
  //     padding-top: 0;
  //     margin-top: 0;
  //     &__slot {
  //       margin-bottom: 0;
  //       max-width: 95%;
  //     }
  //   }

  //   .border_none, .select_answer{
  //     .v-input__slot{
  //       &::before{
  //         border: none !important;
  //       }
  //       &::after{
  //         border: none !important;
  //       }
  //       .v-input__icon.v-input__icon--append{
  //         border: 1px solid $second-color;
  //         border-radius: 6px;
  //         margin-left: -5px;
  //         margin-top: 2px;
  //       }
  //     }
  //   }
  //   .border_none{
  //     .v-select__selection.v-select__selection--comma{
  //       position: absolute;
  //       z-index: 2;
  //       right: 8px;
  //     }
  //     .mdi-menu-down::before{
  //       content: none !important;
  //     }
  //   }
  //   .select_answer{
  //     margin-left: -10px;
  //     .v-select__selection.v-select__selection--comma{
  //       display: none !important;
  //     }
  //   }
  //   .row {
  //     margin: 0;
  //     +.row{
  //       margin: 0;
  //     }
  //   }
  //   .if_label{
  //     padding: 0 15px;
  //     background-color: $second-color-light;
  //     border-radius: 3px;
  //   }
  //   .name{
  //     margin-left: 9px;
  //     border-bottom: 1px dashed $main-color-dark;
  //     color: $main-color-dark;
  //   }
  //   .fields{
  //     max-height: 30px;
  //     .btn__autoname{
  //       color: $main-color-dark !important; 
  //       width: 100%; 
  //       background-color: $main-color-light !important; 
  //       border-radius: 50%; 
  //       max-height: 20px;
  //       max-width: 20px;
  //     }
  //     .answer>div{
  //       width: 100%;
  //     }
  //   }
  //   &_danger-border{
  //     border: 1px solid red !important;
  //   }
  // }
}
</style>
