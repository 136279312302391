import Vue from "vue";
import Vuex from "vuex";

import { alert } from "@/store/modules/alert.module";
import { authentication } from "@/store/modules/auth.module";
import { node } from "@/store/modules/node.module";
import { project } from "@/store/modules/project.module";
import { processes } from "@/store/modules/processes.module";
import { dictionary } from "@/store/modules/dictionary.module";
import { app } from "@/store/modules/app.module"
import { multilang } from "@/store/modules/multilang.module";
import {chatgpt} from "@/store/modules/chatgpt.module";
import {pageBuilder} from "@/store/modules/builder.module";
import { actions } from "@/store/modules/actions.modules";
import { events } from "@/store/modules/events.modules";
import { externalSources } from "@/store/modules/externalSources.module";
import { conditionsRules } from "@/store/modules/conditionsRules.module";
import { references } from "@/store/modules/references.module";
import { projectsList } from "@/store/modules/projectsList.module";
import { endpoints } from "@/store/modules/endpoints.module";
import { properties } from "@/store/modules/properties.module";
import {actionsCRUD} from "@/store/modules/actionsCRUD.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    authentication,
    node,
    project,
    processes,
    dictionary,
    app,
    multilang,
    chatgpt,
    actions,
    events,
    pageBuilder,
    externalSources,
    conditionsRules,
    references,
    projectsList,
    endpoints,
    properties,
    actionsCRUD,
  },
});
