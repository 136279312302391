<template>
  <div class="credit-limit">
    <bpmInput
        label="Available credit limit"
        placeholder="₹1,00,000"
    />
    <p>Enter amount you need to transfer</p>
    <bpmButton variant="success" size="small"><template v-slot:content><span style="color: white">WITHDRAW NOW</span></template></bpmButton>
  </div>
</template>

<script>
export default {
  name: "CreditLimit",
}
</script>

<style lang="scss" scoped>
.credit-limit {
  padding: 30px 50px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 125%;
    color: #777777;
  }
}
</style>