<template>
  <PhoneWrapper node-name="главный экран">
    <PhoneHeader/>
    <CreditLimit class="phone-screen__credit-limit"/>
    <AfterGrace class="phone-screen__after-grace"/>
    <CreditLine class="phone-screen__credit-line"/>
    <MainTips class="phone-screen__main-tips"/>
    <TabBar class="phone-screen__tab-bar"/>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import PhoneHeader from "@/components/nodes/creditLine/widgets/transh/PhoneHeader.vue";
import CreditLimit from "@/components/nodes/creditLine/widgets/transh/CreditLimit.vue";
import CreditLine from "@/components/nodes/creditLine/widgets/transh/CreditLine.vue";
import MainTips from "@/components/nodes/creditLine/widgets/transh/MainTips.vue";
import TabBar from "@/components/nodes/creditLine/widgets/transh/TabBar.vue";
import AfterGrace from "@/components/nodes/creditLine/widgets/transh/AfterGrace.vue";
export default {
  name: "MainScreen",
  components: {
    AfterGrace,
    TabBar,
    MainTips,
    PhoneWrapper,
    PhoneHeader,
    CreditLimit,
    CreditLine
  }
}
</script>

<style lang="scss" scoped>
.phone-screen {
  &__credit-limit {
    margin-top: 30px;
  }

  &__credit-line {
    margin-top: 17px;
  }

  &__after-grace {
    margin-top: 20px;
  }

  &__tab-bar {
    width: 90% !important;
    position: absolute;
    bottom: 0;
  }
}
</style>