/** The best solution to read stream is fetch, because axios based on xhr **/
import store from "@/store";
import {generateFormData} from "@/utils/utils-functions";

const CHATGPT_AUTH_TOKEN = 'Bearer c320dd9a6c2125c33034d2c7fbee0892';
const CHATGPT_URL = 'http://185.87.199.224:8002';

export const chatgptService = {
    sendRequest,
    showProcess,
    testStream,
    loadOpenApi,
    selectAction,
    showMethods
}

/**
 * @param {Response} resp - read stream from backend
 * */
 function readStream(resp) {
    const reader = resp.body.getReader();
    // read() returns a promise that resolves when a value has been received
    reader.read().then(function pump({ done, value }) {
        value = new TextDecoder().decode(value);
        let splittedValues = value.split('{"');
        splittedValues.shift();
        splittedValues = splittedValues.map(el => `{"${el}`).map(el => JSON.parse(el));
        store.dispatch('chatgpt/setStreamedText', splittedValues);
        if (done) {
            store.dispatch('chatgpt/setStreamedText', [{content: '', finish: true}]);
            return;
        }

        // Read some more, and call this function again
        return reader.read().then(pump);
    });
}

/**
 * @param {string} msg - send request to chatgpt with msg param
 * */
async function sendRequest(msg){
    const payload = {msg};
    fetch(`${CHATGPT_URL}/chatgpt`, {
       method: "POST",
       headers: {
           "Content-Type": "application/json",
           Authorization: CHATGPT_AUTH_TOKEN
       },
       body: JSON.stringify(payload)
   })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * function to answer about process
 * */
async function showProcess() {
    fetch(`${CHATGPT_URL}/show_process`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * test function to test stream functional
 * */
async function testStream() {
    fetch(`${CHATGPT_URL}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * @param {object} file - file which we upload
 * load open api file to service, get stream text on response
 * */
async function loadOpenApi(file) {
    const payload = generateFormData(file);
    fetch(`${CHATGPT_URL}/load_openapi`, {
        method: "POST",
        headers: {
            Authorization: CHATGPT_AUTH_TOKEN
        },
        body: payload,
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * @param {string} action - type of action
 * select action and post it to service, get stream text on response
 * */
async function selectAction(action) {
    const payload = JSON.stringify({action});
    fetch(`${CHATGPT_URL}/select_action`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
        body: payload,
    })
        .then((response) => {
            readStream(response);
        })
        .catch((err) => store.dispatch('alert/error', err));
}

/**
 * return list of methods for methods modal
 * */
async function showMethods() {
   let response = await fetch(`${CHATGPT_URL}/show_methods`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: CHATGPT_AUTH_TOKEN
        },
    })
       .catch((err) => store.dispatch('alert/error', err));

   if (response.ok) {
       response = await response.json();
       return response;
   }
}