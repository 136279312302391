<template>
    <div class="phone-screen__wrapper ">
        <div class="phone-screen phone-screen-custom">
        <div class="phone-screen__inner">
            <div class="phone-screen__border phone-screen-custom__border"><img src="@/assets/images/phone.png" alt="phone-border"></div>
            <div class="phone-screen__main">
            <h1 class="phone-screen__title">Homescreen</h1>

                <div class="block">
                    <div class="card card__late_payment">
                        <div class="flex flex_between">
                            <div class="amount">
                                <span>Amount</span>
                                <p class="num">₹{{ amount }}</p>
                            </div> 
                            <div class="overdue">
                                <span>Overdue</span>
                                <p class="num">{{ overdue }} days</p>
                            </div>
                        </div>
                        <div class="payment">
                            <p class="mb-0 payment_fines">Fines accrued: ₹{{payment_fines}}</p>
                            <p class="payment_date">Planned payment date: {{payment_date}}</p>
                        </div>
                    </div>
                </div>
                <Overdue_payment />
            </div>
        </div>
        </div>
    </div>
    
</template>

<script>
import Overdue_payment from '../widgets/homescreen/overduePayment.vue'
export default {
    components:{
        Overdue_payment
    },
    computed: {
    },
    data(){
        return{}
    },
    props: {
        amount: {
            default: 8320,
            type: Number,
        },
        overdue: {
            default: 33,
            type: Number,
        },
        payment_fines: {
            default: 5544,
            type: Number
        },
        payment_date: {
            default: '31.10.2022',
            type: String
        },
    },
}
</script>
