<template>
  <v-btn
      class="btn"
      :class="{ 'btn-danger': danger, 'btn-warning': warning }"
      :disabled="disabled"
      color="grey"
  >
    <slot></slot>
  </v-btn>
</template>

<!-- :color="danger ? 'red darken-4' : warning ? 'yellow darken-4' : '#0F8E9F'" -->

<script>
export default {
  props: {
    danger: {
      type: Boolean,
      default: false,
    },
    warning : {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    }
  },
  name: 'MainButton'
}
</script>

<style lang="scss" scoped>
.btn {
  /*background: #0F8E9F !important;*/
  color: $main-white;
  border-radius: 10px;
  max-width: 170px;
  padding: 0 50px !important;
}
</style>