import {actionsCRUDService} from "@/services/actionsCRUD.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset, isArraysEqual} from '@/utils/utils-functions'

export const actionsCRUD = {
    namespaced: true,
    state: {         
        actionsData: [],
        searchedActionsData: [],
        actionsTypes: [],
        loadedAllData: false,
    },
    getters: {
        actionsData: state => state.actionsData,
        searchedActionsData: state => state.searchedActionsData,
        actionsTypes: state => state.actionsTypes,
        loadedAllData: state => state.loadedAllData,
    },
    mutations: {
        setActions(state, value) {
            state.actionsData = [...state.actionsData, ...value.actions]
        },
        setSearchedActions(state, value){
            state.searchedActionsData = state.searchedActionsData?.length
                ? [...state.searchedActionsData, ...value]
                : value
        },
        deleteActions(state, {id, searchData}){
            state.actionsData = state.actionsData.filter(field => field.id !== id)
            if(Object.values(searchData).some(item => item)) state.searchedActionsData = state.searchedActionsData.filter(field => field.id !== id)
        },
        addActions(state, {dataActions, searchData}){
            state.actionsData.push(dataActions)
            if(Object.values(searchData).some(item => item)) {
                state.searchedActionsData?.length
                    ? state.searchedActionsData.push(dataActions)
                    : state.searchedActionsData = [dataActions]
            }
        },
        cleanSearchedData(state){
            state.searchedActionsData = null
        },
        cleanData(state){
            state.actionsData = []
        },
        setActionsTypes(state, types){
            state.actionsTypes = [...state.actionsTypes, ...types]
        },
        setLoaded(state, loaded){
            state.loadedAllData = loaded
        }
    },
    actions: {
        async getData({commit, getters, dispatch}, payload) {
            const response = await actionsCRUDService.getData({offset: payload?.offset || 0})

            if(getters.actionsData.length >= response.response.total - 1) commit('cleanData')
            commit('setActions', response.response)

            const loaded = await requestWithOffset(response.response, payload, 'getData', dispatch)
            if(loaded) {
                commit('setLoaded', loaded)
            }
        },

        async getDataFiltered({commit, dispatch}, searchData){
            const isType = false // need to hide type in request
            const keys = Object.keys(searchData)
            const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

            const data = {
                isType,
                type: availableKey,
                search: availableKey ? searchData[availableKey] : '',
                offset: searchData?.offset || 0
            }
            const response = await actionsCRUDService.getData(data)

            if(!response?.response?.actions?.length) {
                dispatch('alert/error', i18n.t('actionCRUD.alert.searchError'), { root: true })
                return commit('setLoaded', true)
            }
            commit('setSearchedActions', response.response.actions)

            const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
            if(loaded) commit('setLoaded', loaded)
        },

        async getDataById({commit, dispatch}, {id, show}) {
            const response = await actionsCRUDService.getDataById(id)

            commit('setLoaded', true)
            if(!response?.response?.id) return dispatch('alert/error', i18n.t('actionCRUD.alert.searchError'), { root: true })
            if(!show) {
                commit('setSearchedActions', [response.response])
                commit('setLoaded', true)
            }

            return response.response
        },

        loadData({commit}){
            commit('setLoaded', false)
        },

        cleanSearchedData({commit}){
            commit('cleanSearchedData')
        },

        async createAction({commit, dispatch, getters}, {newData, searchData}){

            const isExistParams = getters.actionsData.some(action => isArraysEqual(action.parameters, newData.parameters))
            if(isExistParams) {
                dispatch('alert/error', i18n.t('actionCRUD.alert.createErrorExistParams'), { root: true })
                return false
            }

            const response = await actionsCRUDService.createAction(newData)

            if(!response?.response || response?.status === 0) {
                dispatch('alert/error', i18n.t('actionCRUD.alert.createError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('actionCRUD.alert.createSuccess'), { root: true })

                const createAction = await actionsCRUDService.getDataById(response.response.id)
                commit('addActions', {dataActions: createAction.response, searchData})

                return response.response.id
            }
        },

        async updateAction({commit, dispatch, getters}, {data, searchData}){
            const response = await actionsCRUDService.updateAction(data)

            if(!response?.response || response?.status === 0){
                dispatch('alert/error', i18n.t('actionCRUD.alert.updateError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('actionCRUD.alert.updateSuccess'), { root: true })

                // set alias to updated data
                const action = getters.actionsData.find(action => action.id === data.id)
                const actionAlias = action?.actionAlias

                commit('deleteActions', {id: data.id, searchData})
                commit('addActions', {dataActions: {...data, actionAlias}, searchData})
            }
        },
        async deleteAction({commit, dispatch}, {id, searchData}){
            const response = await actionsCRUDService.deleteAction(id)

            if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
                dispatch('alert/error', i18n.t('actionCRUD.alert.deleteError'), { root: true })
            }else{
                dispatch('alert/success', i18n.t('actionCRUD.alert.deleteSuccess'), { root: true })

                searchData?.id
                    ? dispatch('cleanSearchedData')
                    : commit('deleteActions', {id, searchData})
            }
        },

        async getActionsTypes({commit, dispatch}, payload){
            const response = await actionsCRUDService.getActionsTypes({offset: payload?.offset || 0})

            commit('setActionsTypes', response.response.actions_types)
            await requestWithOffset(response.response, payload, 'getActionsTypes', dispatch)
        },
    }
}