import {extService} from "@/services/externalSources.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'

export const externalSources = {
    namespaced: true,
    state: {
      externalSources: [],
      searchedExternalSources: [],
      types: [],
      apis: [],
      loadedAllData: false,
    },
    getters: {
      externalSources: state => state.externalSources,
      searchedExternalSources: state => state.searchedExternalSources,
      types: state => state.types,
      apis: state => state.apis,
      loadedAllData: state => state.loadedAllData,
    },
    mutations: {
      setMethods(state, value) {
          state.externalSources = [...state.externalSources, ...value.apiMethods]
      },
      setSearchedMethods(state, value){
          state.searchedExternalSources = state.searchedExternalSources?.length
              ? [...state.searchedExternalSources, ...value]
              : value
      },
      deleteMethod(state, {id, searchData}){
        state.externalSources = state.externalSources.filter(field => field.id !== id)
        if(Object.values(searchData).some(item => item)) state.searchedExternalSources = state.searchedExternalSources.filter(field => field.id !== id)
      },
      addMethod(state, {dataMethod, searchData}){
        state.externalSources.push(dataMethod)
        if(Object.values(searchData).some(item => item)) {
          state.searchedExternalSources?.length
              ? state.searchedExternalSources.push(dataMethod)
              : state.searchedExternalSources = [dataMethod]
        }
      },
      cleanSearchedData(state){
        state.searchedExternalSources = null
      },
      cleanMethods(state){
        state.externalSources = []
      },
      setMethodTypes(state, types){
        state.types = types
      },
      setMethodApis(state, apis){
        state.apis = apis
      },
      setLoaded(state, loaded){
        state.loadedAllData = loaded
      }
    },
    actions: {
      async getMethods({commit, getters, dispatch}, payload) {
        const response = await extService.getMethods({offset: payload?.offset || 0})

        if(getters.externalSources.length >= response.response.total - 1) commit('cleanMethods')
        commit('setMethods', response.response)

        const loaded = await requestWithOffset(response.response, payload, 'getMethods', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataFiltered({commit, dispatch}, searchData){
          const isType = true // need to hide type in request
          const keys = Object.keys(searchData)
          const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

          const data = {
              isType,
              type: availableKey,
              search: availableKey ? searchData[availableKey] : '',
              offset: searchData?.offset || 0
          }
          const response = await extService.getMethods(data)

        if(!response?.response?.apiMethods?.length) {
          dispatch('alert/error', i18n.t('externalSource.alert.searchError'), { root: true })
          return commit('setLoaded', true)
        }
        commit('setSearchedMethods', response.response.apiMethods)

        const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataById({commit, dispatch}, {id, show}) {
        const response = await extService.getMethodById(id)

        commit('setLoaded', true)
        if(!response?.response?.id) return dispatch('alert/error', i18n.t('externalSource.alert.searchError'), { root: true })
        if(!show) {
          commit('setSearchedMethods', [response.response])
          commit('setLoaded', true)
        }

        return response.response
      },

      loadData({commit}){
        commit('setLoaded', false)
      },

      cleanSearchedData({commit}){
          commit('cleanSearchedData')
      },

      async createMethod({commit, dispatch}, {newData, searchData}){
          const response = await extService.createMethod(newData)

          if(!response.response || response.status === 0) {
              dispatch('alert/error', i18n.t('externalSource.alert.createError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('externalSource.alert.createSuccess'), { root: true })

              const createdMethod = await extService.getMethodById(response.response.id)
              commit('addMethod', {dataMethod: createdMethod.response, searchData})

              return response.response.id
          }
      },
     
      async updateMethod({commit, dispatch}, {data, searchData}){
          const response = await extService.updateMethod(data)

          if(!response?.response || response.status === 0){
              dispatch('alert/error', i18n.t('externalSource.alert.updateError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('externalSource.alert.updateSuccess'), { root: true })

              commit('deleteMethod', {id: data.id, searchData})
              commit('addMethod', {dataMethod: data, searchData})
          }
      },
      async deleteMethod({commit, dispatch}, {id, searchData}){
          const response = await extService.deleteMethod(id)

          if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
              dispatch('alert/error', i18n.t('externalSource.alert.deleteError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('externalSource.alert.deleteSuccess'), { root: true })

              searchData?.id
                ? dispatch('cleanSearchedData')
                : commit('deleteMethod', {id, searchData})
          }
      },

      async getMethodTypes({commit}){
        const response = await extService.getMethodTypes()

        commit('setMethodTypes', response.response)
      },
      async getMethodApis({commit, dispatch}, payload){
        const response = await extService.getMethodApis()

        commit('setMethodApis', response.response.instanceListApi)
        requestWithOffset(response.response, payload ?? null, 'getMethodApis', dispatch)
      },
    }
}