<template>
  <div class="d-flex justify-space-between">
    <div>
      <v-select
        :disabled="disabled"
        :value="limit"
        :items="limitItems"
        label="Items per Page"
        @change="limitChange"
        id="translate__per-page"
        color="deep-purple accent-2"
      ></v-select>
    </div>
    <div>
      <v-pagination
        :value="currentPage"
        v-if="limit !== 'all'"
        :disabled="disabled"
        :length="pageCount"
        @input="pageChange"
        id="translate__pagination"
        color="deep-purple accent-2"
        circle
        :total-visible="8"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    currentPage: {
      type: Number,
      required: true,
      default: 1,
    },
    limit: {
      type: [Number, String],
      required: true,
      default: 10,
    },
    pageCount: {
      type: Number,
      default: 1,
    },
    limitItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    limitChange (e) {
      this.$emit('change', {limit: e, currentPage: this.currentPage})
    },
    pageChange (e) {
      this.$emit('change', {limit: this.limit, currentPage: e})
    }
  },
}
</script>
