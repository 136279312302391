<template>
  <PhoneWrapper node-name="НОДА: ИЗМЕНЕНИЕ ДАННЫХ. ПРЕСЕТ: ПАРОЛЬ. ЭКРАН 1: ТЕКУЩИЙ ПАРОЛЬ.">
    <div class="phone-screen__context">
      <ProfileHeader title="Change password"/>

      <p class="phone-screen__title mt-4">Enter your current  password</p>

      <bpmInput placeholder="Enter password" class="mt-3" />

      <bpmButton class="mt-auto mb-10" style="width: 100%">
        <template v-slot:content><span style="color: white">Confirm</span></template>
      </bpmButton>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "ChangePassword",
  components: {PhoneWrapper, ProfileHeader}
}
</script>