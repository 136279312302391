<template>
  <PhoneWrapper node-name="EMI PLAN. ПРЕСЕТ: ПЕРВИЧНЫЙ.">
    <h1 class="phone-screen__title mb-6">Full EMI plan</h1>
    <div class="emi__block">
      <div class="emi__item emi__item_open">
        <div class="emi__subitem">
          <div style="width: 100%">
            <p class="phone-screen__subtitle_grey mb-2">31 Jan’ 23</p>
            <div class="d-flex align-center justify-space-between">
              <p class="phone-screen__subtitle">₹4,758.22</p>
              <div class="d-flex">
                <p class="phone-screen__subtitle">₹8,166.69</p>
                <img class="emi__arrow emi__arrow_up" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
              </div>
            </div>
          </div>
        </div>
        <div class="emi__subitem">
          <p class="phone-screen__subtitle_grey">Principal debt</p>
          <div class="d-flex align-center">
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">₹3,489.22</p>
            <img class="emi__arrow emi__arrow_up emi__arrow_hidden" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
          </div>
        </div>
        <div class="emi__subitem">
          <p class="phone-screen__subtitle_grey">Interest</p>
          <div class="d-flex align-center">
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">₹1,989.04</p>
            <img class="emi__arrow emi__arrow_up emi__arrow_hidden" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
          </div>
        </div>
        <div class="emi__subitem">
          <p class="phone-screen__subtitle_grey">Processing fee</p>
          <div class="d-flex align-center">
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">₹1,000.00</p>
            <img class="emi__arrow emi__arrow_up emi__arrow_hidden" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
          </div>
        </div>
        <div class="emi__subitem">
          <p class="phone-screen__subtitle_grey">Optional term fee</p>
          <div class="d-flex align-center">
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">₹999.00</p>
            <img class="emi__arrow emi__arrow_up emi__arrow_hidden" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
          </div>
        </div>
        <div class="emi__subitem">
          <p class="phone-screen__subtitle_grey">SMS Alert</p>
          <div class="d-flex align-center">
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">₹69.00</p>
            <img class="emi__arrow emi__arrow_up emi__arrow_hidden" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
          </div>
        </div>
      </div>

      <div class="emi__item">
        <div style="width: 100%">
          <p class="phone-screen__subtitle_grey mb-2">31 Jan’ 23</p>
          <div class="d-flex align-center justify-space-between">
            <p class="phone-screen__subtitle">₹4,758.22</p>
            <div class="d-flex">
              <p class="phone-screen__subtitle">₹8,166.69</p>
              <img class="emi__arrow emi__arrow_down" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
            </div>
          </div>
        </div>
      </div>

      <div class="emi__item">
        <div style="width: 100%">
          <p class="phone-screen__subtitle_grey mb-2">31 Jan’ 23</p>
          <div class="d-flex align-center justify-space-between">
            <p class="phone-screen__subtitle">₹4,758.22</p>
            <div class="d-flex">
              <p class="phone-screen__subtitle">₹8,166.69</p>
              <img class="emi__arrow emi__arrow_down" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
            </div>
          </div>
        </div>
      </div>

      <div class="emi__item">
        <div style="width: 100%">
          <p class="phone-screen__subtitle_grey mb-2">31 Jan’ 23</p>
          <div class="d-flex align-center justify-space-between">
            <p class="phone-screen__subtitle">₹4,758.22</p>
            <div class="d-flex">
              <p class="phone-screen__subtitle">₹8,166.69</p>
              <img class="emi__arrow emi__arrow_down" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
            </div>
          </div>
        </div>
      </div>

      <div class="emi__item">
        <div style="width: 100%">
          <p class="phone-screen__subtitle_grey mb-2">31 Jan’ 23</p>
          <div class="d-flex align-center justify-space-between">
            <p class="phone-screen__subtitle">₹4,758.22</p>
            <div class="d-flex">
              <p class="phone-screen__subtitle">₹8,166.69</p>
              <img class="emi__arrow emi__arrow_down" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
            </div>
          </div>
        </div>
      </div>

      <div class="emi__item">
        <div style="width: 100%">
          <p class="phone-screen__subtitle_grey mb-2">31 Jan’ 23</p>
          <div class="d-flex align-center justify-space-between">
            <p class="phone-screen__subtitle">₹4,758.22</p>
            <div class="d-flex">
              <p class="phone-screen__subtitle">₹8,166.69</p>
              <img class="emi__arrow emi__arrow_down" width="6" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
            </div>
          </div>
        </div>
      </div>

      <div class="emi__footer">
        <div class="d-flex justify-space-between mt-4">
          <h1 class="phone-screen__title">Total  Payable</h1>
          <p class="phone-screen__title phone-screen__title_tin">₹66,880.00</p>
        </div>

        <div class="d-flex justify-space-between mt-4">
          <p class="phone-screen__subtitle_grey">Principal debt</p>
          <p class="phone-screen__subtitle_tin">₹55,000.00</p>
        </div>
        <div class="d-flex justify-space-between mt-2">
          <p class="phone-screen__subtitle_grey">Interest</p>
          <p class="phone-screen__subtitle_tin">₹9,191.00</p>
        </div>
        <div class="d-flex justify-space-between mt-2">
          <p class="phone-screen__subtitle_grey">Fees</p>
          <p class="phone-screen__subtitle_tin">₹2,137.00</p>
        </div>
        <div class="d-flex justify-space-between mt-3">
          <p class="phone-screen__subtitle_tin">To see details download PDF</p>
          <img width="24" height="24" src="@/assets/icons/transh/download.svg"/>
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";

export default {
  name: "EmiPlanCombined",
  components: {PhoneWrapper},
}
</script>

<style lang="scss" scoped>
.emi {
  position: relative;
  &__item {
    &:nth-child(odd) {
      background: #F5F7F7;
    }
  }
  &__footer {
    background: #F9F9F9;
  }
}
</style>