<template>
  <PhoneWrapper node-name="перечесление средств">
    <h1 class="phone-screen__title">Transfer</h1>
    <h1 class="phone-screen__title">Enter the details of your account where you want to transfer money</h1>
    <div class="phone-screen__form form">
      <div class="form__block">
        <bpmSelect placeholder="select your bank"/>
        <bpmInput placeholder="Enter your account number"/>
        <bpmInput placeholder="Enter your IFSC Code" />
      </div>
      <div class="form__block">
        <bpmCheckbox label="I confirm that the bank details have been entered correctly and belong to me. I agree to repay the loan even if I do not receive any money due to incorrect details."/>
        <bpmButton>
          <template v-slot:content><span style="color: white">Continue</span></template>
        </bpmButton>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "TransferFunds",
  components: {
    PhoneWrapper,
  }
}
</script>

<style lang="scss" scoped>
  .phone-screen {
    &__form {
      margin-top: 32px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    &__block {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 25px;
      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
</style>