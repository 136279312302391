<template>
  <div class="profile__header">
    <img src="@/assets/icons/profile/arrow-back.svg" alt="back">
    <p class="profile__subtitle profile__subtitle_tin">{{ title }}</p>
    <img src="@/assets/icons/profile/Close-icon.svg" alt="close">
  </div>
</template>

<script>
export default {
  name: "ProfileHeader",
  props: ['title'],
}
</script>