<template>
  <PhoneWrapper node-name="НОДА: ПРОГРАММА ЛОЯЛЬНОСТИ.">
    <div class="phone-screen__context profile-menu">
      <ProfileHeader title="Invite your friends"/>

      <div class="profile-menu__block">
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Share your invite code</p>
          </div>
        </div>
      </div>

      <p class="phone-screen__subtitle phone-screen__subtitle_tin mt-6">Поясняющий текст что это такое и как работает </p>
      <div class="mt-auto mb-10" style="width: 100%">
        <p class="phone-screen__small-text mb-10 text-center"><strong>Code: asdh238bj</strong></p>
        <bpmButton style="width: 100%">
          <template v-slot:content><span style="color: white">Tell a friend</span></template>
        </bpmButton>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";

export default {
  name: "InviteFriends",
  components: {ProfileHeader, PhoneWrapper},
}
</script>


