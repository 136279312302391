<template>
  <PhoneWrapper node-name="НОДА: КОММУНИКАЦИЯ.">
    <div class="phone-screen__context">
      <ProfileHeader title="Set up communication"/>

      <div class="profile-menu__block">
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Paid SMS alert</p>
          </div>
          <bpmCheckbox class="w-auto" />
        </div>
        </div>
      <p class="phone-screen__subtitle phone-screen__subtitle_tin mt-5 mb-3">Cost  ₹100 per month</p>
      <div class="profile-menu__block">
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Promotional SMS</p>
          </div>
          <bpmCheckbox class="w-auto" />
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Promotional PUSH</p>
          </div>
          <bpmCheckbox class="w-auto" />
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Promotional E-mail</p>
          </div>
          <bpmCheckbox class="w-auto" />
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "SetCommunication",
  components: {PhoneWrapper, ProfileHeader}
}
</script>