<template>
  <div class="spinner-loader">
    <v-progress-circular
      :size="70"
      :width="7"
      :color="color"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "SpinnerLoader",
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>

<style scoped lang="scss">
.spinner-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
</style>
