import { defaultApiInstance } from "@/api";

export const referencesService =  {
  getReferences,
  getReferenceById,
  createReference,
  updateReference,
  deleteReference,
  getMethods
}

async function createReference(data) {
  return defaultApiInstance.post('/references', data);
}

async function getReferences({offset, search}) {
  let query = `&offset=${offset}`
  if(search) query = search + query

  return defaultApiInstance.get(`/references?q=${query}`);
}

async function getReferenceById(id){
  return defaultApiInstance.get(`/references/${id}`);
}

async function updateReference(data) {
  return defaultApiInstance.patch(`/references/${data.id}`, data);
}

async function deleteReference(id) {
  return defaultApiInstance.delete(`/references/${id}`);
}

async function getMethods(offset){
  return defaultApiInstance.get(`/properties?q=&offset=${offset}`);
}

export const unHighlightReferencesService = [{
    method: 'post',
    url: '/references'
  },{
    method: 'patch',
    url: '/references'
  },{
    method: 'delete',
    url: '/references'
  },{
    method: 'get',
    url: '/references'
}]