<template>
    <div class="d-flex color-box">
        <div class="color-picker">
            <v-color-picker v-if="index === activeColor" v-model="getcolorData.color" :modes="['hex']" hide-inputs class="color-picker__input"/>
        </div>
         <div>
            <p class="d-flex" @click="$emit('changeColor', index)">
                <span :class="{ 'type-color_active' : index === activeColor }" class="type-color mr-2">
                    <span :style="{'background-color': getcolorData.color}" class="type-color_input"></span>
                </span>
                {{$t('gradientColor.color') - index }}</p>
            <div class="d-flex">
                <template>
                    <BpmInput class="bpm-input mr-4" label="HEX" maxlength="9" placeholder="HEX" v-model="getcolorData.color" size="small" />
                </template>
                <template>
                    <BpmInput class="bpm-input" type="number" label="Weight" maxlength="3" min="0" max="100" placeholder="100" v-model="getcolorData.weight" size="small" />
                </template>
            </div>
         </div>
    </div>
 </template>
 
 <script>
 export default {
     name: 'colorsVue',
     data: function () {
       return {
         getcolorData: this.colorData,
       }
     },
     props: {
         colorData: {
             type: Object,
             default: () => {
                return {
                    color: '#F04D4DFF',
                    weight: '100',
                }
             }
         },
         index: Number,
         activeColor: Number
     }
 }
 </script>
 
 <style lang="scss" scoped>
 .color-box{
     gap: 20px;
     align-items: center;
 }
 
 .bpm-input{
     width: 120px !important;
 }

 .type-color{
  height: 25px;
  width: 25px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &_active{
      border: 1px solid #adb5bd;
  }
  &_input{
    height: 20px;
    width: 20px;
    display: block;
    border-radius: 50px;
  }
}
.color-picker{
    width:300px;
    &__input{
        position: absolute;
        top:0;
    }
}
 </style>