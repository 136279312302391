const AVAILABLE_LANGUAGES = [
  {
    name: "ENG",
    value: "en",
  },
  {
    name: "RUS",
    value: "ru",
  },
];

const DEFAULT_LANGUAGE = "en";

export {
  DEFAULT_LANGUAGE,
  AVAILABLE_LANGUAGES,
};
