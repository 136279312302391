<template>
  <PhoneWrapper node-name="НОДА: ПЕРЕЧИСЛЕНИЕ СРЕДСТВ">
    <h1 class="phone-screen__title">Transfer</h1>
    <div class="block">
      <div class="card">
        <p class="card__title card__title_bold">Enter the details of your account where you want to transfer money</p>
      </div>
      <template><BpmSelect
            placeholder="Select your bank"
            :options='banks'
        /></template>
      <template><BpmInput type="number" placeholder="Enter your account number" /></template>
      <template><BpmInput type="number" placeholder="Enter your IFSC Code" /></template>
        <template><BpmCheckbox
            label="I confirm that the bank details have been entered correctly and belong to me. I agree to repay the loan even if I do not receive any money due to incorrect details."
            class="confirm"
        /></template>
    </div>

    <nextButton btn_name="Confirm" />
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import nextButton from "@/components/nodes/creditLine/widgets/payment/nextButton.vue";
export default {
  name: "vueTransfer",
  components: {
    PhoneWrapper,
    nextButton
  },
  props: {
    banks: {
      default: () => [
        {"index":1,"value":"First bank"},
        {"index":2,"value":"Second bank"},
        {"index":3,"value":"Third bank"},
        {"index":4,"value":"Fourth bank"}
      ],
      type: Array
    }
  }
}
</script>

<style scoped>
.confirm{
  align-items: baseline;
  margin-top: 250px;
}

</style>