<template>
  <v-hover v-slot="{ hover }">
    <div class="phone-screen__wrapper">
      <div class="phone-screen phone-screen-custom" :style="{ background: setting?.properties?.background }">
        <div class="phone-screen__inner">
          <div class="phone-screen__border phone-screen-custom__border">
            <img src="@/assets/images/phone.png" alt="phone-border">
          </div>
          <div class="phone-screen__temp-fullscreen" v-if="tempFullImagesId.includes(setting?.id)" style="padding: 2px">
            <div
                v-for="(item) in setting.nodeFields"
                :key="item.id"
                class="phone-screen__item"
                :class="{ selected: item.name === $store.getters['node/currentField'] }"
                @click="$store.dispatch('node/setCurrentField', item.name)"
                style="margin-left: 12px;"
            >
              <PreviewImage
                  v-if="item.nodeType === 'image'"
                  max-width="100%"
                  max-height="100%"
                  :src="item.properties.imageUrl"
              />
            </div>
          </div>
          <div
              class="phone-screen__disabled"
              :class="{ active: setting.properties?.visibility ? !setting.properties.visibility : false }"
          ></div>
          <div v-if="!tempFullImagesId.includes(setting?.id)" class="phone-screen__main" :class="{selected: $store.getters['node/currentField']}">
            <!-- TODO take img for node FastLogin. Need to resolve problem with cope nodes -->
            <div v-if="settings?.id === 55">
              <v-img
                  width="150"
                  height="250"
                  contain
                  src="@/assets/images/fastLogin.jpg"
              />
            </div>
            <div style="width: 100%" v-else>
              <div
                  v-for="(item) in setting.nodeFields"
                  
                  :key="item.id"
                  class="phone-screen__item"
                  :class="{ selected: item.name === $store.getters['node/currentField'] }"
                  @click="$store.dispatch('node/setCurrentField', item.name)"
              >

                <!-- Visibility of fields -->
                <template v-if="item.properties.visibility">

                  <!-- Ограничение вывода тайтла по определенным правилам, а также в одном из экранов регистрации и определенном филде (номер телефона в регистрации) - быстрое, но костыльное решение -->
                  <PreviewText
                      v-if="item.nodeType.includes('block')"
                      :item="item"
                  />

                  <PreviewImage
                      v-if="item.nodeType === 'image'"
                      max-width="200"
                      max-height="200"
                      :src="item.properties.imageUrl"
                  />

                  <FieldDisplay
                      v-if="item.nodeType.includes('input') || item.nodeType === 'timer'"
                      :item="item"
                  />

                  <bpm-action-text v-if="item.nodeType === 'button.arrowText'" :text="item.properties.text" :rightIcon="rightArrow" />

                  <div v-if="item.nodeType === 'button.main'" class="phone-screen__button">
                    <bpm-button :text="item.properties.strokeColor" :backgroundColor="item.properties.backgroundColor">
                      <span v-html="item.properties.text"></span>
                    </bpm-button>
                  </div>

                  <template v-if="item.nodeType === 'block.bullet'">
                    <ul v-if="isJson(item.properties.data) && Array.isArray(JSON.parse(item.properties.data))" class="my-2">
                      <li v-for="(text,i) in JSON.parse(item.properties.data)" :key="i" v-html="text"></li>
                    </ul>
                    <p v-else v-html="item.properties.data"></p>
                  </template>

                  <WidgetDisplay
                      v-if="item.nodeType === 'widget'"
                      :item="item"
                  />
                  <template v-if="item.nodeType === 'switcher'">
                    <div class="d-flex flex-row align-center justify-space-between" > 
                      <div class="d-flex align-center" style="gap:10px">
                        <div class="phone-screen__img phone-screen__img-empty"></div>
                        <b>{{ item.properties.text }}</b>
                      </div>
                      <!-- <BpmCheckbox :checked="item.properties.state" /> -->
                      <input type="checkbox" :checked="item.properties.state">
                    </div>
                  </template>

                  <template v-if="item.nodeType === 'checkbox'">
                    <div class="d-flex align-center" style="gap: 5px;">
                      <input type="checkbox">
                      <span v-html="item.properties.text"></span>
                    </div>
                  </template>

                  <div v-if="item.nodeType === 'radiobutton' && isJson(item.properties.data)" class="d-flex" :class="{'flex-column' : item.properties.displayType === 'flags', 'flex-wrap' : item.properties.displayType !== 'flags'}">
                    <BpmRadio
                      v-for="(property,i) in JSON.parse(item.properties.data)" :key="i"
                      :bubble="item.properties.displayType === 'radiobutton'"
                      :label="property" modelValue="" value="yes"
                      :style="{width: item.properties.displayType !== 'flags' ? 'inherit' : '100%'}"
                      class="ma-1"
                    />
                  </div>

                  <template v-if="item.nodeType.includes('progressBar')">
                    <BpmProgress v-if="item.nodeType === 'progressBar'" :max="100" :value="+item.properties.progress" :backgroundColor="item.properties.background" :color="item.properties.color" />
                    <div v-if="item.nodeType.includes('discrete')">
                      <ProgressBarDiscrete :data="item.properties"/>
                    </div>
                  </template>

                  <template v-if="item.nodeType === 'changer'">
                    <ChangerTab :data="item.properties"/>
                  </template>

                  <template v-if="item.nodeType === 'webTooltip'">
                    <div :style="`background-color: ${item.properties.backgroundColor}`" style="padding: 10px; border-radius: 10px; color:#fff;">
                      <span v-html="item.properties.text"></span>
                    </div>
                  </template>

                  <template v-if="item.nodeType === 'webImage'">
                    <img v-if="item.properties.hover === 'hover'" @mouseleave="item.properties.hover = 'main'" :src="item.properties.hoverImageUrl" alt="hoverImg">
                    <img v-else @mouseenter="item.properties.hover = 'hover'" :src="item.properties.imageUrl" alt="mainImg">
                  </template>

                  <template v-if="item.nodeType === 'webProgressBar'">
                    <div class="d-flex justify-space-between">
                      <span v-html="item.properties.text.replace(/<\/?[^>]+>/gi, '')"></span>
                      <span v-html="item.properties.progress + '%'"></span>
                    </div>
                    <BpmProgress :max="100" :value="+item.properties.progress" :backgroundColor="item.properties.backgroundLineColor" :color="item.properties.backgroundActiveLineColor" />
                  </template>

                  <template v-if="item.nodeType === 'webButton.arrow'">
                    <p>webButton.arrow</p>
                    <p>{{ item.properties }}</p>
                    <span v-html="item.properties.text.replace(/<\/?[^>]+>/gi, '')"></span>
                    <span></span>
                  </template>

                  <div class="d-flex align-start" style="gap:10px;" v-if="item.nodeType === 'dropDownList'">
                    <div>
                      <div v-if="item.properties.imageUrlOpened === '' || item.properties.imageUrl === ''" class="phone-screen__img phone-screen__img-empty"></div>
                      <template v-else>
                        <img v-if="item.properties.state" :src="item.properties.imageUrlOpened" class="phone-screen__img" alt="open">
                        <img v-else :src="item.properties.imageUrl" class="phone-screen__img" alt="closed">
                      </template>
                    </div>
                    <div>
                      <span v-html="item.properties.label" v-if="item.properties.fixed"></span>
                      <span v-html="item.properties.label" v-else @click="item.properties.state = !item.properties.state"></span>
                      <div v-if="isJson(item.properties.data) && Array.isArray(JSON.parse(item.properties.data)) && item.properties.state" class="my-2">
                        <p v-for="(text,i) in JSON.parse(item.properties.data)" :key="i" v-html="text"></p>
                      </div>
                    </div>
                  </div>
                  
                </template>

              </div>
            </div>
          </div>
        </div>

        <!-- TODO take img for node FastLogin. Need to resolve problem with cope nodes -->
        <div v-if="settings?.id === 55"></div>
        <div v-else>
          <div class="phone-screen__arrow">
            <img :src="arrowIcon" alt="arrow">
          </div>
          <NodeScreenActions
              v-if="activeActions"
              :setting="setting"
              :screen-settings="screenSettings"
              :can-hide="canHide"
              @openScreenSettings="openScreenSettings"
              :class="{ 'show-btns': hover }"
          />
        </div>
      </div>
      <div class="phone-screen__name"> Экран: {{ setting.title }}<span class="text-caption"> | {{ setting.id }}</span></div>
    </div>
  </v-hover>
</template>

<script>
import NodeScreenActions from "@/components/nodes/NodeScreenActions";
import FieldDisplay from "@/components/FieldDisplay";
import WidgetDisplay from "@/components/WidgetDisplay";
import ChangerTab from "@/components/ChangerTab.vue";
import ProgressBarDiscrete from "@/components/ProgressBarDiscrete.vue";
import {isJsonString} from "@/utils/utils-functions";

export default {
  name: 'NodeScreen',
  components: {
    WidgetDisplay,
    FieldDisplay,
    NodeScreenActions,
    ChangerTab,
    ProgressBarDiscrete,
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
    activeActions: {
      type: Boolean,
      required: true,
    },
    canHide: {
      type: Boolean,
      default: false,
    },
    screenSettings: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      arrowIcon: require('@/assets/icons/screen__arrow.svg'),
      rightArrow: require('@/assets/icons/arrow-icon.svg'),
      staticUrl: process.env.VUE_APP_STATIC_URL,
      tempFullImagesId: [759130, 759958, 760998, 761025, 761043, 761070, 761223, 761268, 761247, 761683, 761957, 761964, 761970, 761977, 761991],
    }
  },
  computed: {
    dictionaries () {
      return this.$store.getters["dictionary/dictionaries"];
    },
    settings () {
      return  this.$store.getters["node/settings"]
    },
  },
  methods: {
    openScreenSettings (screenId) {
      this.$emit('openScreenSettings', screenId);
    },
    isJson(str) {
      return isJsonString(str);
    }
  }
}
</script>

<style lang="scss" scoped>

.phone-screen {
  &__item {
    &:first-child {
      margin-top: 10px;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    color: white;
  }
  &__wrapper {
    transform: scale(0.7);
    margin-top: -7em;
  }
  &__img{
    height:30px; 
    width: 30px; 
    border-radius: 10px;
    &-empty{
      background-color: #e9f6e5; 
    }
  }
}
</style>
