<template>
  <div class="photo">
    <div class="photo__back">
    </div>
    <PersonalSettings />
    <div class="photo__popup popup">
      <div class="popup__item">
        From gallery
      </div>
      <div class="popup__item">
        Make a photo
      </div>
      <div class="popup__item">
        Cancel
      </div>
    </div>
  </div>
</template>

<script>
import PersonalSettings from "@/components/nodes/creditLine/profile/PersonalSettings.vue";

export default {
  name: "PersonalSettingsPhoto",
  components: {PersonalSettings},
}
</script>

<style lang="scss" scoped>
.photo {
  position: relative;
  &__back {
    width: 100%;
    height: 812px;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.5;
    background: #000;
    z-index: 2;
    border-radius: 50px;
  }
}
.popup {
  position: absolute;
  bottom: 100px;
  left: 30px;
  z-index: 2;
  &__item {
    width: 315px;
    padding: 15px 0;
    text-align: center;
    background: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    border-radius: 8px;
    color: #2C3135;
    &:first-child {
      border-radius: 8px 8px 0 0;
    }
    &:nth-child(2) {
      border-radius: 0 0 8px 8px;
      margin-bottom: 16px;
    }
  }
}
</style>