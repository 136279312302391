<template>
  <PhoneWrapper node-name="ТАРИФЫ">
    <h1 class="phone-screen__title mb-5">Tariffs</h1>
    <div class="tarrif__block">
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Service fee:</p>
        <p class="phone-screen__subtitle">0</p>
      </div>
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Processing fee:</p>
        <p class="phone-screen__subtitle">₹ 1,000 (charged only once)</p>
      </div>
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Grace period:</p>
        <p class="phone-screen__subtitle">until the 20th day of the month following the month of receiving the loan</p>
      </div>
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Interest rate:</p>
        <p class="phone-screen__subtitle">36% per annum</p>
      </div>
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Late payment penalty:</p>
        <p class="phone-screen__subtitle">4.5% per month for the amount of overdue debt (accrued on a daily basis)</p>
      </div>
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Early repayment fee:</p>
        <p class="phone-screen__subtitle">4% of the early repayment amount. The minimum amount of early repayment is ₹ 10,000</p>
      </div>
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">Optional term fee:</p>
        <p class="phone-screen__subtitle">5 months – free; 10 months – ₹ 999; 20 months – ₹ 1499</p>
      </div>
      <div class="tarrif__item">
        <p class="phone-screen__subtitle phone-screen__subtitle_grey">EMI reschedule (optional, charged when the payment date is postponed):</p>
        <p class="phone-screen__subtitle">₹ 750</p>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
export default {
  name: "TarifsNode",
  components: {
    PhoneWrapper,
  }
}
</script>

<style lang="scss" scoped>
.tarrif {
  &__block {
    text-align: left;
    align-self: flex-start;
  }
  &__item {
    margin-bottom: 16px;
  }
}
.phone-screen {
  &__subtitle {
    margin-bottom: 5px;
    font-weight: 400;
    max-width: 303px;
  }
}
</style>