<template>
  <PhoneWrapper node-name="НОДА: ПРОФИЛЬ. ПРЕСЕТ: ПЕРСОНАЛЬНЫЕ НАСТРОЙКИ. ЭКРАН: АДРЕС.">
    <div class="phone-screen__context address">
      <ProfileHeader title="Address"/>

      <p class="phone-screen__title mt-5">Where to send documents and certificates</p>

      <div class="address__block">
        <div class="address__item">
          <div class="address__name">
            <p class="phone-screen__subtitle">Residence address</p>
            <bpmCheckbox/>
          </div>
          <p class="phone-screen__small-text address__text">21, Rachna, Sector 17, Vashi, Navi Mumbai</p>
          <p class="phone-screen__small-text address__link">Change</p>
        </div>

        <div class="address__item">
          <div class="address__name">
            <p class="phone-screen__subtitle фв">Address of registration</p>
            <bpmCheckbox/>
          </div>
          <p class="phone-screen__small-text address__text">21, Rachna, Sector 17, Vashi, Navi Mumbai</p>
          <p class="phone-screen__small-text address__link">Change</p>
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";

export default {
  name: "ProfileAddress",
  components: {ProfileHeader, PhoneWrapper},
}
</script>

<style lang="scss" scoped>
.address {
  &__link {
    color: #118DFF;
  }
  &__item {
    width: 100%;
    margin-bottom: 15px;
    background: #EAEAEA;
    border-radius: 8px;
    padding: 8px 14px;

  }
  &__name {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__text {
    margin-top: 7px;
    margin-bottom: 15px;
    max-width: 200px;
  }
  div[class*="bpm-checkbox"] {
    width: auto;
  }
}
</style>