import { defaultApiInstance } from "@/api";
import {generateFormData} from "@/utils/utils-functions";

export const projectService =  {
  getProjects,
  getProject,
  saveProject,
  getFiles,
  addFiles
}

async function getProjects() {
  return await defaultApiInstance.get('projects');
}

async function getProject(id) {
  return await defaultApiInstance.get(`projects/${id}`);
}

async function saveProject(project, id) {
  return await defaultApiInstance.patch(`projects/${id}`, project);
}

async function getFiles() {
  return await defaultApiInstance.get(`files`);
}

async function addFiles(files) {
  const payload = generateFormData(files);

  return await defaultApiInstance.post(`files`, payload);
}