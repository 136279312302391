<template>
  <PhoneWrapper node-name="ОТОБРАЖЕНИЕ СТАУТУСА. ПРЕСЕТ: УСПЕШНО">
    <h1 class="phone-screen__title mb-8">Transfer</h1>
    <h1 class="phone-screen__title mb-10">Congratulations! Your transfer has been successfully completed.</h1>
    <img class="mb-10" height="80px" width="80px" src="@/assets/images/logo.png"/>

    <div class="mb-10">
      <p class="phone-screen__text phone-screen__text_grey">Transfer amouth:</p>
      <p class="phone-screen__title" style="font-size: 32px">₹ 40,000.00</p>
    </div>

    <div class="d-flex justify-space-between mt-3" style="width: 100%">
      <p class="phone-screen__subtitle">Transaction receipt</p>
      <img width="24" height="24" src="@/assets/icons/transh/download.svg"/>
    </div>

    <bpmButton class="mt-auto mb-10" style="width: 100%">
      <template v-slot:content><span style="color: white">Close</span></template>
    </bpmButton>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";

export default {
  name: "StatusDisplay",
  components: {PhoneWrapper},
}
</script>

<style lang="scss" scoped>

</style>