import {referencesService} from "@/services/references.service";
import i18n from "@/plugins/i18n.js";
import {requestWithOffset} from '@/utils/utils-functions'

export const references = {
    namespaced: true,
    state: {
      references: [],
      searchedReferences: [],
      loadedAllData: false,
      methods: []
    },
    getters: {
      references: state => state.references,
      searchedReferences: state => state.searchedReferences,
      loadedAllData: state => state.loadedAllData,
      methods: state => state.methods
    },
    mutations: {
      setReferences(state, value) {
        state.references = [...state.references, ...value.references]
      },
      setSearchedReferences(state, value){
          state.searchedReferences = state.searchedReferences?.length
              ? [...state.searchedReferences, ...value]
              : value
      },
      deleteReference(state, {id, searchData}){
        state.references = state.references.filter(field => field.id !== id)
        if(Object.values(searchData).some(item => item)) state.searchedReferences = state.searchedReferences.filter(field => field.id !== id)
      },
      addReference(state, {dataReference, searchData}){
        state.references.push(dataReference)
        if(Object.values(searchData).some(item => item)) {
          state.searchedReferences?.length
              ? state.searchedReferences.push(dataReference)
              : state.searchedReferences = [dataReference]
        }
      },
      cleanSearchedReferences(state){
          state.searchedReferences = null
      },
      cleanReferences(state){
          state.references = []
      },
      setLoaded(state, loaded){
        state.loadedAllData = loaded
      },
      setMethods(state, methods){
        state.methods = [...state.methods, ...methods]
      }
    },
    actions: {
      async getReferences({commit, getters, dispatch}, payload) {
        const response = await referencesService.getReferences({offset: payload?.offset || 0})

        if(getters.references.length >= response.response.total - 1) commit('cleanReferences')
        commit('setReferences', response.response)

        const loaded = await requestWithOffset(response.response, payload, 'getReferences', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataFiltered({commit, dispatch}, searchData){
          const isType = false // need to hide type in request
          const keys = Object.keys(searchData)
          const availableKey = keys.find(key => searchData[key] !== '' && key !== 'offset')

          const data = {
              isType,
              type: availableKey,
              search: availableKey ? searchData[availableKey] : '',
              offset: searchData?.offset || 0
          }
          const response = await referencesService.getReferences(data)

        if(!response?.response?.references?.length) {
          dispatch('alert/error', i18n.t('externalSource.alert.searchError'), { root: true })
          return commit('setLoaded', true)
        }
        commit('setSearchedReferences', response.response.references)

        const loaded = await requestWithOffset(response.response, searchData, 'getDataFiltered', dispatch)
        if(loaded) commit('setLoaded', loaded)
      },

      async getDataById({commit, dispatch}, {id, show}) {
        const response = await referencesService.getReferenceById(id)

        commit('setLoaded', true)
        if(!response?.response?.id) return dispatch('alert/error', i18n.t('externalSource.alert.searchError'), { root: true })
        if(!show) {
          commit('setSearchedReferences', [response.response])
          commit('setLoaded', true)
        }

        return response.response
      },

      loadData({commit}){
        commit('setLoaded', false)
      },

        cleanSearchedData({commit}){
          commit('cleanSearchedReferences')
      },

      async createReference({commit, dispatch}, {newData, searchData}){
          const response = await referencesService.createReference(newData)

          if(!response?.response || response?.status === 0) {
              dispatch('alert/error', i18n.t('references.alert.createError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('references.alert.createSuccess'), { root: true })

              const createdReference = await referencesService.getReferenceById(response.response.id)
              commit('addReference', {dataReference: createdReference.response, searchData})

              return response.response.id
          }
      },

      async updateReference({commit, dispatch}, {data, searchData}){
          const response = await referencesService.updateReference(data)

          if(!response?.response || response?.status === 0){
              dispatch('alert/error', i18n.t('references.alert.updateError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('references.alert.updateSuccess'), { root: true })

              commit('deleteReference', {id: data.id, searchData})
              commit('addReference', {dataReference: data, searchData})
          }
      },

      async deleteReference({commit, dispatch}, {id, searchData}){
          const response = await referencesService.deleteReference(id)

          if(!response?.statusCode || response?.statusCode !== 204 && response?.statusCode !== 200){
              dispatch('alert/error', i18n.t('references.alert.deleteError'), { root: true })
          }else{
              dispatch('alert/success', i18n.t('references.alert.deleteSuccess'), { root: true })

              searchData?.id
                ? dispatch('cleanSearchedData')
                : commit('deleteReference', {id, searchData})
          }
      },
      
      async getMethods({commit, dispatch}, payload){
        const response = await referencesService.getMethods(payload?.offset || 0)
        await requestWithOffset(response.response, payload, 'getMethods', dispatch)

        commit('setMethods', response.response.properties)
      },
    }
}