<template>
  <PhoneWrapper node-name="НОДА: ПРИВЯЗАННЫЕ СПОСОБЫ ОПЛАТЫ. ЭКРАН 1: ОСНОВНОЙ">
    <h1 class="phone-screen__title">Recurrent payments</h1>
    <div class="block">
      <div class="card">
        <div class="bank_cards">
          <div class="bank_card plus">
            <span>+</span>
          </div>
          <div class="bank_card active flex flex__column flex_between">
            <div class="bank_card__selected">
              <img src="@/assets/images/success.png" alt="">
            </div>
            <div>
              <div class="flex flex_between">
                <p class="mb-2">Mastercard</p>
                <img class="delete" src="@/assets/icons/payment/delete.svg" alt="delete">
              </div>
              <p class="card__subtitle mb-0">1234 *** *** 5678</p>
            </div>
          </div>
          <div class="bank_card visa">
            <div class="flex flex__column">
              <p class="mb-2">Visa</p>
              <p class="card__subtitle mb-0">*5678</p>
            </div>
          </div>
        </div>
      </div>
      <div class="block">
        <div class="card flex flex_between">
          <p class="card__title">Use by default</p>
          <input type="checkbox">
        </div>
        <div class="card">
          <p class="card__title card__title_bold">
            Regular autopayments
          </p>
        </div>
        <div v-for="(item, i) in autopayments" :key="i">
            <p class="mb-1">{{item.title}}</p>
            <p class="card__subtitle mb-0">{{item.subtitle}}</p>
        </div>
        <div class="card">
          <div class="flex flex_between">
            <p class="card__title mb-5">Set up regular autopayments</p>
            <span>&#62;</span>
          </div>
          <p class="description mb-0">If there is not sufficient balance to cover EMI on the card by last day of the month, we'll apply Digital mandate and linked account</p>
        </div>
        <nextButton color="danger" btn_name="Delete autopayment" />
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import nextButton from "@/components/nodes/creditLine/widgets/payment/nextButton.vue";
export default {
  name: "vueRecurrentPayments",
  components: {
    PhoneWrapper,
    nextButton
  },
  props: {
    autopayments: {
      default: () => [
        {
          title: 'Payment amount:',
          subtitle: 'According to your payment schedule'
        },{
          title: 'Frequency:',
          subtitle: 'Monthly'
        },{
          title: 'Date of the first payment:',
          subtitle: '21.09.2022'
        },{
          title: 'Date of the next payment:',
          subtitle: '21.10.2022'
        },
      ],
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
.bank_cards{
  position: relative;
  display: flex;
  gap: 16px;
  left: -140px;
}
.bank_card{
  background: #EDEDED;
  min-width: 180px;
  max-width: 180px;
  height: 125px;
  border-radius: 16px;
  padding: 10px 15px;
  &.active{
    border: 1px solid #000000;
  }
  &.plus{
    font-size: 50px;
    font-weight: 200;
    color: gray;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  &.visa{
    display: flex;
    align-items: end;
  }
  &__selected{
    display: flex;
    justify-content: end;
    img{
      width: 24px;
    }
  }
}
  .description{
    line-height: 18px;
  }

</style>