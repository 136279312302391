<template>
  <!-- ? color for controls -->
  <div class="node-screen-edit" ref="nodeScreenEdit">
    <v-expansion-panels 
      flat
      v-model="openPanel"  
      multiple
      >
      <v-expansion-panel
        v-for="item,i in currentScreen.nodeFields"
        :key="item.id"
        v-show="item.nodeType !== 'widget'"
        @click="toggleField(item, i)"
      >
        <!-- Не отображаем редактирование лейблов, потому что пока что отказались от идеи делать лейбл отдельно от инпутов -->
        <v-expansion-panel-header
          v-if="!item.name.includes('_label')"
        >
          <div class="d-flex align-center justify-space-between">
            <span>
              {{ item.name }}
            </span>
            <div>
              <span class="mr-5">
                {{ item.id }}
              </span>

              <v-icon
                v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('visibility')"
                class="d-inline dark mr-2"
                @click.stop="item.properties.visibility = !item.properties.visibility"
              >
                {{ item.properties.visibility ? 'mdi-eye' : 'mdi-eye-off' }}
              </v-icon>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="!item.name.includes('_label')"
        >
          <template v-if="item.nodeType === 'block.bullet' ||  item.nodeType === 'dropDownList' ||  item.nodeType.includes('radiobutton')">
            <label class="node-screen-edit__label">{{ item.nodeType }}</label>
            <MainList :list="item" />
          </template>
          <template 
            v-if="item.nodeType.includes('block') || item.nodeType.includes('webTooltip') || item.nodeType.includes('webProgressBar') || item.nodeType.includes('checkbox') || item.nodeType.includes('button')"
          >
            <label class="node-screen-edit__label">{{ $t('screenEditor.text') }}</label>

            <text-editor-quill
              v-if="openPanel[0] === i"
              v-model="editorScreen.nodeFields.find(field => field.name === item.name).properties.text"
              :parentWidth="$refs.nodeScreenEdit.clientWidth"
              @input="editText($event, item.name, 'text')"
            />

            <template v-if="item.properties.hasOwnProperty('imageUrl')">
              <label class="node-screen-edit__label">{{ $t('screenEditor.icon') }}</label>
              <MainFileUpload
                @selectImg="selectImg(item.name, ...arguments)"
              />
            </template>
          </template>
          <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('label')"
          >
            <label class="node-screen-edit__label">{{ $t('screenEditor.label') }}</label>
            <quill-editor
                v-model="editorScreen.nodeFields.find(field => field.name === item.name).properties.label"
                :options="inputEditorOption"
                @change="editText($event, item.name, 'label')"
            />
          </template>
          <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('length')"
          >
            <label class="node-screen-edit__label">{{ $t('screenEditor.countSymbol') }}</label>
            <v-text-field
              type="number"
              v-model="item.properties.length"
              :placeholder="$t('screenEditor.countSymbol')"
              outlined
              dense
            />
          </template>
          <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('splitCount')"
          >
            <label class="node-screen-edit__label">{{ $t('screenEditor.countCells') }}</label>
            <v-text-field
              type="number"
              v-model="item.properties.splitCount"
              :placeholder="$t('screenEditor.countCells')"
              outlined
              dense
            />
          </template>
          <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('mask')"
          >
            <label class="node-screen-edit__label">{{ $t('screenEditor.mask') }}</label>
            <quill-editor
                v-model="editorScreen.nodeFields.find(field => field.name === item.name).properties.mask"
                :options="inputEditorOption"
                @change="editText($event, item.name, 'mask')"
            />
          </template>
          <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('placeholder')"
          >
            <label class="node-screen-edit__label">{{ $t('screenEditor.placeholder') }}</label>
            <quill-editor
                v-model="editorScreen.nodeFields.find(field => field.name === item.name).properties.placeholder"
                :options="inputEditorOption"
                @change="editText($event, item.name, 'placeholder')"
            />
          </template>
          <!-- Костыльное скрытие настроек для телефона на втором экране регистрации -->
          <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('align') 
            && !(item.nodeType.includes('block') || item.nodeType.includes('webTooltip') || item.nodeType.includes('webProgressBar') || item.nodeType.includes('checkbox') || item.nodeType.includes('button'))"
          >
            <!-- label for & id -->
            <label class="node-screen-edit__label">{{ $t('screenEditor.position') }}</label>
            <v-select
              v-model="item.properties.align"
              :items="alignItems"
              item-value="value"
              item-text="key"
              outlined
              dense
            />
          </template>
          <template
            v-if="item.nodeType === 'image'"
          >
            <!-- ? label name -->
            <label class="node-screen-edit__label">{{ $t('screenEditor.image') }}</label>
            <MainFileUpload
              @selectImg="selectImg(item.name, ...arguments)"
            />
          </template>
          <!-- Color component for fields it have color -->
          <template v-if="item.properties !== undefined">
            <div 
              v-for="color,i in Object.keys(item.properties)
                .map(item => {
                  if(item === 'backgroundColor' || item === 'background') return item
                  return ''
                }).filter(item => item !== '')" 
              :key="i"
              class="my-3"
            >
              <v-btn v-if="item.properties[color] === ''" color="deep-purple accent-2" class="white--text" small @click="changeShowColor(item.properties, [color])" >{{ $t('gradientColor.setBgColor') }}</v-btn>
              <v-btn v-else color="grey" class="white--text mb-4" small @click="changeShowColor(item.properties, [color])" >{{ $t('gradientColor.removeBgColor') }}</v-btn>
              <template v-if="item.properties[color] !== ''">
                <div class="d-flex align-center">
                  <p class="node-screen-edit__label">{{ $t('gradientColor.bgColor') }}</p>
                  <span class="node-screen-edit__inputs">
                    <span :class="{'gradient__active' : !isGradient([color], item.id)}" class="node-screen-edit__input" @click="setColorMode([color], 'mono', item.id)">{{ $t('gradientColor.mono') }}</span>
                    <span :class="{'gradient__active' : isGradient([color], item.id)}" class="node-screen-edit__input" @click="setColorMode([color], 'gradient', item.id)">{{ $t('gradientColor.gradient') }}</span>
                  </span>
                </div>
                <gradientColors v-if="isGradient([color], item.id)" v-model="item.properties[color]" :type="color" />
                <v-color-picker v-else v-model="item.properties[color]" hide-inputs :label="color" />
              </template>
            </div>
          </template>

          <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('fontSize')"
          >
            <label class="node-screen-edit__label">{{ $t('screenEditor.fontSize') }}</label>
            <v-slider
              :max="50"
              :min="5"
              thumb-label
              v-model="item.properties.fontSize"
            ></v-slider>
          </template>
          <v-checkbox
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('underline') && item.nodeType.hasOwnProperty('button')"
            v-model="item.properties.underline"
            :label="$t('screenEditor.underline')"
          />
          <!-- <template
            v-if="typeof item.properties !== 'undefined' && item.properties.hasOwnProperty('minimum') && item.properties.hasOwnProperty('maximum')"
          >
            <label class="node-screen-edit__label">Минимальное значение</label>
            <input type="number" v-model="item.properties.minimum">
            
            <label class="node-screen-edit__label">Максимальное значение</label>
            <input type="number" v-model="item.properties.maximum"> 
          </template> -->
        </v-expansion-panel-content>
        <v-divider class="mx-6"></v-divider>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="typeof currentScreen.properties !== 'undefined' && currentScreen.properties.hasOwnProperty('background')"
      >
        <v-expansion-panel-header>
          {{ $t('screenEditor.bgColor') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p class="node-screen-edit__label">{{ $t('screenEditor.selectColor') }}</p>
          <v-color-picker
            v-model="currentScreen.properties.background"
          />
        </v-expansion-panel-content>
        <v-divider class="mx-6"></v-divider>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="d-flex mx-6 my-4">
      <v-spacer></v-spacer>
      <v-btn
        color="grey"
        class="white--text mr-2"
        small
        @click.native="resetScreen"
      >{{ $t('reset') }}</v-btn>

      <v-btn
        color="deep-purple accent-2"
        class="white--text"
        small
        @click.native="saveScreen"
      >{{ $t('save') }}</v-btn>
    </div>
    <ConfirmModal
      ref="confirm"
    />
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import ConfirmModal from "@/components/utils/ConfirmModal";
import gradientColors from "@/components/UI/gradientColor/gradientColors.vue";
import gradientMixin from "@/mixins/gradientMixin";

import MainList from "@/components/UI/MainList.vue"

import textEditorQuill from '../UI/textEditor/textEditorQuill'

export default {
  name: "NodeScreenEdit",
  components: {
    ConfirmModal,
    gradientColors,
    quillEditor,
    MainList,
    textEditorQuill
  },
  mixins: [gradientMixin],
  data () {
    return {
      currentScreen: null,
      editorScreen: null,
      alignItems: [
        {
          key: this.$t('screenEditor.align.center'),
          value: 'center'
        },
        {
          key: this.$t('screenEditor.align.left'),
          value: 'left'
        },
        {
          key: this.$t('screenEditor.align.right'),
          value: 'right'
        },
      ],
      tempMainStyles: {},
      inputEditorOption: {
        modules: {
          toolbar: false,
        }
      },
      chosenTextStyle: null,
      openPanel: [],
      changeLocal: false,
    }
  },
  created () {
    this.render();
  },
  computed: {
    colorTextSwatches() {
      return this.$store.getters["app/colorTextSwatches"];
    },
    textStyles() {
      return this.$store.getters["app/textStyles"];
    },
    openedContentPanel(){
      return this.$store.getters['node/currentField']
    }
  },
  watch:{
    // hide/show content panel with click on preview
    openedContentPanel(newField, pastField){
      if(this.changeLocal) return this.changeLocal = false

      const name = newField ?? pastField
      const field = this.currentScreen.nodeFields.find(field => field.name === name)
      const indexField = this.currentScreen.nodeFields.indexOf(field)

      this.toggleVisibilityContent(indexField)
    }
  },
  methods: {
    // hide/show content panel
    toggleVisibilityContent(index){
      if(this.openPanel.some(field => field === index)) return this.openPanel = []

      this.openPanel = []
      this.openPanel.push(index)
    },
    render () {
      this.currentScreen = this.$store.getters["node/currentScreen"];
      this.editorScreen = JSON.parse(JSON.stringify(this.currentScreen));
    },
    toggleField(field, index) {
        // open only one field 
        this.openPanel = Object.values(this.openPanel).filter(item => item === index)
        this.changeLocal = true
       
        const str = this.editorScreen.nodeFields.find(nodeField => nodeField.name === field.name).properties.text
        const regex = /class='([^']*)'/g
        const classNames = []
        let match

        while ((match = regex.exec(str))) {
          classNames.push(match[1]);
        }

        this.chosenTextStyle = localStorage.getItem(field.name) ? localStorage.getItem(field.name) : classNames[0]
        if(this.chosenTextStyle !== localStorage.getItem(field.name)) localStorage.setItem(field.name, classNames[0]) 

        this.$store.dispatch('node/setCurrentField', field.name)
        this.$set(this.tempMainStyles, `field-style-${field.name}`, this.chosenTextStyle)
    },
    editText(e, name, prop) {
      this.currentScreen.nodeFields.map(field => {
        if (field.name === name) {
          field.properties[prop] = this.editorScreen.nodeFields.find(f => f.name === name).properties[prop];
        }
        return field;
      })
      if (localStorage.getItem(`field-style-${name}`) || this.tempMainStyles?.[`field-style-${name}`]) {
        this.changeTextStyle(name);
      }
    },
    changeTextStyle(fieldName) {
      this.$set(this.tempMainStyles, `field-style-${fieldName}`, this.chosenTextStyle);
     this.currentScreen.nodeFields.map(field => {
        if (field.name === fieldName) {
          if (field.properties.text.includes("<p")) {
            const sliceId = field.properties.text.search(/>/i);
            field.properties.text = `<p class='${this.chosenTextStyle}'>${field.properties.text.slice(sliceId + 1)}`;
          }
        }
      });
     this.$forceUpdate();
    },
    fileInput (file, preview, imgName) {
      if (file) {
        this.currentScreen.nodeFields.map(field => {
          if (field.name === imgName) {
            field.properties.url = file[0];
          }
          return field;
        })
      }
    },
    saveScreen() {
      this.$emit('save', this.currentScreen, this.tempMainStyles)
      this.tempMainStyles = {};
      this.$forceUpdate();
    },
    async resetScreen () {
      if (await this.$refs.confirm.open(
            "Сброс настроек экрана",
            "Вы уверены, что хотите поставить дефолтные настройки экрана?"
          )) {
        this.$emit('reset', () => {
          this.render();
        })
      }
    },
    selectImg (fieldName, file) {
      this.currentScreen.nodeFields.map(field => {
        if (field.name === fieldName) {
          // for images only  NEED TO REFACTOR WHEN WILL BE OTHER FILE INPUTS
          field.properties.imageUrl = file;
        }
        return field;
      })
      this.$forceUpdate();
    },
    changeShowColor(property, name){
      property[name] === ''
        ? property[name] = '#ff0000ff'
        : property[name] = ''
    },
  },
  beforeDestroy() {
    if (Object.keys(this.tempMainStyles)) {
      Object.keys(this.tempMainStyles).forEach(key => {
        localStorage.removeItem(key);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.node-screen-edit {
  &__label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
    margin-right: 8px;
  }
  &__input {
    padding: 8px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
  }
  &__inputs{
    border: .5px solid #E2E8F0;
    border-radius: 8px;
    padding-top: 3px;
    padding-bottom: 6px;
    margin-bottom: 8px;
  }
}

.gradient__active{
  background: #E6E6E6;
  font-size: 12px;
  font-weight: 700;
  }
</style>
