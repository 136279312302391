<template>
  <PhoneWrapper node-name="НОДА: АНКЕТА-1. ЭКРАН: АДРЕС ПРОЖИВАНИЯ.">
    <div class="phone-screen__context address-info">
      <ProfileHeader title="Address"/>

      <div class="address-info__header">
        <img width="40px" height="40px" src="@/assets/icons/transh/tab-bar-icon.svg" />
        <p class="phone-screen__title ml-6">{{ title }}</p>
      </div>

      <bpmInput placeholder="Pincode" />
      <p class="phone-screen__small-text">As per your current residence Pincode</p>

      <div class="d-flex justify-space-between mt-4">
        <bpmInput placeholder="House/Flat/Building" />
        <bpmCheckbox label="Don't have a number" />
      </div>

      <bpmInput placeholder="Street Name" class="mt-4" />

      <bpmInput placeholder="Locality/Area" class="mt-4" />

      <bpmButton class="mt-auto mb-10" style="width: 100%">
        <template v-slot:content><span style="color: white">Confirm</span></template>
      </bpmButton>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "AddressInfo",
  components: {PhoneWrapper, ProfileHeader},
  props: ['title']
}
</script>

<style lang="scss" scoped>
.address-info {
  &__header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  div[class*="bpm-checkbox"] {
    width: auto;
    margin-left: 20px;
  }
}
</style>