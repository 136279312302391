<template>
  <div class="after-grace d-none">
    <div class="after-grace__inner">
      <div class="after-grace__block">
        <div class="d-flex align-center">
          <img src="@/assets/images/transh/img-preview-circle.svg" width="40" height="40">
          <div class="phone-screen__text ml-3">Line of credit</div>
        </div>
        <img src="@/assets/icons/transh/right_arrow.svg" width="15" height="9">
      </div>

      <div class="after-grace__block">
        <div class="d-flex align-center">
          <img src="@/assets/images/transh/img-preview-circle.svg" class="hidden" width="40" height="40">
          <div class="ml-3">
            <p class="phone-screen__subtitle">20 Dec</p>
            <div class="phone-screen__text after-grace__text">End Grace period</div>
          </div>
        </div>
        <p class="phone-screen__title">₹40,000.<span class="phone-screen__text phone-screen__text_grey">00</span></p>
      </div>
    </div>

    <div class="after-grace__line">
      <p class="phone-screen__text"><b>After the Grace period</b></p>
    </div>

    <div class="after-grace__inner pt-0">
      <div class="after-grace__block mt-3">
        <div class="d-flex align-center">
          <img src="@/assets/images/transh/img-preview-circle.svg" class="hidden" width="40" height="40">
          <div class="ml-3">
            <p class="phone-screen__subtitle">31 Dec</p>
            <div class="phone-screen__text after-grace__text">Next Due date</div>
          </div>
        </div>
        <p class="phone-screen__title">₹7,546.<span class="phone-screen__text phone-screen__text_grey">26</span></p>
      </div>
    </div>

    <hr class="mb-3">

    <div class="after-grace__inner pt-0">
      <div class="after-grace__block">
        <div class="d-flex align-center">
          <img src="@/assets/images/transh/img-preview-circle.svg" width="40" height="40">
          <div class="phone-screen__text after-grace__text ml-3">Total outstanding</div>
        </div>
        <p class="phone-screen__subtitle">40,000<span class="phone-screen__text phone-screen__text_grey">.00</span></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AfterGrace",
}
</script>

<style lang="scss" scoped>
.after-grace {
  width: 310px;
  border-radius: 8px;
  background: #fff;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
  hr {
    height: 1px;
    width: 100%;
    background: #B51ACE;
  }
  &__inner {
    padding: 16px;
  }
  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__text {
    font-size: 12px;
  }
  &__line {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #B51ACE;
    b {
      color: #fff;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      border: 16px solid transparent;
      border-top: 20px solid #B51ACE;
      bottom: -30px;
      right: 65px;
    }
  }
}

.hidden {
  visibility: hidden;
}
</style>