<template>
  <div class="phone-screen__header">
    <v-img
        lazy-src="@/assets/icons/transh/avatar.svg"
        max-height="32"
        max-width="32"
        src="@/assets/images/logo.png"
    />
    <bpmInput
        placeholder="Search"
        size="small"
    />
    <v-img
        lazy-src="@/assets/icons/transh/notif-icon.svg"
        max-height="20"
        max-width="17"
        src="@/assets/icons/transh/notif-icon.svg"
    />
    <v-img
        lazy-src="@/assets/icons/transh/avatar.svg"
        max-height="32"
        max-width="32"
        src="@/assets/icons/transh/avatar.svg"
    />
  </div>
</template>

<script>
export default {
  name: "PhoneHeader",
}
</script>

<style lang="scss" scoped>
  .phone-screen {
    &__header {
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
    }
  }
</style>