
const justifyAligns = {
  center: 'center',
  left: 'flex-start',
  right: 'flex-end',
  start: 'flex-start',
  end: 'flex-end',
}

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    styles() {
      return {
        'text-align': this.item.properties.align,
        'color': this.item.properties.color ? this.item.properties.color : '#000',
        'background': this.item.properties.background ? this.item.properties.background : 'transparent',
      }
    },
    wrapperStyles() {
      return {
        'justify-content': justifyAligns[this.item.properties.align]
      }
    }
  }
}