<template> 
      <div>
        <table class="calendar">
          <thead>
              <tr class="calendar-head">
                  <th class="calendar-month" colspan="2">
                      2022 - 2033
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
                  </th>
                  <th class="calendar-month__select" colspan="1">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
                  </th>
              </tr>
          </thead>
          <tbody class="calendar-years">
              <tr>
                  <td>2022</td>
                  <td>2023</td>
                  <td>2024</td>
              </tr>
              <tr>
                  <td>2025</td>
                  <td>2026</td>
                  <td>2027</td>
              </tr>
              <tr>
                  <td class="active-year">2028</td>
                  <td>2029</td>
                  <td>2030</td>
              </tr>
              <tr>
                  <td>2031</td>
                  <td>2032</td>
                  <td>2033</td>
              </tr>
          </tbody>
        </table>
      </div>
</template>

<script>
  export default {
    name: "WidgetCalendar",
  }
</script>

<style lang="scss" scoped>
    .calendar {
        padding: 20px;
        background: #fff;
        border-radius: 13px;

        &-head th{
            text-align: center;
            font-weight: 600;
            font-size: 20px;
            padding-left: 8px;
            padding-bottom: 15px;
            svg {
                height: 18px;
                fill:gray;
            }
        }
        &-month{
            text-align: left !important;
            svg{
                height: 13px !important;
                margin: 0 0 2px 5px;
                fill:gray;
            }
            &__select{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
            }
        }
        &-years td {
            text-align: center;
            font-weight: 400;
            font-size: 20px;
            padding: 8px 30px;

        }
    }
    .active-year{
        background: #b51bce;
        border-radius: 30px;
        color: #fff;
    }
</style>