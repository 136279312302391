export const dictionary = {
  namespaced: true,
  state: {
    dictionaries: {
      marital_status: [
        {"code":"1","value":"Холост/не замужем"},
        {"code":"2","value":"Женат/замужем"},
        {"code":"3","value":"Вдовец/вдова"},
        {"code":"4","value":"Разведен (а)"},
        {"code":"5","value":"Гражданский брак"}],
      education: [
        {"code":"1","value":"Неполное среднее"},
        {"code":"2","value":"Среднее"},
        {"code":"3","value":"Неполное высшее"},
        {"code":"4","value":"Высшее"},
        {"code":"5","value":"Ученая степень"},
        {"code":"6","value":"Два и более высших"},
        {"code":"7","value":"Среднее специальное"}]
    }
  },

  getters: {
    dictionaries: state => state.dictionaries
  }
}