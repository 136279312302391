<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    class="main-file-upload"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        type="text"
        :placeholder="$t('modal.clickToSelectImg')"
        outlined
        dense
        readonly
      />
    </template>
    <SpinnerLoader
      v-if="loading"
    />
    <v-card v-else class="rounded-lg">
      <v-card-title class="text-h5">{{ $t('modal.selectImg') }}</v-card-title>
      <v-card-text class="text-body-1">{{ $t('modal.selectMediaFromList') }}</v-card-text>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <div class="main-file-upload__previews">
          <div
            v-for="file in files"
            :key="file.id"
            class="main-file-upload__preview"
            @click="selectImage(file.path)"
          >
            <MainImage
              :id="`image-upload__preview_${file.id}`"
              max-width="100px"
              max-height="100px"
              :src="file.path"
            />
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          class="body-2 font-weight-bold mr-2"
          @click.native="close"
        >{{ $t('cancel') }}</v-btn>
        <MainUpload
          :multiple="false"
          :label="$t('modal.uploadNewImg')"
          @input="fileInput"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MainFileUpload",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      loading: false
    }
  },
  computed: {
    files () {
      return this.$store.getters["app/files"];
    }
  },
  methods: {
    async fileInput (files) {
      this.loading = true;
      await this.$store.dispatch('app/addFiles', files);
      this.loading = false;
    },
    selectImage (img) {
      this.$emit('selectImg', img);
      this.dialog = false;
    },
    close () {
      this.dialog = false;
    }
  }
}
</script>

<style scoped lang="scss">
.main-file-upload {
  &__previews {
    display: flex;
    flex-wrap: wrap;
  }
  &__preview {
    cursor: pointer;
    flex: 0 1 33.3333%;
  }
}
</style>
