<template>
  <PhoneWrapper node-name="НОДА: ДОСРОЧНОЕ ПОГАШЕНИЕ.">
    <h1 class="phone-screen__title">Early repayment</h1>
    <div class="block">
      <div class="card">
        <p class="card__title">Enter the amount</p>
        <template><BpmInput type="number" v-model="amount" class="input_amount" /></template>
        <template>
          <BpmRange :rangeValues='[rangeMin, rangeMax]' label="Диапазон" v-model="amount" class="range_custom" />
        </template>
        <div class="card">
          <div class="flex flex_between gray_text">
            <p class="mb-0">From ₹{{rangeMin}}</p>
            <p class="mb-0">To ₹{{rangeMax}} <br> total payable</p>
          </div>
        </div>
        <div class="card payment_details">
          <p class="card__title mb-4">Payment details</p>
          <div class="flex flex_between">
            <p>Principal debt</p>
            <p>₹59,341.00</p>
          </div>
          <div class="flex flex_between">
            <p>Interest</p>
            <p>₹5,979.12</p>
          </div>
          <div class="flex flex_between">
            <p>Fees</p>
            <p>₹3,636.00</p>
          </div>
          <div class="flex flex_between">
            <p>Early repayment fee 4%:</p>
            <p>₹2,700</p>
          </div>
        </div>
        <div class="next_emi_details mb-3">
          <template v-if="next_emi_details">
            <div class="info_block mb-3">
              <p><span>Next EMI details</span></p>
              <p>Current EMI: <span>₹7,477.26</span></p>
              <p>New EMI: <span>₹4,689.22</span></p>
            </div>
            <div class="flex flex_between">
              <p class="card__title">Next Due date:</p>
              <p class="gray_text">31 Jan’ 23</p>
            </div>
            <div class="flex flex_between">
              <p class="card__title">New EMI plan</p>
              <span>&#62;</span>
            </div>
          </template>
        </div>
        <div class="card total_amount">
          <p class="card__title">The total amount of Payment:</p>
          <p>₹71,656.12</p>
        </div>
      </div>
    </div>
    <nextButton btn_name="Confirm" />
  </PhoneWrapper>

</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import nextButton from "@/components/nodes/creditLine/widgets/payment/nextButton.vue";
export default {
  name: "vueEarlyRepayment",
  components: {
    PhoneWrapper,
    nextButton,
  },
  data(){
    return{
      amount:68956
    }
  },
  props: {
    rangeMin: {
      default: 10000,
      type: Number
    },
    rangeMax: {
      default: 70000,
      type: Number
    },
    next_emi_details: {
      default: false,
      type: Boolean
    }
  }
}
</script>
<style lang="scss">
.range_custom{
  label{
    display: none ;
  }
  span{
    display: none;
  }
  input{
    background-image: linear-gradient(#525559, #525559)
  }
}
.gray_text{
  color: #7F7F7F;
  font-size: 14px;

  &:last-child{
    text-align: right;
  }
}

.input_amount{
  input{
    font-weight: 600;
    font-size: 24px;
  }
}
.payment_details{
  p{
    margin-bottom: 8px;
    font-size: 14px;
  }
}
.total_amount{
  p{
    &:last-child{
      font-weight: 600;
      font-size: 24px;
    }
  }
}
.next_emi_details{
  min-height: 200px;
  .info_block{
    background: #FFDCFE;
    padding: 12px;
    width: calc(100% + 68px);
    margin-left: -34px;
    span{
      font-weight: 600;
    }
    p{
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
</style>