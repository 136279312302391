<template>
  <PhoneWrapper node-name="НОДА: НАСТРОЙКИ БЕЗОПАСНОСТИ. ЭКРАН 1: ВЫБОР НАСТРОЙКИ. ">
    <div class="phone-screen__context profile-menu">
      <ProfileHeader title="Security"/>

      <div class="profile-menu__block">
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Change login</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Change password</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Change passcode</p>
          </div>
          <img width="12" height="12" src="@/assets/icons/transh/right_arrow.svg"/>
        </div>
        <div class="profile-menu__item">
          <div class="profile-menu__column">
            <img width="24px" height="24px" src="@/assets/icons/transh/tab-bar-icon.svg" />
            <p class="phone-screen__subtitle phone-screen__subtitle_tin">Face ID/Touch ID</p>
          </div>
          <bpmCheckbox class="w-auto" />
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";

export default {
  name: "SecurityMenu",
  components: {ProfileHeader, PhoneWrapper},
}
</script>


