<template>
  <PhoneWrapper node-name="НОДА: НАСТРОЙКИ ПРИЛОЖЕНИЯ. ЭКРАН 2: ЯЗЫК. ">
    <div class="phone-screen__context lang">
      <ProfileHeader title="Language"/>

      <p class="phone-screen__subtitle phone-screen__subtitle_tin mt-8">Choose language</p>

      <div class="lang__block">
        <div class="lang__item">
          <p class="phone-screen__subtitle">English</p>
          <bpmCheckbox/>
        </div>
        <div class="lang__item">
          <p class="phone-screen__subtitle">Русский</p>
          <bpmCheckbox/>
        </div>
      </div>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "ChooseLang",
  components: {PhoneWrapper, ProfileHeader}
}
</script>

<style lang="scss" scoped>
.lang {
  &__block {
    margin-top: 26px;
  }

  &__item {
    padding: 10px 5px;
    background: #F2F3F7;
    border: 1px solid rgba(188, 195, 208, 0.5);
    border-radius: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    div[class*="bpm-checkbox"] {
      width: auto;
    }
  }
}
</style>