<template>
  <form class='chat-box' @submit.prevent>
    <v-text-field
        hide-details
        :error-messages="textError"
        v-model="text"
        @keydown.enter="onSubmit"
        :disabled="disabled"
    >
      <v-img
          slot="prepend"
          :src="require('@/assets/images/ChatGPT_logo.svg.png')"
          height="40px"
          width="40px"
          contain
      >

      </v-img>
      <v-btn
          color="primary"
          slot="append"
          fab
          small
          :disabled="disabled"
          icon
          @click="onSubmit"
      >
        <v-icon>
          mdi-send
        </v-icon>
      </v-btn>
    </v-text-field>
  </form>
</template>

<script>
export default {
  name: "ChatBox",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      text: '',
      textError: '',
    }
  },
  methods: {
    /**
     * fires an event which we will handle in the parent component
     * */
    onSubmit() {
      if (!!this.text) {
        if (!this.disabled) {
          this.textError = '';
          this.$emit("submit", this.text);
          this.text = ''
        }
        return;
      }
      this.textError = "Field can't be empty";
    }
  },
  watch: {
    text(new_val) {
      if (!!new_val) this.textError = '';
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-box {
  width: 100%;
  display: flex;
  padding: 10px;
}

.v-text-field {
  padding-top: 0;
  margin-top: 0;
  width: min(100%, 20rem);
  flex-grow: 1;
}

button:disabled {
  opacity: 0.5;
}
</style>