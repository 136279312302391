<template>
  <div>
    <div class="container">
      Верстка экранов профиля
      <div class="node-edit__views">
        <SetCommunication />
        <InviteFriends />
        <HintMenu />
        <StatementsDownload />
        <StatementsPeriod />
        <StatementsMenu />
        <SetPassword />
        <ChangePassword />
        <ChangeLogin />
        <SecurityMenu />
        <ChooseTheme />
        <ChooseLang />
        <ChooseSetting />
        <FeedBackMessage />
        <ProfileMenu />
        <UniversalVerify
            button-text="Verify"
            placeholder="Сode word"
            screen-title="Enter a new code word"
            header-title="Change code word"
            node-name="НОДА: АУТЕНТИФИКАЦИЯ. ПРЕСЕТ: ЗАДАНИЕ КОДОВОГО СЛОВА."
        />
        <UniversalVerify
            button-text="Next"
            placeholder="Enter current code word"
            screen-title="To change enter current code word"
            header-title="Change code word"
            node-name="НОДА: АУТЕНТИФИКАЦИЯ. ПРЕСЕТ: ПРОВЕРКА КОДОВОГО СЛОВА."
        />
        <UniversalVerify
            button-text="Verify e-mail"
            placeholder="e-mail"
            screen-title="Verify your new e-mail"
            screen-subtitle="Please, enter your e-mail (required to share loan agreement)"
            header-title="E-mail"
            node-name="НОДА: ИДЕНТИФИКАЦИЯ. ПРЕСЕТ: E-mail."
        />
        <UniversalVerify
            button-text="Next"
            placeholder=""
            screen-title="To change enter current e-mail"
            header-title="E-mail"
            node-name="НОДА: ИДЕНТИФИКАЦИЯ. ПРЕСЕТ: E-mail."
        />
        <AddressInfo title="Residence address"/>
        <AddressInfo title="Address of registration"/>
        <ProfileAddress />
        <IdentifyPhone />
        <PersonalSettings/>
        <PersonalSettingEditName/>
        <PersonalSettingsPhoto />
      </div>
    </div>
  </div>
</template>

<script>
import PersonalSettings from "@/components/nodes/creditLine/profile/PersonalSettings.vue";
import PersonalSettingEditName from "@/components/nodes/creditLine/profile/PersonalSettingsEditName.vue";
import PersonalSettingsPhoto from "@/components/nodes/creditLine/profile/PersonalSettingsPhoto.vue";
import IdentifyPhone from "@/components/nodes/creditLine/profile/IdentifyPhone.vue";
import ProfileAddress from "@/components/nodes/creditLine/profile/ProfileAddress.vue";
import AddressInfo from "@/components/nodes/creditLine/profile/AddressInfo.vue";
import UniversalVerify from "@/components/nodes/creditLine/profile/UniversalVerify.vue";
import ProfileMenu from "@/components/nodes/creditLine/profile/ProfileMenu.vue";
import FeedBackMessage from "@/components/nodes/creditLine/profile/FeedBackMessage.vue";
import ChooseSetting from "@/components/nodes/creditLine/profile/ChooseSetting.vue";
import ChooseLang from "@/components/nodes/creditLine/profile/ChooseLang.vue";
import ChooseTheme from "@/components/nodes/creditLine/profile/ChooseTheme.vue";
import SecurityMenu from "@/components/nodes/creditLine/profile/SecurityMenu.vue";
import ChangeLogin from "@/components/nodes/creditLine/profile/ChangeLogin.vue";
import ChangePassword from "@/components/nodes/creditLine/profile/ChangePassword.vue";
import SetPassword from "@/components/nodes/creditLine/profile/SetPassword.vue";
import StatementsMenu from "@/components/nodes/creditLine/profile/StatementsMenu.vue";
import StatementsPeriod from "@/components/nodes/creditLine/profile/StatementsPeriod.vue";
import StatementsDownload from "@/components/nodes/creditLine/profile/StatementsDownload.vue";
import HintMenu from "@/components/nodes/creditLine/profile/HintMenu.vue";
import InviteFriends from "@/components/nodes/creditLine/profile/InviteFriends.vue";
import SetCommunication from "@/components/nodes/creditLine/profile/SetCommunication.vue";

export default {
  name: "ProfileUx",
  components: {
    SetCommunication,
    InviteFriends,
    HintMenu,
    StatementsDownload,
    StatementsPeriod,
    StatementsMenu,
    SetPassword,
    ChangePassword,
    ChangeLogin,
    SecurityMenu,
    ChooseTheme,
    ChooseLang,
    ChooseSetting,
    FeedBackMessage,
    ProfileMenu,
    UniversalVerify,
    AddressInfo,
    ProfileAddress,
    IdentifyPhone, PersonalSettingsPhoto, PersonalSettingEditName, PersonalSettings},
}
</script>