<template>
    <div class="block">
        <div class="card flex">
            <p class="card__title card__title_bold mb-0">Payment options</p>
        </div>
        <div class="card">
            <div v-for="item,i in options" :key="i" class="card__content payment_options flex">
              <p class="mb-0 gray_rectangle">
              </p>
                <div class="text flex flex__content_center flex__column">
                    <p class="mb-0" >{{ item.title }}</p>
                    <p v-if="item.sum" class="mb-0" >₹ {{ item.sum }}</p>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        title: {
            default: 'Overdue payment amount',
            type: String
        },
        fines: {
            default: 9313,
            type: Number
        },
        date: {
            default: '31.10.2022',
            type: String
        },
        sum: {
            default: 8320,
            type: Number
        },
        options: {
            default: () => [
                {
                    'title': 'My balance',
                    'sum':  4500000
                },
                {
                    'title': 'UPI',
                },
                {
                    'title': 'Card',
                },
                {
                    'title': 'Net banking',
                },
            ],
            type: Array
        }
    }
  
  }
</script>

<style>
.gray_rectangle{
  width: 72px;
  height: 48px;
  background: #AFAFAF;
  border-radius: 8px;
}
</style>
  