import {actionsService} from "@/services/actions.service";

export const actions = {
  namespaced: true,
  state: {
    actions: [],
  },
  getters: {
    actions: state => state.actions,
  },
  mutations: {
    setActions(state, actions) {
      state.actions = actions;
    },
  },
  actions: {
    async setActions({commit}){
      await actionsService.getActions()
        .then((actions) => {
          commit('setActions', actions.response.actions);
        })
    }
  }
}
