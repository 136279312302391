<template>
    <v-row class="d-flex">
      <v-col :cols="tableWidth">
        <SearchComponent
            :searchData="['id', 'name']"
            prefix="pageBuilder"
            @searchInput="searchInput"
            ref="searchComponent"
        />

    <v-card class="mt-5 pb-6 rounded-xl pa-6">
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="showCreate"
        class="btn-add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>


      <h2>{{ $t('variables.variables') }}</h2>
      <v-data-table
        :headers="tableHeaders"
        :loading="!loading"
        :loading-text="$t('multilang.load')"
        :items="variables"
        item-key="id"
        density="compact"
        :items-per-page="tableItemsPerPage"
        :footer-props="tableFooterProps"
        class="mt-4"
        :sort-by="sortTableBy"
        :sort-desc="sortTableDesc"
        :custom-sort="customSort"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ item.id }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.fieldName`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ item.fieldName }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.fieldName)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.personalData`]="{ item }">
          <div v-if="typeof item.personalData === 'boolean'" class="table-item" @click="enterSelect(item.id)">
            <v-icon>{{ dataStatus(item.personalData) }}</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.localSave`]="{ item }">
          <div v-if="typeof item.localSave === 'boolean'" class="table-item" @click="enterSelect(item.id)">
            <v-icon>{{ dataStatus(item.localSave) }}</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.fieldSettings`]="{ item }">
          <div v-if="item.fieldSettings && !(typeof item.fieldSettings === 'object' && !Object.keys(item.fieldSettings)?.length)" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.fieldSettings }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.fieldSettings)">mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
  <v-col cols="3" style="position: relative;" v-if="visibleEditor">
    <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
      <div style="position: fixed;">
        <v-btn
          class="btn-close"
          icon
          color="red"
          @click="closeEditor"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div v-if="newData?.id || visibleCopy || visibleAdd" class="pb-6 editor-col__content">
        <p v-if="newData.id" class="mr-6"> Id:
          <b style="color:#9c9c9c;">{{ newData.id }}</b>
          <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
        </p>
        <v-text-field
          :label="$t('variables.fieldName')"
          hide-details="auto"
          outlined
          v-model="newData.fieldName"
          require
        ></v-text-field>
        <div class="editor-col__content__checkbox">
          <v-checkbox
            v-model="newData.personalData"
            color="green"
            class="editor-col__content__checkbox-item"
          >
            <template v-slot:label>
              <div @click.stop="">
                {{ $t('variables.personalData') }}
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="newData.localSave"
            color="green"
            class="editor-col__content__checkbox-item"
          >
            <template v-slot:label>
              <div @click.stop="">
                {{ $t('variables.localSave') }}
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="json-editor">
          <JsonEditorVue
            v-model="newData.fieldSettings"
            v-bind="JsonEditorSetting"
            class="mb-3"
            style="position: relative;"
            ref="jsonEditor-fieldSettings"
          />
          <v-icon class="copy_json" @click="copyData(newData.fieldSettings)">mdi-content-copy</v-icon>
      </div>
        <div class="editor-col__btns">
          <template v-if="newData.id">
            <v-btn :disabled="requireFields" @click="updateMethod">{{ $t('update') }}</v-btn>
            <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
            <v-btn @click="deleteMethod(newData.id)">{{ $t('delete') }}</v-btn>
          </template>
          <template v-else>
            <v-btn :disabled="requireFields" @click="createMethod">{{ $t('create') }}</v-btn>
          </template>
        </div>
      </div>
      <SpinnerLoader 
        v-else
        color="blue" 
      />
    </div>
  </v-col>
</v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";

export default{

  data(){
    return {
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('variables.fieldName'), value: 'fieldName'},
        {text: this.$t('variables.personalData'), value: 'personalData'},
        {text: this.$t('variables.localSave'), value: 'localSave'},
        {text: this.$t('variables.fieldSettings'), value: 'fieldSettings'}
      ],
      templateData: {
        personalData: true,
        localSave: true,
        fieldSettings: {}
      },

      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
      },
    }
  },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('pageBuilder/getDataById', {id, show: true})

      data?.fieldSettings
          ? this.parseProperties(data, 'fieldSettings')
          : data.fieldSettings = JSON.parse(JSON.stringify(this.templateData.fieldSettings))

      this.newData = data
    },

    duplicate(id){
      const variables = this.variables.find(field => field.id === id)

      variables?.fieldSettings
          ? this.parseProperties(variables, 'fieldSettings')
          : variables.fieldSettings = JSON.parse(JSON.stringify(this.templateData.fieldSettings))

      const variable = JSON.parse(JSON.stringify(variables))
      delete variable.id

      this.visibleCopy = true
      this.newData = variable
    },

    deleteMethod(id){
      this.$store.dispatch('pageBuilder/deleteVariable', {id, searchData: this.search})

      this.closeEditor()
    },

    async createMethod(){
      this.parseProperties(this.newData, 'fieldSettings')

      const newId = await this.$store.dispatch('pageBuilder/createVariable', {newData: this.newData, searchData: this.search})
      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    updateMethod(){
      this.parseProperties(this.newData, 'fieldSettings')

      this.$store.dispatch('pageBuilder/updateVariable', {varData: JSON.parse(JSON.stringify(this.newData)), searchData: this.search})
    },

    loadData(){
      this.$store.dispatch('pageBuilder/getVariables')
    },
  },
  computed: {
    filteredData(){
      return this.$store.getters['pageBuilder/searchedVariable']
    },
    variables(){
      const variables = this.$store.getters['pageBuilder/variables']
      
      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? variables
          : this.filteredData
    },
    loading() {
      return this.variables && this.variables.length > 0 && this.loadedAllData
    },
    loadedAllData(){
      return this.$store.getters['pageBuilder/loadedAllData']
    },

    requireFields(){
      return (!this.newData.fieldName)
    }
  },

  created(){
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/CRUD.scss";
</style>