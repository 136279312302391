<template>
  <div class="main-tips">
    <p class="main-tips__text">What about some tips?</p>
    <p class="main-tips__subtext">Learn more how to manage budget</p>
    <div class="main-tips__slider slider">
      <div class="slider__item">
        <span class="slider__text">Financial literacy</span>
      </div>
      <div class="slider__item">
        <span class="slider__text">Invite your friends</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainTips",
}
</script>

<style lang="scss" scoped>
.main-tips {
  position: relative;
  width: 310px;
  height: 191px;
  padding: 13px 0 32px 24px;
  background: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  &__subtext {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 125%;
    margin-bottom: 0;
    color: #777;
  }
  &__slider {
    margin-top: 35px;
    position: absolute;
    bottom: 30px;
  }
}
.slider {
  display: flex;
  gap: 16px;
  &__item {
    width: 200px;
    height: 80px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 12px 0 0 12px;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
  }
  &__text {
    display: inline-block;
    max-width: 70px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #212121;
  }
}
</style>