<template>
    <div class="block">
      <div v-if="success_interview" class="card">
        <p class="card__title card__title_bold mb-10">
            Congratulation! Your contract has been signed and sent to you by e-mail and sms.
        </p>
        <img class="card__block" src="@/assets/images/success.png" alt="success">
      </div>
      <div v-else class="card">
        <p class="card__description">
            According to the RBI requirements,
            you need to complete the video KYC
            procedure. It will take no more than
            3 minutes.
        </p>
        <p class="card__title card__title_mid">
            Please prepare the following items:
        </p>
        <ol>
            <li>Original of PAN card</li>
            <li>White sheet of paper</li>
            <li>A pen</li>
        </ol>
      </div>
      <div class="card">
        <p class="card__description">
            When you're ready, click on the
            <span class="card__title">"Start Video KYC"</span> button and we'll
            connect you with an operator.
        </p>
      </div>
    </div>
</template>

<script>
export default{
    props: {
        success_interview: {
            default: false,
            type: Boolean
        }
    }
}
</script>