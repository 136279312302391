export default {
    methods: {
        setColorMode(label, mode, id = null){ 
            let color = '#000000ff'
            let gradient = 'linear-gradient(0deg, #000000ff 0%, #000000ff 100%)'

            let comparable = id !== null
                ? this.currentScreen.nodeFields.find(item => item.id === id).properties
                : this.fields.find(item => item.label === label)

            if(id !== null){
                mode === 'mono'
                ? comparable[label] = color
                : comparable[label] = gradient
            }else{
                mode === 'mono'
                ? comparable.value = color
                : comparable.value = gradient
            }
    
            this.$forceUpdate();
        },

        isGradient(label, id = null){
            return id === null
                ? this.fields.filter(item => item.category === 'color').length > 0
                    ? this.fields.filter(item => item.category === 'color').find(item => item.label === label).value.split('-gradient').length > 1 
                    : false
                : this.currentScreen.nodeFields.find(item => item.id === id).properties[label].split('-gradient').length > 1 
        }
    }
  }