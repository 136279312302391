<template>
  <SpinnerLoader
    v-if="loading"
  />
  <div v-else-if="workFields" class="main-props">
    <MainPropsCategory
      v-for="category in fieldsCategories"
      class="main-props-category"
      :key="category.name"
      :fields="category.fields"
      :title="category.title"
    />
    <div class="main-props-btn-group">
      <v-spacer></v-spacer>
      <v-btn
        color="grey"
        class="white--text"
        @click.native="resetMainProps"
      >{{ this.$t('reset') }}</v-btn>
      <!-- ? color -->
      <v-btn
        color="deep-purple accent-2"
        class="white--text"
        @click.native="saveMainProps"
      >{{ this.$t('save') }}</v-btn>
    </div>

    <ConfirmModal
      ref="confirm"
    />
  </div>
</template>

<script>
import MainPropsCategory from "@/components/main-props/MainPropsCategory";
import ConfirmModal from "@/components/utils/ConfirmModal";

export default {
  name: "MainProps",
  components: {
    MainPropsCategory,
    ConfirmModal
  },
  data () {
    return {
      workFields: [],
      resetUpload: false,
      staticUrl: process.env.VUE_APP_STATIC_URL,
      loading: false
    }
  },
  computed: {
    fieldsCategories () {
      return [
        {
          name: 'main',
          title: this.$t('settings.basic'),
          fields: this.getFieldsByCategory('main')
        },
        {
          name: 'image',
          title: this.$t('settings.images'),
          fields: this.getFieldsByCategory('image')
        },
        {
          name: 'color',
          title: this.$t('settings.colors'),
          fields: this.getFieldsByCategory('color')
        }
      ];
    }
  },
  methods: {
    getFieldsByCategory (category) {
      return this.workFields.filter(field => field.category === category);
    },
    async getProject (id) {
      await this.$store.dispatch('project/getProject', id);
      this.workFields = JSON.parse(JSON.stringify(this.$store.getters["project/workFields"]));
    },
    saveMainProps () {
      const payload = []
      this.workFields.forEach((field, index) => {
        if (field.value !== this.$store.getters["project/workFields"][index].value) {
          payload.push(field);
        }
      })
      this.$store.dispatch("project/saveProject", payload);
    },
    async resetMainProps () {
      if (await this.$refs.confirm.open(
          this.$t('settings.resetSetting'),
          this.$t('settings.confResetSetting')
      )) {
        await this.getProject(12);
        this.resetUpload = !this.resetUpload;
      }
    }
  },
  async created () {
    this.loading = true;
    await this.getProject(12);
    this.loading = false;
  },
}
</script>

<style scoped lang="scss">
.main-props {
  &-category {
    margin-bottom: 24px;
  }

  &-btn-group {
    display: flex;
    gap: 8px;
  }
}
</style>
