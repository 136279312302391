<template>
  <PhoneWrapper node-name="НОДА: ИЗМЕНЕНИЕ ДАННЫХ. ПРЕСЕТ: ЛОГИН. ">
    <div class="phone-screen__context">
      <ProfileHeader title="Change login"/>

      <bpmInput placeholder="Enter login" class="mt-3" />
      <bpmInput placeholder="New login" class="mt-3" />

      <bpmButton class="mt-auto mb-10" style="width: 100%">
        <template v-slot:content><span style="color: white">Confirm</span></template>
      </bpmButton>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "ChangeLogin",
  components: {PhoneWrapper, ProfileHeader}
}
</script>