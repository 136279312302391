<template>
  <v-row class="d-flex">
    <v-col :cols="tableWidth">
      <SearchComponent
          :searchData="['id', 'name']"
          prefix="conditionsRules"
          @searchInput="searchInput"
          ref="searchComponent"
      />

    <v-card class="mt-5 pb-6 rounded-xl pa-6">
      <v-btn
        fab
        dark
        small
        color="primary"
        @click="showCreate"
        class="btn-add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-data-table
        :headers="tableHeaders"
        :items="rules"
        :loading="!loading"
        :loading-text="$t('multilang.load')"
        item-key="id"
        density="compact"
        :items-per-page="tableItemsPerPage"
        :footer-props="tableFooterProps"
        class="mt-4"
        :sort-by="sortTableBy"
        :sort-desc="sortTableDesc"
        :custom-sort="customSort"
      >
        <template v-slot:[`item.id`]="{ item }">
          <div class="table-item">
            <span @click="enterSelect(item.id)">{{ item.id }}</span>
            <v-icon class="copy" @click.prevent="copyData(item.id)">mdi-content-copy</v-icon>
          </div>
        </template>
        <template v-slot:[`item.ruleName`]="{ item }">
          <div class="table-item" @click="enterSelect(item.id)">
            {{ item.ruleName }}</div>
        </template>
        <template v-slot:[`item.method`]="{ item }">
          <div class="table-item" v-if="Object.keys(item.method).length">
            <pre @click="enterSelect(item.id)">{{ item.method }}</pre>
            <v-icon v-if="item.method?.id" class="copy" @click.prevent="copyData(item.method)">mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.fieldName`]="{ item }">
          <div v-if="item.fieldName && !item.fieldName.includes('null')" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.fieldName }}</span>
            <v-icon v-if="item.fieldName" class="copy" @click.prevent="copyData(item.fieldName)">mdi-content-copy</v-icon>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.resultApiField`]="{ item }">
          <div v-if="item.resultApiField?.length" class="table-item"> 
            <span @click="enterSelect(item.id)">{{ item.resultApiField }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.operator`]="{ item }">
          <div v-if="item.operator?.length" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.operator }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.answer`]="{ item }">
          <div v-if="item.answer?.length" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.answer }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
        <template v-slot:[`item.isRequired`]="{ item }">
          <div v-if="item.isRequired?.length" class="table-item">
            <span @click="enterSelect(item.id)">{{ item.isRequired }}</span>
          </div>
          <div v-else class="table-item" @click="enterSelect(item.id)"></div>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
  <v-col id="right-col" cols="3" style="position: relative;" v-if="visibleEditor">
    <div class="editor-col pa-6 rounded-xl" :style="topPositionCol">
      <div style="position: fixed;">
        <v-btn
          class="btn-close"
          icon
          color="red"
          @click="closeEditor"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div v-if="newData?.id || visibleAdd || visibleCopy" class="pb-6 mt-3 editor-col__content">
        <p v-if="newData.id" class="mr-6"> Id:
          <b style="color:#9c9c9c;">{{ newData.id }}</b>
          <v-icon class="copy_visible" @click="copyData(newData.id)">mdi-content-copy</v-icon>
        </p>
        <v-text-field
          :label="$t('rules.ruleName')"
          hide-details="auto"
          outlined
          v-model="newData.ruleName"
          require
        ></v-text-field>
        <v-checkbox
          v-model="newData.isRequired"
          color="green"
        >
          <template v-slot:label>
            <div @click.stop="">{{ $t('rules.isRequired') }}</div>
          </template>
        </v-checkbox>

        <Switcher
          :params="switcherParams"
          :selectedParam="typeOfRule"
          @switchParam="switchParam"
          :disabled="!!newData?.id"
          :inlineStyleBtn="{'font-weight': 700}"
        />
        <div v-if="typeOfRule === $t('rules.method')" class="d-flex align-center" style="gap:5px; position: relative;">
          <v-autocomplete
            :items="methods"
            v-model="newData.methodId"
            outlined
            item-value="id"
            :item-text="item => `${item.id}: ${item.methodName}`"
            :placeholder="$t('rules.selectMethod')"
            hide-details
            class="mt-1"
          />
          <v-btn
            v-if="selectedMethod"
            icon
            color="gray"
            @click="showMethod"
          >
            <v-icon class="method-help">mdi-help-circle-outline</v-icon>
          </v-btn>
        </div>
        <JsonEditorVue
          v-if="visibleMethod"
          v-model="selectedMethod"
          v-bind="JsonEditorSetting"
          class="mb-3"
          style="position: relative;"
        />
        <v-text-field
          v-if="typeOfRule === $t('rules.method')"
          :label="$t('rules.resultApiField')"
          hide-details="auto"
          outlined
          v-model="newData.resultApiField"
          require
        ></v-text-field>
        <v-autocomplete
          v-if="typeOfRule === $t('rules.variable')"
          :items="variables"
          v-model="newData.fieldName"
          outlined
          item-value="id"
          :item-text="item => `${item.id}: ${item.fieldName}`"
          :placeholder="$t('rules.selectFieldName')"
          hide-details
          class="mt-1"
        />
        <v-autocomplete
          :items="operators"
          v-model="newData.operator"
          outlined
          :placeholder="$t('rules.selectOperator')"
          hide-details
          class="mt-1"
        />
       <v-text-field
          :label="$t('rules.answer')"
          hide-details="auto"
          outlined
          v-model="newData.answer"
          require
        ></v-text-field>
        <!-- <v-text-field
          label="answerId"
          hide-details="auto"
          outlined
          v-model="newData.answerId"
          require
        ></v-text-field> -->
        <div class="editor-col__btns">
          <template v-if="newData.id">
            <v-btn :disabled="requireFields" @click="updateRule">{{ $t('update') }}</v-btn>
            <v-btn @click="duplicate(newData.id)">{{ $t('duplicate') }}</v-btn>
            <v-btn @click="deleteRule(newData.id)">{{ $t('delete') }}</v-btn>
          </template>
          <template v-else>
            <v-btn :disabled="requireFields" @click="createRule">{{ $t('create') }}</v-btn>
          </template>
        </div>
      </div>
      <SpinnerLoader 
        v-else
        color="blue" 
      />
    </div>
  </v-col>
</v-row>
</template>

<script>
import CRUD_UI_Mixin from "@/mixins/CRUD_UI_Mixin";
import Switcher from '@/components/UI/switcher.vue'

export default{
  data(){
    return {
      tableHeaders: [
        {text: 'Id', value: 'id'},
        {text: this.$t('rules.ruleName'), value: 'ruleName'},
        {text: this.$t('rules.method'), value: 'method'},
        {text: this.$t('rules.variable'), value: 'fieldName'},
        {text: this.$t('rules.resultApiField'), value: 'resultApiField'},
        {text: this.$t('rules.operator'), value: 'operator'},
        {text: this.$t('rules.answer'), value: 'answer'},
        {text: this.$t('rules.isRequired'), value: 'isRequired'},
        // {text: 'answerId', value: 'answerId'},
      ],
      visibleMethod: false,      
      newData: {},
      templateData: {
        isRequired: true
      },

      JsonEditorSetting: {
        mode: 'text',
        statusBar: false,
        navigationBar: false,
        mainMenuBar: false,
        readOnly: true,
      },
      operators: ['<', '<=', '>', '>=', '!=', '='],
      selectedMethod: null,
      typeOfRule: this.$t('rules.method'),
      switcherParams: [this.$t('rules.method'), this.$t('rules.variable')],
    }
  },
  components: { Switcher },
  mixins: [CRUD_UI_Mixin],
  methods: {
    async actionShow(id){
      const data = await this.$store.dispatch('conditionsRules/getDataById', {id, show: true})

      if(data?.fieldName) {
        const num = data.fieldName.match((/\d+/))
        if(num?.length) data.fieldName = Number(num[0])
        this.typeOfRule = this.$t('rules.variable')
      }

      if(data?.method?.id){
        data.methodId = data.method.id
        this.typeOfRule = this.$t('rules.method')
      }

      this.newData = data
    },

    duplicate(id){
      let rules = this.rules.find(field => field.id === id)

      const rule = JSON.parse(JSON.stringify(rules))
      delete rule.id
      rule.methodId = rule.method.id

      this.visibleCopy = true
      this.newData = rule
    },

    deleteRule(id){
      this.$store.dispatch('conditionsRules/deleteRule', {id, searchData: this.search})

      this.closeEditor()
    },

    async createRule(){
      if(this.typeOfRule === this.$t('rules.variable')) this.newData.fieldName = `{{field.${this.newData.fieldName}}}`

      const newId = await this.$store.dispatch('conditionsRules/createRule', {newData: this.newData, searchData: this.search})
      if(newId) this.enterSelect(newId)
      this.visibleCopy = false
    },

    updateRule(){
      if(this.typeOfRule === this.$t('rules.variable')) this.newData.fieldName = `{{field.${this.newData.fieldName}}}`
      this.newData.methodId = this.newData.method.id

      this.$store.dispatch('conditionsRules/updateRule', {data: JSON.parse(JSON.stringify(this.newData)), searchData: this.search})
    },
    
    async getMethod(id){
      const res = await this.$store.dispatch('externalSources/getDataById', {id})
      this.selectedMethod = res?.responseJson
    },

    showMethod(){
      this.visibleMethod = !this.visibleMethod
    },

    switchParam(param){
      if(this.typeOfRule === param) return
      
      this.visibleMethod = false
      this.typeOfRule = param

      if(this.switcherParams.indexOf(param) === 0) delete this.newData.fieldName
      else {
        delete this.newData.method
        delete this.newData.methodId
        delete this.newData.resultApiField
        this.selectedMethod = null
      }
    },
    loadData(){
      this.$store.dispatch('pageBuilder/getVariables')
      this.$store.dispatch('conditionsRules/getRules')
      this.$store.dispatch('externalSources/getMethods')
    },
  },
  computed: {
    filteredData(){
      return this.$store.getters['conditionsRules/searchedRules']
    },
    rules(){
      const rules = this.$store.getters['conditionsRules/rules']

      if(!this.loadedAllData) return []
      return !this.filteredData?.length
          ? rules
          : this.filteredData
    },

    loading(){
      return this.rules && this.rules.length > 0 && this.loadedAllData
    },

    loadedAllData(){
      return this.$store.getters['conditionsRules/loadedAllData']
    }, 

    variables(){
      return this.$store.getters['pageBuilder/variables']
    },

    methods(){
      return this.$store.getters['externalSources/externalSources']
    },

    requireFields(){
      return (!this.newData.ruleName)
    }
  },

  watch: {
    'newData.method': {
      handler: function (data) {
        const id = data?.id ? data.id : data
    
        /* eslint-disable */ 
        if(typeof id === 'number') this.getMethod(id)
      },
      deep: true
    },
    'newData.methodId': {
      handler: function (id) {
        
        if(typeof id === 'number') this.getMethod(id)
      },
      deep: true
    },
  },
  created(){
    this.loadData()
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/CRUD.scss";

.method-selected {
	position: absolute;
	top: 0;
  left: -20px;
  background: #dedede;
  z-index: 9;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}
</style>