<template>
  <PhoneWrapper node-name="НОДА: ПЛАТЕЖИ. ЭКРАН 4: НАСТРОЙКА ПАРАМЕТРОВ EMI. ">
    <h1 class="phone-screen__title">Set EMI parametrs</h1>
    <div class="block">
      <div class="card">
        <p class="card__title card__title_bold">Change loan term</p>
      </div>
      <overduePayment :progress="false" :grace="true" :emi="false" period="Dec 2022" loan_amount="15,000" grace_term="5" grace_untill="20 Jan’ 23" />
      <overduePayment :progress="false" :grace="true" :emi="false" loan_amount="40,000" />
      <div class="card">
        <p class="card__title card__title_bold">EMI reschedule</p>
      </div>
      <overduePayment :progress="false" :amount="false" :grace_block="false" emi_pay="7,000" />
    </div>

  </PhoneWrapper>

</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import overduePayment from "@/components/nodes/creditLine/widgets/homescreen/overduePayment.vue";
export default {
  name: "vueSetEmiParametrs",
  components: {
    PhoneWrapper,
    overduePayment
  }
}
</script>

<style scoped>

</style>