<template>
  <PhoneWrapper node-name="НОДА: СБОР ОБРАТНОЙ СВЯЗИ. ПРЕСЕТ: СООБЩЕНИЕ. ">
    <div class="phone-screen__context">
      <ProfileHeader title="Write to the developer"/>

      <bpmTextarea class="mt-5" />

      <bpmButton class="mt-auto mb-10" style="width: 100%">
        <template v-slot:content><span style="color: white">Send</span></template>
      </bpmButton>
    </div>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";
import ProfileHeader from "@/components/nodes/creditLine/widgets/transh/ProfileHeader.vue";
export default {
  name: "FeedBackMessage",
  components: {PhoneWrapper, ProfileHeader}
}
</script>