<template>
  <div>
    <div class="top-bar">
      <img v-if="visibleField('arrow')" class="top-bar__arrow" src="@/assets/icons/arrow_left.svg" alt="arrow left">
      <img v-if="visibleField('logo')" class="top-bar__logo" src="@/assets/images/logo_viva.svg" alt="logo_viva">
      <div v-if="visibleField('help')" class="top-bar__help">
        <img src="@/assets/icons/lang_select.svg" alt="lang select">
        <img src="@/assets/icons/help_circle.svg" alt="help">
      </div>
    </div>
  </div>
</template>

<script>
import widgetOptions from "@/mixins/widgetOptions";
export default {
    name: "WidgetTopBar",
    mixins: [widgetOptions],
    methods:{
      visibleField(name){
        return this.fieldsType.some(type => type === name)
      }
    }
}
</script>

<style lang="scss" scoped>
.top-bar{
  align-items: center;
  position: relative;
  padding: 10px;
  display: flex;
  &__arrow{
    position: absolute;
    left: 5px;
  }
  &__logo{
    margin: 0 auto;
  }
  &__help{
    position: absolute;
    right: 5px;
    display: flex;
    gap: 10px;
  }
}

</style>