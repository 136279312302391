<template>
  <div class="mb-3">
    <div class="d-flex my-4 align-center">
      <div class="d-flex" style="gap:5px" @click="setTypeGradient('linear')">
        <span :class="{ 'type-color_active' : typeGradient === 'linear'}" class="type-color">
            <span :style="{'background': linearColor}" class="type-color_input"></span>
        </span>
        <button>Linear</button>
      </div>
      <div class="d-flex ml-5 mr-9 my-3" style="gap:5px" @click="setTypeGradient('radial')">
        <span :class="{ 'type-color_active' : typeGradient === 'radial'}" class="type-color">
          <span :style="{'background': radialColor}" class="type-color_input"></span>
        </span>
        <button>Radial</button>
      </div>
      <template v-if="typeGradient === 'linear'">
        <span>{{ $t('gradientColor.degree') }}</span><BpmInput class="bpm-input ml-3" type="number"  min="0" max="360" maxlength="3" placeholder="0" v-model="linearDeg" size="small" />
      </template>
    </div>    
    <div class="gradient" :style="`background: ${value}`"></div>
    <div class="d-flex justify-end flex-column mt-6">
      <div class="d-flex">
        <div class="colors-box d-flex flex-column p-2">
          <div v-for="item,i in colors" :key="i" class="colorBox">
              <colorsVue :colorData="item" :index="++i" :activeColor="activeColor" @changeColor="changeColor($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colorsVue from './colorsVue.vue'
export default {
  name: 'gradientColors',
  props: {
    value: {
      type: String,
      default: '#ffffff'
    },
    type:{
      type: [String, Number],
      default: 'color'
    }
  }, 
  data(){
    return{
      colors: [ {
        color: this.value,
        weight: '0',
      }, {
        color: this.value,
        weight: '100',
      }],
      color: '',
      typeGradient: 'radial',
      linearDeg: 0,
      activeColor: 1
    }
  },
  computed: {
    setColorName(){
      return this.typeGradient === 'linear'
      ? `${this.typeGradient}-gradient(${this.meaningLimit(this.linearDeg, 360)}deg, ${this.colors[0].color} ${this.meaningLimit(this.colors[0].weight, 100)}%, ${this.colors[1].color} ${this.meaningLimit(this.colors[1].weight, 100)}%)`
      : `${this.typeGradient}-gradient(${this.colors[0].color} ${this.meaningLimit(this.colors[0].weight, 100)}%, ${this.colors[1].color} ${this.meaningLimit(this.colors[1].weight, 100)}%)`
    },
    getColorsParse(){
      return { backgroundColor: this.colors[0].color,
                gradient: {
                deg: this.meaningLimit(this.linearDeg, 360),
                  type: this.typeGradient,
                  value: [
                    {
                      color: this.colors[0].color,
                      weight: this.meaningLimit(this.colors[0].weight, 100)
                    },{
                      color: this.colors[1].color,
                      weight: this.meaningLimit(this.colors[1].weight, 100)
                    }
                  ]
                }
              }
    },
    radialColor(){
      return `radial-gradient(${this.colors[0].color} ${this.meaningLimit(this.colors[0].weight, 100)}%, ${this.colors[1].color} ${this.meaningLimit(this.colors[1].weight, 100)}%)`
    },
    linearColor(){
      return `linear-gradient(${this.meaningLimit(this.linearDeg, 360)}deg, ${this.colors[0].color} ${this.meaningLimit(this.colors[0].weight, 100)}%, ${this.colors[1].color} ${this.meaningLimit(this.colors[1].weight, 100)}%)`
    },
  },
  methods: {
    meaningLimit(weight, max){
      if(weight > max) return max
      if(weight < 0) return 0
      return +weight
    },
    setTypeGradient(type){
      this.typeGradient = type
    },
    changeColor($event){
      this.activeColor = $event
      // console.log('$event', $event)
    }
  },
  components: {
    colorsVue
  },
  watch: {
    setColorName: function (val) {
      this.$emit('input', val)
    },
  },
  created(){
    let value = JSON.parse(JSON.stringify(this.value))
    
    this.typeGradient = value.slice(0, value.indexOf('-gradient'))

    let start = value.indexOf('(') + 1
    let end = value.indexOf(')')
    let inside = value.slice(start, end)
    
    let isDeg = inside.indexOf('deg') >= 0
    this.linearDeg = isDeg ? inside.split(' ')[0].replace(/[a-z/,]/g, '') : false

    let colors = inside.split(',').filter(item => item.indexOf('#') >= 0)
    let color1 = colors[0].split(' ').filter(item => item !== '')
    let color2 = colors[1].split(' ').filter(item => item !== '')

    this.colors = [
      {color: color1[0],  weight: color1[1].replace(/%/g, '')},
      {color: color2[0],  weight: color2[1].replace(/%/g, '')},
    ]
  }
}
</script>

<style lang="scss" scoped>
.colors-box{
  gap: 10px;
  position: relative;
}

.gradient{
  width: 100%;
  height: 36px;
  border-radius: 8px;
  border: 0.5px solid #dadada 
}

.type-color{
  height: 25px;
  width: 25px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &_active{
    border: 1px solid #adb5bd;
  }
  &_input{
    height: 20px;
    width: 20px;
    display: block;
    border-radius: 50px;
  }
}
.bpm-input{
     width: 100px !important;
 }
</style>