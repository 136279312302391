<template>
  <PhoneWrapper node-name="НОДА: ФРЕЙМ. ПРЕСЕТ: E-MANDATE.">
    <div class="frame">
      <h1 class="phone-screen__title">Webview for Digital Mandate signing (process like RupeeRedee)</h1>
    </div>
    <bpmButton class="mt-auto mb-10" style="width: 100%">
      <template v-slot:content><span style="color: white">Confirm</span></template>
    </bpmButton>
  </PhoneWrapper>
</template>

<script>
import PhoneWrapper from "@/components/utils/PhoneWrapper.vue";

export default {
  name: "FrameNode",
  components: {PhoneWrapper},
}
</script>

<style lang="scss" scoped>
.frame {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-direction: column;
}
</style>