<template>
    <div class="block popup_block">
        <div class="card">
            <p class="card__title">{{ title }}</p>
            <p class="card__description">{{ text }}</p>
            <div class="flex btns">
                <bpm-button><template v-slot:content>Not now</template></bpm-button>
                <bpm-button><template v-slot:content>Accept</template></bpm-button>
            </div>
        </div>
    </div>
  
</template>

<script>
export default {
    props: {
        title: {
            default: 'Hey Kumar!',
            type: String
        },
        text: {
            default: 'We are pleased to announce that an increase in the credit limit of 20,000 rupees is available to you. Offer available until 18.11.2022.',
            type: String
        }
    }

}
</script>

<style scoped lang="scss">
.popup_block{
    margin: auto !important;
    position: absolute;
    z-index: 12;
    background-color: #fff;
    border-radius: 16px;
    padding: 20px 24px 10px 24px;
    width: calc(100% - 80px) !important;
    top: 28vh;
}
.btns button, .btns button:hover, .btns button:focus{
    background: none;
    border: none;
    padding: 0;
}


</style>