import { userServices } from "@/services/user.service";
import { router } from "@/router";

export const authentication = {
  namespaced: true,
  state: {
    loggedIn: !!localStorage.getItem('accessToken'),
  },
  getters: {
    isLoggedIn: state => state.loggedIn,
  },
  actions: {
    login ({ dispatch, commit }, { login, password }) {

      userServices.login(login, password).then(
        (credentials) => {
          commit("loginSuccess", credentials.response);
          dispatch('app/getInitProps', null, {root: true})
          router.push("/");
        },
        (error) => {
          commit("loginFailure", error);
          dispatch("alert/error", error, { root: true });
        }
      );
    },
    async refresh({commit, dispatch}, refreshToken) {
      const cred = await userServices.refresh(refreshToken)
      console.log('cred - ', cred);
      if (cred?.status === 1) {
        commit("loginSuccess", cred.response);
        return cred.response;
      } else {
        commit("logout");
        return null;
      }
    },
     logout({ commit }) {
      userServices.logout()
        .then(resp => {
          if (resp.status !== 0)
            commit('logout')
        })
      ;
    },
  },
  mutations: {
    loginSuccess(state, credentials) {
      localStorage.setItem('accessToken', credentials.access);
      localStorage.setItem('refreshToken', credentials.refresh);
      state.loggedIn = true;
    },
    loginFailure(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.loggedIn = true;
    },
    logout(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.loggedIn = false;
      window.location.reload();
    },
  },
};
